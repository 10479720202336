import { Text } from "components/Typography/text.styled";
import { useTranslation } from "react-i18next";
import {
  Cell,
  DeductionAmountContainer,
  DividerLine1,
  DividerLine2,
  DividerLine3,
  GrossSalaryContainer,
  HoursContainer,
  NetSalaryContainer,
  PayrollTaxContainer,
  SalaryActionContainer,
  SalaryNoticeContainer,
  TaxContainer,
  WorkerContainer,
} from "./salary.styled";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import { formatDuration } from "utils/utils";
import { SalaryNotice } from "model/Salary";

type Props = {
  salaryNotices: SalaryNotice[];
  setSelectedYear: React.Dispatch<React.SetStateAction<number | undefined>>;
  selectedYear: number;
};

export const SummaryIndividualSalary: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const totalGrossSalary = props.salaryNotices.reduce(
    (sum, notice) => sum + notice.grossSalary,
    0
  );
  const totalPreliminaryTax = props.salaryNotices.reduce(
    (sum, notice) => sum + notice.preliminaryTax,
    0
  );
  const totalNetSalary = props.salaryNotices.reduce(
    (sum, notice) => sum + notice.netSalary,
    0
  );
  const totalWorkedHours = props.salaryNotices.reduce(
    (sum, notice) => sum + notice.workedHours,
    0
  );
  const totalPayrollTax = props.salaryNotices.reduce(
    (sum, notice) => sum + notice.payrollTax,
    0
  );
  const totalDeductionAmount = props.salaryNotices.reduce(
    (sum, notice) => sum + notice.deductionAmount,
    0
  );

  let currency = props.salaryNotices[0].currency == "SEK" ? " kr" : "";

  return (
    <SalaryNoticeContainer>
      <Cell style={{ backgroundColor: Color.Positive, marginTop: 0 }}>
        <WorkerContainer />
        <DividerLine1 />
        <HoursContainer>
          <Text
            style={{ margin: 0, padding: 0 }}
            fontSize={FontSize.Small}
            color={Color.BurntSienna}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("Salary.TotalWorkTime")}
          </Text>
          <Text
            color={Color.MidnightBlue}
            fontFamily={FontFamily.MontserratRegular}
          >{`${formatDuration(totalWorkedHours.toString())}`}</Text>
        </HoursContainer>
        <DividerLine2 />
        <GrossSalaryContainer>
          <Text
            style={{ margin: 0, padding: 0 }}
            fontSize={FontSize.Small}
            color={Color.BurntSienna}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("Salary.TotalGrossSalary")}
          </Text>
          <Text
            color={Color.MidnightBlue}
            fontFamily={FontFamily.MontserratRegular}
          >{`${totalGrossSalary.toFixed(2)}${currency}`}</Text>
        </GrossSalaryContainer>
        <NetSalaryContainer>
          <Text
            style={{ margin: 0, padding: 0 }}
            fontSize={FontSize.Small}
            color={Color.BurntSienna}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("Salary.TotalNetSalary")}
          </Text>
          <Text
            color={Color.MidnightBlue}
            fontFamily={FontFamily.MontserratRegular}
          >{`${totalNetSalary.toFixed(2)}${currency}`}</Text>
        </NetSalaryContainer>
        <TaxContainer>
          <Text
            style={{ margin: 0, padding: 0 }}
            fontSize={FontSize.Small}
            color={Color.BurntSienna}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("Salary.TotalTax")}
          </Text>
          <Text
            color={Color.MidnightBlue}
            fontFamily={FontFamily.MontserratRegular}
          >{`${totalPreliminaryTax.toFixed(2)}${currency}`}</Text>
        </TaxContainer>
        <PayrollTaxContainer>
          <Text
            style={{ margin: 0, padding: 0 }}
            fontSize={FontSize.Small}
            color={Color.BurntSienna}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("Salary.TotalPayrollTax")}
          </Text>
          <Text
            color={Color.MidnightBlue}
            fontFamily={FontFamily.MontserratRegular}
          >{`${totalPayrollTax.toFixed(2)}${currency}`}</Text>
        </PayrollTaxContainer>
        <DeductionAmountContainer>
          <Text
            style={{ margin: 0, padding: 0 }}
            fontSize={FontSize.Small}
            color={Color.BurntSienna}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("Salary.TotalDeductionAmount")}
          </Text>
          <Text
            color={Color.MidnightBlue}
            fontFamily={FontFamily.MontserratRegular}
          >{`${totalDeductionAmount}${currency}`}</Text>
        </DeductionAmountContainer>
        <DividerLine3 />
        <SalaryActionContainer />
      </Cell>
    </SalaryNoticeContainer>
  );
};
