import { Worker } from "model/StaffingPool";
import { useEffect, useMemo, useState } from "react";
import { Api } from "services/api/api.service";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import {
  Container,
  DropdownItem,
  DropdownList,
  Input,
  WorkerId,
} from "./salary.styled";
import { useTranslation } from "react-i18next";
import { Text } from "components/Typography/text.styled";

type Props = {
  setSelectedWorker: React.Dispatch<React.SetStateAction<Worker | undefined>>;
  selectedWorker: Worker | undefined;
};

export const WorkerDropdown: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [workers, setWorkers] = useState<Worker[]>([]);
  const [companyState] = useCompanyStore();
  const [inputValue, setInputValue] = useState("");
  const { t } = useTranslation();

  const getCompanyWorkers = () => {
    if (companyState.company?.id) {
      Api()
        .company.salary.getCompanyWorkers(companyState.company.id)
        .then((res) => {
          setWorkers(res.data);
        })
        .catch((e) => {
          console.error("Error when getting workers", e);
        });
    }
  };

  useEffect(() => {
    getCompanyWorkers();
  }, []);

  const filteredWorkers = useMemo(() => {
    if (!inputValue) return [];

    return workers.filter((worker) =>
      `${worker.firstName} ${worker.lastName}`
        .toLowerCase()
        .includes(inputValue.toLowerCase())
    );
  }, [inputValue]);

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setInputValue(value);
    setIsOpen(value.length > 0);
  };

  const handleWorkerSelect = (worker: Worker) => {
    props.setSelectedWorker(worker);
    setInputValue(`${worker.firstName} ${worker.lastName}`);
    setIsOpen(false);
  };

  return (
    <Container>
      <Input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={() => inputValue && setIsOpen(true)}
        placeholder={t("Salary.SearchForWorker")}
      />

      {isOpen && filteredWorkers.length > 0 && (
        <DropdownList>
          {filteredWorkers.map((worker) => (
            <DropdownItem
              key={worker.workerId}
              onClick={() => handleWorkerSelect(worker)}
              isSelected={props.selectedWorker?.workerId === worker.workerId}
            >
              <Text>
                {worker.firstName} {worker.lastName}
              </Text>
              <WorkerId>[ID: {worker.workerId}]</WorkerId>
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </Container>
  );
};
