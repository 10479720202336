import React, { useEffect } from "react";
import { Color } from "config/colors";
import { FontFamily } from "config/font";
import { useTranslation } from "react-i18next";
import {
  TipButtonStyled,
  WorkerContainer,
  TipDetailsContainer,
} from "../tips.styled";
import {
  DropDown,
  DetailsHeadCell,
  DetailsContainer,
  DropdownContainer,
  TipButtonContainer,
} from "../tips.styled";
import { Text } from "components/Typography/text.styled";
import { formatNumber, parseWorkTime } from "utils/utils";
import { TipDetails, TipStatus, Worker } from "model/Tip";
import { TipState } from "web-apps/company/stores/tipStore/tipStore.reducer";

type TipDetailsProps = {
  tip: TipDetails;
  tipState: TipState;
  isExpanded: boolean;
  handleManualPayout: (tipId: number) => void;
  getWorkerStatus: (workerId: number, date: string) => JSX.Element;
  openDistributeModal: (tipId: number) => void;
};

export const TipDetailsComponent: React.FC<TipDetailsProps> = (props) => {
  const { t } = useTranslation();

  const groupWorkersByWorkgroup = (workers: Worker[]) => {
    return workers.reduce((acc, worker) => {
      const workGroupKey = worker.workGroupId ?? "no-group";

      if (!acc[workGroupKey]) {
        acc[workGroupKey] = {
          workGroupName: worker.workGroupName || t("CompanyTip.NotChosen"),
          workers: [],
        };
      }
      acc[workGroupKey].workers.push(worker);
      return acc;
    }, {} as Record<string, { workGroupName: string; workers: Worker[] }>);
  };

  // const tipDetail = props.tipState.tips.find(
  //   (tip) => tip.companyTipId === props.tip.companyTipId
  // );
  const groupedWorkers = groupWorkersByWorkgroup(props.tip?.workers ?? []);

  // useEffect(() => {
  //   console.log("TipDetailsComponent got updated tip", props.tip);
  // }, [props.tip]);

  const isClosed =
    props.tip.status === TipStatus.Closed ||
    props.tip.status === TipStatus.ClosedManually;

  const shouldShowDistribute = !isClosed;
  const shouldShowManualPayout = props.tip.status === TipStatus.Done;

  const handleDistributeTip = () => {
    props.openDistributeModal(props.tip.companyTipId);
  };

  const isDistributeDisabled = props.tip.amount === 0;

  return (
    <DropdownContainer isOpen={props.isExpanded}>
      {props.isExpanded && (
        <DropDown>
          {props.tip?.workers.length ? (
            <DetailsContainer>
              <DetailsHeadCell>
                <Text fontFamily={FontFamily.MontserratSemiBold}>
                  {t("CompanyTip.Workers")}
                </Text>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {t("CompanyTip.Hours")}
                </Text>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {t("CompanyTip.Tips")}
                </Text>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {t("CompanyTip.Payment")}
                </Text>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {t("CompanyTip.Workgroup")}
                </Text>
              </DetailsHeadCell>

              {Object.entries(groupedWorkers).map(([workGroupId, group]) => (
                <div key={workGroupId} style={{ marginBottom: "10px" }}>
                  {group.workers.map((worker, index) => (
                    <TipDetailsContainer key={index} style={{ padding: "0" }}>
                      <WorkerContainer
                        style={{
                          display: "flex",
                          justifyContent: "start",
                        }}
                      >
                        <Text>
                          {worker.firstName} {worker.lastName}
                        </Text>
                      </WorkerContainer>
                      <WorkerContainer>
                        <Text>
                          {parseWorkTime(worker.workTime).hours}
                          {t("General.HourShortened")}{" "}
                          {parseWorkTime(worker.workTime).minutes}
                          {t("General.MinutesShortened")}
                        </Text>
                      </WorkerContainer>
                      <WorkerContainer>
                        <Text>
                          {formatNumber(worker.tipAmount)} {t("CompanyTip.Kr")}
                        </Text>
                      </WorkerContainer>
                      <WorkerContainer>
                        {props.getWorkerStatus(worker.workerId, props.tip.date)}
                      </WorkerContainer>
                      <WorkerContainer>
                        <Text>
                          {worker.workGroupId
                            ? `${worker.workGroupName}`
                            : t("CompanyTip.NotChosen")}
                        </Text>
                      </WorkerContainer>
                    </TipDetailsContainer>
                  ))}
                </div>
              ))}

              {(shouldShowDistribute || shouldShowManualPayout) && (
                <TipButtonContainer
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                >
                  {shouldShowDistribute && (
                    <TipButtonStyled
                      onClick={handleDistributeTip}
                      backgroundColor={Color.MidnightBlue}
                      disabled={isDistributeDisabled}
                      style={{
                        marginRight: "10px",
                      }}
                    >
                      {t("CompanyTip.Distribute")}
                    </TipButtonStyled>
                  )}
                  {shouldShowManualPayout && (
                    <TipButtonStyled
                      onClick={() => {
                        props.handleManualPayout(props.tip.companyTipId);
                      }}
                      backgroundColor={Color.MidnightBlue}
                    >
                      {t("CompanyTip.MarkAsManuallyPaid")}
                    </TipButtonStyled>
                  )}
                </TipButtonContainer>
              )}
            </DetailsContainer>
          ) : (
            <>
              <Text>{t("CompanyTip.NoDetailsToShowNotDistributed")}</Text>
              <TipButtonContainer
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
              >
                <TipButtonStyled
                  onClick={handleDistributeTip}
                  backgroundColor={Color.MidnightBlue}
                  disabled={isDistributeDisabled}
                >
                  {t("CompanyTip.Distribute")}
                </TipButtonStyled>
              </TipButtonContainer>
              {isDistributeDisabled && (
                <Text style={{ marginTop: "5px", color: Color.Destructive }}>
                  {t("CompanyTip.EnterTipAmountToDistribute")}
                </Text>
              )}
            </>
          )}
        </DropDown>
      )}
    </DropdownContainer>
  );
};
