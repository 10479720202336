import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import { useTranslation } from "react-i18next";
import { Instant, LocalDateTime } from "@js-joda/core";
import { CircularProgress } from "@material-ui/core";
import { useAlertStore } from "stores/alertStore/alertStore";
import {
  TimeReportDto,
  TimeReportResolution,
  TimeReportStatus,
} from "model/TimeReport";
import { useTimeReportStore } from "web-apps/company/stores/timeReportStore/timeReportStore";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { BannerComponent } from "components/banners/banner.component";
import { LabelStyled, TextArea } from "components/form/inputs/inputs.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { Text } from "components/Typography/text.styled";
import {
  ModalBody,
  FlexRow,
  ButtonContainer,
  LinkWrapper,
  RowWrapper,
  TotalCostWrapper,
  InfoCircle,
  InfoContainer,
  DividerLine,
} from "./timeReportModal.styled";
import { GigCosts, PaymentMethod } from "model/Gig";
import {
  getWorkHours,
  isWithinXMinutes,
  updateTimeReportInContext,
} from "utils/utils";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useCalendarStore } from "web-apps/company/stores/calendarStore/calendarStore";
import { Api } from "services/api/api.service";
import SimflexityPayLogo from "images/logo/svg/simflexityPay-logo.svg";
import { Logo } from "components/nav/logo/logo.styled";
import { getCostsTimeReports } from "web-apps/company/utils/utils";
import { CustomIcon } from "components/icon/customIcon.component";
import { ErrorMessage, TimeInput } from "../saveGig/shift/shiftForm.styled";
import { ShiftFormHHMMRegex } from "../saveGig/shift/shiftForm.component";
import {
  createBreakFullDates,
  getDifferenceFromDates,
  createEditedTimeReport,
  createStartAndEndDateStrings,
  calculateTotalTimeString,
  toLocalString,
  isBreakInsideShift,
} from "./timeReportsUtils";
import { BreakRow } from "./timeReportModalBreakRow.component";
import { useApplicationStore } from "web-apps/company/stores/applicationStore/applicationStore";
import {
  ApplicationDto,
  ApplicationRequestDto,
  ApplicationResolution,
  ApplicationStatus,
} from "model/Application";
type Props = {
  chosenTimeReportWithWorker: TimeReportDto;
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRatingModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  calenderView?: boolean;
  triggerRefreshTimeReports?: React.Dispatch<React.SetStateAction<void>>;
  application?: ApplicationDto | undefined;
};
const emptyCosts = {
  grossSalary: 0,
  obAmount: 0,
  socialFee: 0,
  simflexityFee: 0,
  totalCost: 0,
};

export const TimeReportModal: React.FC<Props> = ({
  chosenTimeReportWithWorker,
  modalOpen,
  setModalOpen,
  setRatingModalOpen,
  calenderView,
  triggerRefreshTimeReports,
  application,
}) => {
  const { t } = useTranslation();
  const [, , alertDispatch] = useAlertStore();
  const [timeReportState, timeReportActions, timeReportDispatch] =
    useTimeReportStore();
  const [companyState] = useCompanyStore();
  const [companyNotes, setCompanyNotes] = useState("");
  const [showFeeInfo, setShowFeeInfo] = useState(false);
  const [timeReportCost, setTimeReportCost] = useState<GigCosts>(emptyCosts);
  const [calendarState, calendarActions] = useCalendarStore();
  const [hoverOnCheckInTime, setHoverOnCheckInTime] = useState(false);
  const [hoverOnCheckOutTime, setHoverOnCheckOutTime] = useState(false);
  const [userNotesIsOpen, setUserNotesIsOpen] = useState(true);
  const [companyNotesIsOpen, setCompanyNotesIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [, applicationActions] = useApplicationStore();

  const d = LocalDateTime.ofInstant(
    Instant.parse(chosenTimeReportWithWorker.startTime)
  );
  const date = `${t(
    `WeekDays.${d.dayOfWeek().name()}SHORT`
  )} ${d.dayOfMonth()}/${d.monthValue()}`;

  const startTime = LocalDateTime.ofInstant(
    Instant.parse(chosenTimeReportWithWorker.startTime)
  );
  const endTime = LocalDateTime.ofInstant(
    Instant.parse(chosenTimeReportWithWorker.endTime)
  );

  const breakStartLocalDateTime = chosenTimeReportWithWorker.breakStartTime
    ? LocalDateTime.ofInstant(
        Instant.parse(chosenTimeReportWithWorker.breakStartTime)
      )
    : null;

  const breakEndLocalDateTime = chosenTimeReportWithWorker.breakEndTime
    ? LocalDateTime.ofInstant(
        Instant.parse(chosenTimeReportWithWorker.breakEndTime)
      )
    : null;

  const [isEdited, setIsEdited] = useState(false);

  const [startTimeStr, setStartTimeStr] = useState(toLocalString(startTime));

  const [endTimeStr, setEndTimeStr] = useState(toLocalString(endTime));

  const [breakStartTimeStr, setBreakStartTimeStr] = useState(
    breakStartLocalDateTime && toLocalString(breakStartLocalDateTime)
  );

  const [breakEndTimeStr, setBreakEndTimeStr] = useState(
    breakEndLocalDateTime && toLocalString(breakEndLocalDateTime)
  );

  const { localStartDateStr, localEndDateStr } = createStartAndEndDateStrings(
    startTime,
    startTimeStr,
    endTimeStr
  );

  const { breakStartDate, breakEndDate } =
    breakStartTimeStr && breakEndTimeStr
      ? createBreakFullDates(
          localStartDateStr,
          breakStartTimeStr,
          breakEndTimeStr
        )
      : { breakStartDate: undefined, breakEndDate: undefined };

  const breakMinutes =
    breakStartDate && breakEndDate
      ? getDifferenceFromDates(breakStartDate, breakEndDate)
      : 0;

  const breakValidInsideShift =
    breakStartDate && breakEndDate
      ? isBreakInsideShift(
          localStartDateStr,
          localEndDateStr,
          breakStartDate,
          breakEndDate
        )
      : true;

  const totalTimeStr = calculateTotalTimeString(
    localStartDateStr,
    localEndDateStr,
    breakMinutes,
    t
  );

  const resetView = () => {
    setCompanyNotes(chosenTimeReportWithWorker.companyNotes || "");

    setStartTimeStr(toLocalString(startTime));
    setEndTimeStr(toLocalString(endTime));

    setBreakStartTimeStr(
      breakStartLocalDateTime && toLocalString(breakStartLocalDateTime)
    );
    setBreakEndTimeStr(
      breakEndLocalDateTime && toLocalString(breakEndLocalDateTime)
    );

    setIsEdited(false);
  };

  useEffect(() => {
    resetView();
  }, [chosenTimeReportWithWorker]);

  const userNotes = chosenTimeReportWithWorker.userNotes || "";

  const withdrawOffer = () => {
    if (companyState.company && application) {
      const req: ApplicationRequestDto = {
        ...application,
        status: ApplicationStatus.CLOSED,
        resolution: ApplicationResolution.WITHDRAWN,
      };

      applicationActions
        .updateApplicationStatus(companyState.company.id, application.id, req)
        .then(() => {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "checkmark",
              title: t("GigsCompany.Withdrawn"),
              message: t("GigsCompany.OfferWithdrawn"),
            },
          });
        })
        .catch((err: any) => {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "cross",
              title: t("Alerts.OhNo"),
              message: t("Alerts.SomethingWentWrong"),
            },
          });
        });
    }
  };

  const handleTimeReport = (
    response: TimeReportStatus.CLOSED | TimeReportStatus.REJECTED,
    resolution?: TimeReportResolution.WITHDRAWN
  ) => {
    const newTimeReportNotTimeEdited = {
      ...chosenTimeReportWithWorker,
      companyNotes,
      status: response,
      ...(response === TimeReportStatus.CLOSED && !resolution
        ? {
            resolution: TimeReportResolution.TIME_REPORTED,
          }
        : {
            resolution: TimeReportResolution.WITHDRAWN,
          }),
    };

    const newTimeReport =
      isEdited && breakValidInsideShift && response == TimeReportStatus.CLOSED
        ? createEditedTimeReport(
            newTimeReportNotTimeEdited,
            startTime,
            startTimeStr,
            endTimeStr,
            breakMinutes.toString(),
            isEdited,
            breakStartDate,
            breakEndDate
          )
        : newTimeReportNotTimeEdited;

    Api()
      .company.timeReport.updateGigTimeReport(
        chosenTimeReportWithWorker.companyId,
        chosenTimeReportWithWorker.gigId,
        newTimeReport
      )
      .then((res) => {
        if (!resolution) {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "checkmark", // TODO: ADD SAME ICON AS IN APP
              title: t("Alerts.Updated"),
              message: t("CompanyTimeReport.TimeReportHasBeenUpdated"),
            },
          });
        } else {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "checkmark", // TODO: ADD SAME ICON AS IN APP
              title: t("Alerts.Unbooked"),
              message: t("CompanyTimeReport.TimeReportHasBeenUnbooked"),
            },
          });
        }

        setModalOpen(false);

        timeReportDispatch({
          type: "UPDATE_UNHANDLED_TIME_REPORTS",
          payload: updateTimeReportInContext(
            timeReportState.unhandledTimeReports,
            "UPDATE_UNHANDLED_TIME_REPORTS",
            newTimeReport
          ),
        });
        timeReportDispatch({
          type: "UPDATE_MONTHLY_TIME_REPORTS",
          payload: updateTimeReportInContext(
            timeReportState.monthlyTimeReports,
            "UPDATE_MONTHLY_TIME_REPORTS",
            newTimeReport
          ),
        });
        timeReportDispatch({
          type: "UPDATE_GIG_TIME_REPORTS",
          payload: updateTimeReportInContext(
            timeReportState.gigTimeReports,
            "UPDATE_GIG_TIME_REPORTS",
            newTimeReport
          ),
        });
        timeReportDispatch({
          type: "UPDATE_UNHANDLED_TIME_REPORT_COUNT",
          payload: timeReportState.unhandledTimeReportCount - 1,
        });
        if (res.data.isLastTimeReport && !resolution) {
          setRatingModalOpen(true);
        } else {
          setRatingModalOpen(false);
        }
        if (calendarState.currentWeekV2.length > 0) {
          calendarActions.updateTimeReportCalendarContext(newTimeReport);
        }
        if (resolution) {
          withdrawOffer();
        }
      })
      .catch(() => {
        alertDispatch({
          type: "SHOW_ALERT",
          payload: {
            icon: "alert", // TODO: ADD SAME ICON AS IN APP
            title: t("Alerts.OhNo"),
            message: t("CompanyTimeReport.TimeReportCouldNotBeUpdated"),
          },
        });
      });
  };

  const handleSubmit = (
    response:
      | TimeReportStatus.CLOSED
      | TimeReportStatus.APPROVED
      | TimeReportStatus.REJECTED,
    withdraw?: boolean
  ) => {
    const newTimeReportNotTimeEdited = {
      ...chosenTimeReportWithWorker,
      companyNotes,
      status: response,
      ...(response === TimeReportStatus.CLOSED &&
        !withdraw && {
          resolution: TimeReportResolution.TIME_REPORTED,
        }),
      ...(response === TimeReportStatus.CLOSED &&
        withdraw && {
          resolution: TimeReportResolution.WITHDRAWN,
        }),
    };

    const newTimeReport =
      isEdited && breakValidInsideShift && response == TimeReportStatus.APPROVED
        ? createEditedTimeReport(
            newTimeReportNotTimeEdited,
            startTime,
            startTimeStr,
            endTimeStr,
            breakMinutes.toString(),
            isEdited,
            breakStartDate,
            breakEndDate
          )
        : newTimeReportNotTimeEdited;

    Api()
      .company.timeReport.updateGigTimeReport(
        chosenTimeReportWithWorker.companyId,
        chosenTimeReportWithWorker.gigId,
        newTimeReport
      )
      .then(async (res) => {
        timeReportActions
          .createPayoutForTimeReport(chosenTimeReportWithWorker)
          .then((res) => {
            if (!withdraw) {
              alertDispatch({
                type: "SHOW_ALERT",
                payload: {
                  icon: "checkmark", // TODO: ADD SAME ICON AS IN APP
                  title: t("Alerts.Updated"),
                  message: t("CompanyTimeReport.TimeReportHasBeenUpdated"),
                },
              });
            } else {
              alertDispatch({
                type: "SHOW_ALERT",
                payload: {
                  icon: "checkmark", // TODO: ADD SAME ICON AS IN APP
                  title: t("Alerts.WithDrawnAndClosed"),
                  message: t(
                    "CompanyTimeReport.TimeReportHasBeenWithdrawnAndClosed"
                  ),
                },
              });
            }
          });
        setModalOpen(false);

        if (triggerRefreshTimeReports) {
          triggerRefreshTimeReports();
        }

        timeReportDispatch({
          type: "UPDATE_UNHANDLED_TIME_REPORTS",
          payload: updateTimeReportInContext(
            timeReportState.unhandledTimeReports,
            "UPDATE_UNHANDLED_TIME_REPORTS",
            newTimeReport
          ),
        });
        timeReportDispatch({
          type: "UPDATE_UNHANDLED_TIME_REPORT_COUNT",
          payload: timeReportState.unhandledTimeReportCount - 1,
        });
        timeReportDispatch({
          type: "UPDATE_MONTHLY_TIME_REPORTS",
          payload: updateTimeReportInContext(
            timeReportState.monthlyTimeReports,
            "UPDATE_MONTHLY_TIME_REPORTS",
            newTimeReport
          ),
        });
        timeReportDispatch({
          type: "UPDATE_UNHANDLED_TIME_REPORT_COUNT",
          payload: timeReportState.unhandledTimeReportCount - 1,
        });
        if (res.data.isLastTimeReport) {
          setRatingModalOpen(true);
        } else {
          setRatingModalOpen(false);
        }
        if (calendarState.currentWeekV2.length > 0) {
          calendarActions.updateTimeReportCalendarContext(newTimeReport);
        }
      })
      .catch(() => {
        alertDispatch({
          type: "SHOW_ALERT",
          payload: {
            icon: "alert",
            title: t("Alerts.OhNo"),
            message: t("CompanyTimeReport.CouldNotCreatePayOut"),
          },
        });
      });
  };

  const renderBanner = (
    status: TimeReportStatus,
    resolution: TimeReportResolution
  ) => {
    switch (status) {
      case TimeReportStatus.CLOSED: {
        return (
          <>
            <BannerComponent icon="check" backgroundColor={Color.SeaBlue200}>
              <Text
                fontSize={FontSize.Large}
                fontFamily={FontFamily.MontserratSemiBold}
                color={Color.Destructive}
              >
                {resolution === TimeReportResolution.CANCELLED
                  ? t(`CompanyTimeReport.Closed`)
                  : t(`CompanyTimeReport.${resolution}`)}
              </Text>
              <Text>
                {resolution === TimeReportResolution.TIME_REPORTED
                  ? t("CompanyTimeReport.TimeReportClosedReported")
                  : resolution === TimeReportResolution.CANCELLED ||
                    resolution === TimeReportResolution.SICKLEAVE ||
                    resolution === TimeReportResolution.SICKLEAVECHILD ||
                    resolution === TimeReportResolution.PARENTALLEAVE ||
                    resolution === TimeReportResolution.LEAVEOFABSENCE ||
                    resolution === TimeReportResolution.OVERTIMECOMPENSATION ||
                    resolution === TimeReportResolution.FUNERALLEAVE ||
                    resolution === TimeReportResolution.MILITARYSERVICE ||
                    resolution === TimeReportResolution.OTHER
                  ? t("CompanyTimeReport.TimeReportClosedCanceled")
                  : resolution === TimeReportResolution.WITHDRAWN
                  ? t("CompanyTimeReport.TimeReportClosedWithdrawn")
                  : resolution === TimeReportResolution.PAYONDEMAND
                  ? t("CompanyTimeReport.TimeReportPayOnDemand")
                  : t("CompanyTimeReport.TimeReportClosedPaid")}
              </Text>
            </BannerComponent>
          </>
        );
      }
      case TimeReportStatus.REJECTED: {
        return (
          <BannerComponent
            backgroundColor={Color.ChampagnePink}
            icon="cross"
            fill={Color.Destructive}
          >
            <Text
              fontSize={FontSize.Large}
              fontFamily={FontFamily.MontserratSemiBold}
              color={Color.Destructive}
            >
              {t("CompanyTimeReport.Rejected")}
            </Text>
            <Text>{t("CompanyTimeReport.TimeReportRejected")}</Text>
          </BannerComponent>
        );
      }
      case TimeReportStatus.PAID: {
        return (
          <BannerComponent
            icon="wallet"
            fill={Color.BurntSienna}
            backgroundColor={Color.Positive}
          >
            <Text
              fontSize={FontSize.Large}
              fontFamily={FontFamily.MontserratSemiBold}
              color={Color.BurntSienna}
            >
              {t("CompanyTimeReport.Paid")}
            </Text>
            <Text>{t("CompanyTimeReport.TimeReportPaid")}</Text>
          </BannerComponent>
        );
      }
      case TimeReportStatus.NEW: {
        return (
          <BannerComponent
            icon="clock"
            fill={Color.SeaBlue600}
            backgroundColor={Color.SeaBlue200}
          >
            <Text
              fontSize={FontSize.Large}
              fontFamily={FontFamily.MontserratSemiBold}
              color={Color.SeaBlue600}
            >
              {t("CompanyTimeReport.Unsubmitted")}
            </Text>
            <Text fontSize={FontSize.Small}>
              {t("CompanyTimeReport.TimeReportUnsubmitted", {
                worker: chosenTimeReportWithWorker.firstName,
              })}
            </Text>
          </BannerComponent>
        );
      }
      default: {
        return <></>;
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);

    if (chosenTimeReportWithWorker.hourlyRate && companyState.company?.id) {
      const timeReport =
        isEdited && breakValidInsideShift
          ? createEditedTimeReport(
              chosenTimeReportWithWorker,
              startTime,
              startTimeStr,
              endTimeStr,
              breakMinutes.toString(),
              isEdited,
              breakStartDate,
              breakEndDate
            )
          : chosenTimeReportWithWorker;

      getCostsTimeReports([timeReport], companyState.company?.id).then(
        (costs) => {
          setIsLoading(false);
          setTimeReportCost(costs);
        }
      );
    }
  }, [chosenTimeReportWithWorker, startTimeStr, endTimeStr, breakMinutes]);


  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        resetView();
        setModalOpen(false);
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalBody>
        <LinkWrapper>
          <CustomIcon
            color={Color.LighterDestructive}
            onClick={() => {
              resetView();
              setModalOpen(false);
            }}
            name={"cross"}
          >
            {t("General.Close")}
          </CustomIcon>
        </LinkWrapper>
        <Text fontSize={FontSize.H3} fontFamily={FontFamily.MontserratSemiBold}>
          {`${chosenTimeReportWithWorker?.firstName} ${
            chosenTimeReportWithWorker?.lastName
          }'s ${t("CompanyTimeReport.TimeReport").toLowerCase()}`}
        </Text>
        {renderBanner(
          chosenTimeReportWithWorker.status,
          chosenTimeReportWithWorker.resolution
        )}
        <Text
          fontFamily={FontFamily.MontserratSemiBold}
          color={Color.Destructive}
          fontSize={FontSize.H4}
        >
          {isEdited ? t("CompanyTimeReport.Edited") : ""}
        </Text>

        <FlexRow>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            color={Color.PitchBlack}
            fontSize={FontSize.H4}
          >
            {t("CompanyTimeReport.TotalTime")}
          </Text>
          <Text
            fontFamily={FontFamily.MontserratRegular}
            fontSize={FontSize.H4}
            color={Color.PitchBlack}
          >
            {isEdited
              ? totalTimeStr == 'N/A' ? '0t' : totalTimeStr
              : getWorkHours(t, ...[chosenTimeReportWithWorker])}
          </Text>
        </FlexRow>
        <FlexRow>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.H4}
          >
            {t("CompanyTimeReport.Date")}
          </Text>
          <Text
            fontFamily={FontFamily.MontserratRegular}
            fontSize={FontSize.H4}
          >
            {date}
          </Text>
        </FlexRow>
        <FlexRow>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.H4}
          >
            {t("CompanyTimeReport.ShiftStart")}
          </Text>

          <TimeInput
            fontFamily={FontFamily.MontserratBold}
            fontSize={FontSize.Large}
            name="startTime"
            type="time"
            value={startTimeStr}
            onChange={(e) => {
              setStartTimeStr(e.currentTarget.value);
              setIsEdited(true);
            }}
          />
          {!ShiftFormHHMMRegex.test(startTimeStr) && (
            <ErrorMessage>{t("GigsCompany.EnterAValidTime")}</ErrorMessage>
          )}
        </FlexRow>
        <FlexRow>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.H4}
          >
            {t("CompanyTimeReport.ShiftEnd")}
          </Text>

          <TimeInput
            fontFamily={FontFamily.MontserratBold}
            fontSize={FontSize.Large}
            name="endTime"
            type="time"
            required
            value={endTimeStr}
            onChange={(e) => {
              setEndTimeStr(e.currentTarget.value);
              setIsEdited(true);
            }}

          />
          {!ShiftFormHHMMRegex.test(endTimeStr) && (
            <ErrorMessage>{t("GigsCompany.EnterAValidTime")}</ErrorMessage>
          )}
        </FlexRow>
        {chosenTimeReportWithWorker.checkInTime && (
          <FlexRow>
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.H4}
            >
              {t("CompanyTimeReport.CheckIn")}
            </Text>
            <Text
              fontFamily={FontFamily.MontserratRegular}
              fontSize={FontSize.H4}
              {...(chosenTimeReportWithWorker.checkInTime != null &&
                !isWithinXMinutes(
                  new Date(chosenTimeReportWithWorker.startTime),
                  new Date(chosenTimeReportWithWorker.checkInTime),
                  15
                ) && { color: Color.Destructive })}
              onMouseEnter={() => {
                setHoverOnCheckInTime(true);
              }}
              onMouseLeave={() => {
                setHoverOnCheckInTime(false);
              }}
            >
              {new Date(
                chosenTimeReportWithWorker.checkInTime
              ).toLocaleTimeString("sv-SE", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </Text>
            {hoverOnCheckInTime &&
              !isWithinXMinutes(
                new Date(chosenTimeReportWithWorker.startTime),
                new Date(chosenTimeReportWithWorker.checkInTime),
                15
              ) && (
                <InfoContainer>
                  <Text
                    fontSize={FontSize.Small}
                    color={Color.SeaBlue600}
                    fontFamily={FontFamily.MontserratSemiBold}
                  >
                    {t(
                      "CompanyTimeReport.MoreThan15MinutesDifferenceStartTime"
                    )}
                  </Text>
                </InfoContainer>
              )}
          </FlexRow>
        )}
        {chosenTimeReportWithWorker.checkInTime && (
          <FlexRow>
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.H4}
            >
              {t("CompanyTimeReport.CheckOut")}
            </Text>
            <Text
              fontFamily={FontFamily.MontserratRegular}
              fontSize={FontSize.H4}
              {...(chosenTimeReportWithWorker.checkOutTime != null &&
                !isWithinXMinutes(
                  new Date(chosenTimeReportWithWorker.endTime),
                  new Date(chosenTimeReportWithWorker.checkOutTime),
                  15
                ) && { color: Color.Destructive })}
              onMouseEnter={() => {
                setHoverOnCheckOutTime(true);
              }}
              onMouseLeave={() => {
                setHoverOnCheckOutTime(false);
              }}
            >
              {chosenTimeReportWithWorker.checkOutTime
                ? new Date(
                    chosenTimeReportWithWorker.checkOutTime
                  ).toLocaleTimeString("sv-SE", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                : "-"}
            </Text>
            {chosenTimeReportWithWorker.checkOutTime &&
              !isWithinXMinutes(
                new Date(chosenTimeReportWithWorker.endTime),
                new Date(chosenTimeReportWithWorker.checkOutTime),
                15
              ) &&
              hoverOnCheckOutTime && (
                <InfoContainer>
                  <Text
                    fontSize={FontSize.Small}
                    color={Color.SeaBlue600}
                    fontFamily={FontFamily.MontserratSemiBold}
                  >
                    {t("CompanyTimeReport.MoreThan15MinutesDifferenceEndTime")}
                  </Text>
                </InfoContainer>
              )}
          </FlexRow>
        )}

        <BreakRow
          breakStartTimeStr={breakStartTimeStr}
          breakEndTimeStr={breakEndTimeStr}
          setBreakStartTimeStr={setBreakStartTimeStr}
          setBreakEndTimeStr={setBreakEndTimeStr}
          breakMinutes={breakMinutes}
          breakValidInsideShift={breakValidInsideShift}
          setIsEdited={setIsEdited}
        />

        {userNotes !== "" && (
          <FlexRow onClick={() => setUserNotesIsOpen(!userNotesIsOpen)}>
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.H4}
            >
              {`${chosenTimeReportWithWorker.firstName} 
            ${chosenTimeReportWithWorker.lastName}'s 
            ${t("CompanyTimeReport.Notes").toLowerCase()}`}
            </Text>
            {userNotesIsOpen && <CustomIcon name={"upward_arrow"}></CustomIcon>}
            {!userNotesIsOpen && (
              <CustomIcon name={"downward_arrow"}></CustomIcon>
            )}
          </FlexRow>
        )}
        {userNotes !== "" && userNotesIsOpen && (
          <Text
            fontFamily={FontFamily.MontserratRegular}
            fontSize={FontSize.H4}
          >
            {userNotes}
          </Text>
        )}
        <FlexRow onClick={() => setCompanyNotesIsOpen(!companyNotesIsOpen)}>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.H4}
          >
            {t("CompanyTimeReport.CompanyNotes")}
          </Text>
          {companyNotesIsOpen && (
            <CustomIcon name={"upward_arrow"}></CustomIcon>
          )}
          {!companyNotesIsOpen && (
            <CustomIcon name={"downward_arrow"}></CustomIcon>
          )}
        </FlexRow>
        {companyNotesIsOpen && (
          <TextArea
            fontSize={FontSize.Large}
            rows={3}
            name="companyNotes"
            placeholder={t("CompanyTimeReport.NotesPlaceHolder")}
            value={companyNotes}
            onChange={(e) => {
              setCompanyNotes(e.currentTarget.value);
            }}
          />
        )}
        <div>
          <FlexRow style={{ marginBottom: "40px" }}>
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.H4}
            >
              {t("CompanyTimeReport.HourlyRate")}
            </Text>

            <Text
              fontFamily={FontFamily.MontserratRegular}
              fontSize={FontSize.H4}
            >
              {chosenTimeReportWithWorker.hourlyRate}
              kr
            </Text>
          </FlexRow>
          {!timeReportState.isLoading && (
            <TotalCostWrapper>
              <RowWrapper
                style={{
                  justifyContent: "flex-start",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <LabelStyled
                  fontFamily={FontFamily.MontserratSemiBold}
                  color={Color.BurntSienna}
                  fontSize={FontSize.H4}
                >
                  {t("CompanyTimeReport.TotalCost")}{" "}
                </LabelStyled>
                <InfoCircle
                  onMouseEnter={() => setShowFeeInfo(true)}
                  onMouseLeave={() => setShowFeeInfo(false)}
                >
                  <Text
                    fontFamily={FontFamily.MontserratBold}
                    color={Color.White}
                    padding
                    fontSize={FontSize.Small}
                  >
                    i
                  </Text>
                </InfoCircle>
              </RowWrapper>
              {showFeeInfo && (
                <Text fontSize={FontSize.Small}>
                  {t("CompanyTimeReport.FeeInfo")}
                </Text>
              )}

              {timeReportCost && (
                <>
                  <RowWrapper>
                    <Text
                      fontFamily={FontFamily.MontserratSemiBold}
                      color={Color.BurntSienna}
                    >
                      {t("CompanyTimeReport.GrossSalaryExcludingOB")}:
                    </Text>
                    <Text color={Color.BurntSienna}>
                      {timeReportCost.grossSalary.toFixed(2)}
                      kr
                    </Text>
                  </RowWrapper>
                  <DividerLine />
                  <RowWrapper>
                    <Text
                      fontFamily={FontFamily.MontserratSemiBold}
                      color={Color.BurntSienna}
                    >
                      {t("CompanyTimeReport.OB")}:
                    </Text>
                    <Text color={Color.BurntSienna}>
                      {timeReportCost.obAmount.toFixed(2)}
                      kr
                    </Text>
                  </RowWrapper>
                  <DividerLine />
                  <RowWrapper>
                    <Text
                      fontFamily={FontFamily.MontserratSemiBold}
                      color={Color.BurntSienna}
                    >
                      {t("CompanyTimeReport.SocialFees")}:
                    </Text>
                    <Text color={Color.BurntSienna}>
                      {timeReportCost.socialFee.toFixed(2)}
                      kr
                    </Text>
                  </RowWrapper>
                  {chosenTimeReportWithWorker.deductionAmount !== 0 &&
                    chosenTimeReportWithWorker.deductionAmount !==
                      undefined && (
                      <RowWrapper>
                        <Text
                          fontFamily={FontFamily.MontserratSemiBold}
                          color={Color.BurntSienna}
                        >
                          {t("GigsCompany.Deduction")}:
                        </Text>
                        <Text color={Color.BurntSienna}>
                          {chosenTimeReportWithWorker.deductionAmount + " kr"}
                        </Text>
                      </RowWrapper>
                    )}
                  {chosenTimeReportWithWorker.paymentType ===
                    PaymentMethod.SIMFLEXITY_PAY && (
                    <>
                      <DividerLine />
                      <RowWrapper style={{ marginTop: "20px" }}>
                        <Text
                          fontFamily={FontFamily.MontserratBold}
                          color={Color.BurntSienna}
                        >
                          {t("CompanyTimeReport.SimflexityFee")}:
                        </Text>
                        <Text
                          fontFamily={FontFamily.MontserratBold}
                          color={Color.BurntSienna}
                        >
                          {timeReportCost.simflexityFee.toFixed(2)}
                          kr
                        </Text>
                      </RowWrapper>
                      <DividerLine />
                    </>
                  )}
                  <RowWrapper>
                    <Text
                      fontFamily={FontFamily.MontserratSemiBold}
                      color={Color.BurntSienna}
                    >
                      {t("CompanyTimeReport.TotalCost")}{" "}
                      {t("CompanyTimeReport.ExcludingVAT")}:
                    </Text>
                    {isLoading ? (
                      <CircularProgress />
                    ) : (
                      <Text
                        color={Color.BurntSienna}
                        fontFamily={FontFamily.MontserratBold}
                      >
                        {timeReportCost.totalCost.toFixed(2)}
                        kr
                      </Text>
                    )}
                  </RowWrapper>
                </>
              )}
              {chosenTimeReportWithWorker.paymentType ===
                PaymentMethod.SIMFLEXITY_PAY && (
                <RowWrapper style={{ justifyContent: "center" }}>
                  <Logo
                    src={SimflexityPayLogo}
                    style={{
                      height: "20px",
                      width: "auto",
                      marginBottom: "10px",
                    }}
                    alt="logo"
                  />
                </RowWrapper>
              )}
            </TotalCostWrapper>
          )}
        </div>
        <ButtonContainer>
          {timeReportState.isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <ButtonStyled
                backgroundColor={Color.LighterDestructive}
                isCancel
                disabled={
                  chosenTimeReportWithWorker.status !==
                  TimeReportStatus.SUBMITTED
                }
                onClick={() => {
                  if (
                    chosenTimeReportWithWorker.status ===
                    TimeReportStatus.SUBMITTED
                  ) {
                    handleSubmit(TimeReportStatus.REJECTED);
                  }
                }}
              >
                {t("CompanyTimeReport.Reject")}
              </ButtonStyled>
              <ButtonStyled
                backgroundColor={Color.LighterDestructive}
                isCancel
                disabled={
                  chosenTimeReportWithWorker.status !==
                  TimeReportStatus.SUBMITTED
                }
                onClick={() => {
                  if (
                    chosenTimeReportWithWorker.status ===
                    TimeReportStatus.SUBMITTED
                  ) {
                    handleSubmit(TimeReportStatus.CLOSED, true);
                  }
                }}
              >
                {t("CompanyTimeReport.Close")}
              </ButtonStyled>
              {chosenTimeReportWithWorker.paymentType !==
                PaymentMethod.SIMFLEXITY_PAY &&
                !timeReportState.isLoading && (
                  <ButtonStyled
                    backgroundColor={Color.ModernGreen}
                    disabled={
                      !breakValidInsideShift ||
                      chosenTimeReportWithWorker.status ===
                        TimeReportStatus.CLOSED
                    }
                    onClick={() => {
                      handleTimeReport(TimeReportStatus.CLOSED);
                    }}
                  >
                    {!isEdited &&
                    chosenTimeReportWithWorker.status ===
                      TimeReportStatus.SUBMITTED
                      ? t("CompanyTimeReport.Approve")
                      : t("CompanyTimeReport.ClearMark")}
                  </ButtonStyled>
                )}
              {chosenTimeReportWithWorker.paymentType ===
                PaymentMethod.SIMFLEXITY_PAY && (
                <ButtonStyled
                  backgroundColor={Color.ModernGreen}
                  disabled={
                    !breakValidInsideShift ||
                    chosenTimeReportWithWorker.status ===
                      TimeReportStatus.CLOSED
                  }
                  onClick={() => {
                    handleSubmit(TimeReportStatus.APPROVED);
                  }}
                >
                  {!isEdited &&
                  chosenTimeReportWithWorker.status ===
                    TimeReportStatus.SUBMITTED
                    ? t("CompanyTimeReport.Approve")
                    : t("CompanyTimeReport.ClearMark")}{" "}
                  {t("CompanyTimeReport.AndPay")}
                </ButtonStyled>
              )}
              {calenderView && (
                <ButtonStyled
                  backgroundColor={Color.LighterDestructive}
                  disabled={
                    chosenTimeReportWithWorker.status !== TimeReportStatus.NEW
                  }
                  onClick={() => {
                    if (TimeReportStatus.NEW) {
                      handleTimeReport(
                        TimeReportStatus.CLOSED,
                        TimeReportResolution.WITHDRAWN
                      );
                    }
                  }}
                >
                  {t("CompanyTimeReport.CancelShift")}
                </ButtonStyled>
              )}
            </>
          )}
        </ButtonContainer>
      </ModalBody>
    </Modal>
  );
};
