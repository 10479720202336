import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import moment from "moment";
import { Text } from "components/Typography/text.styled";
import {
  CompanyCalendarTimereport,
} from "model/Calendar";
import { shiftWithAbsence } from "web-apps/company/utils/utils";
import { TimeReportResolution } from "model/TimeReport";
import { ShiftCell } from "../weeklyCalendar.styled";
import { TimeReportModalContainer } from "../timeReportModalContainer.component";
import { ApplicationDto } from "model/Application";

type Props = {
  name: string;
  compactView: boolean;
  timeReport: CompanyCalendarTimereport | undefined;
  keyId: number;
  dashboard: boolean | undefined;
  error: string;
  gigDataId: number;
  application: ApplicationDto | undefined;
};

export const FilledCalendarGig: React.FC<Props> = ({
  name,
  compactView,
  timeReport,
  keyId,
  dashboard,
  error,
  gigDataId,
  application

}) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const tooltipContent = compactView
    ? `${moment(timeReport?.startTime).format("HH:mm") ?? ""} - ${
        moment(timeReport?.endTime).format("HH:mm") ?? ""
      }`
    : name;

  return (
    <>
      <ShiftCell
        key={keyId}
        backgroundColor={
          timeReport &&
          (shiftWithAbsence(timeReport) ||
            timeReport.resolution == TimeReportResolution.WITHDRAWN)
            ? Color.LightWarning
            : Color.Positive
        }
        data-tooltip-id={"worker-name"}
        data-tooltip-content={tooltipContent}
        onClick={() => {
          if (timeReport) {
            setModalOpen(true);
          }
        }}
      >
        {!compactView ? (
          <Text color={Color.White}>
            {timeReport
              ? `${moment(timeReport.startTime).format("HH:mm")} - ${moment(
                  timeReport.endTime
                ).format("HH:mm")}`
              : ""}
          </Text>
        ) : (
          <Text fontSize={FontSize.Small} color={Color.White}>{`${
            name.length > 6 && dashboard
              ? `${name.slice(0, 6)}...`
              : name
              ? `${name}`
              : dashboard && !name
              ? t(t("CalendarCompany.NotFilled")).slice(0, 7) + "..."
              : t(t("CalendarCompany.NotFilled"))
          }`}</Text>
        )}
        {!compactView ? (
          <Text color={Color.White}>{tooltipContent}</Text>
        ) : (
          <Tooltip
            id={"worker-name"}
            place="top"
            style={{
              backgroundColor: Color.White,
              color: Color.MidnightBlue,
              fontFamily: FontFamily.MontserratRegular,
              boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.1)",
            }}
          />
        )}
      </ShiftCell>
      {timeReport && (
        <TimeReportModalContainer
          timeReportId={timeReport.id}
          gigId={gigDataId}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          calenderView
          application={application}
        />
      )}
    </>
  );
};
