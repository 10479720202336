import { CustomIcon } from "components/icon/customIcon.component";
import { Text } from "components/Typography/text.styled";
import { Color } from "config/colors";
import { FontFamily } from "config/font";
import { CompanyStructureDto, Entity, Unit } from "model/CompanyStructure";
import React from "react";
import { IconCircularContainer } from "../../dashboard/dashboard.styled";
import {
  RowItem,
  TextColumn,
  UnitContainer,
  UnitHeader,
  WorkerContainer,
} from "./companyStructure.styled";
import { formatPhoneNumber } from "./companyStructureUtils";
import {
  getStatusHoverText,
  getStatusIcon,
  getStatusIconColor,
} from "./editUnit/companyStructureEditUnitWorkerTable.component";
import { useTranslation } from "react-i18next";

interface UnitComponentProps {
  unit: Unit;
  company: CompanyStructureDto;
  entity: Entity;
  expandedUnits: number[];
  toggleUnitExpand: (unitId: number) => void;
  setEditUnitConfig: (config: {
    companyId: number;
    entityId: number;
    unit: Unit;
  }) => void;
  setShowEditUnit: (show: boolean) => void;
}

const UnitComponent: React.FC<UnitComponentProps> = ({
  unit,
  company,
  entity,
  expandedUnits,
  toggleUnitExpand,
  setEditUnitConfig,
  setShowEditUnit,
}) => {
  const { t } = useTranslation();
  return (
    <UnitContainer key={unit.id}>
      <UnitHeader onClick={() => toggleUnitExpand(unit.id)}>
        <Text fontFamily={FontFamily.MontserratSemiBold}>{unit.name}</Text>
        <RowItem>
          <IconCircularContainer
            style={{
              zIndex: 1,
              marginLeft: 20,
              marginTop: 10,
              marginBottom: 10,
              marginRight: 20,
            }}
            isAccept
            onClick={(e) => {
              e.stopPropagation();

              setEditUnitConfig({
                companyId: company.id,
                entityId: entity.id,
                unit: unit,
              });

              setShowEditUnit(true);
            }}
          >
            <CustomIcon
              name="settings"
              size="24px"
              padding="2px"
              color={Color.White}
            />
          </IconCircularContainer>
          <CustomIcon
            name={
              expandedUnits.includes(unit.id)
                ? "upward_arrow"
                : "downward_arrow"
            }
            size="20px"
            color={Color.BurntSienna}
            style={{ marginTop: 10 }}
          />
        </RowItem>
      </UnitHeader>

      {expandedUnits.includes(unit.id) && (
        <WorkerContainer key={unit.id}>
          <TextColumn>
            {unit.workers.map((worker) => (
              <Text
                fontFamily={FontFamily.MontserratRegular}
                key={`name-${worker.id}`}
              >
                {worker.firstName} {worker.lastName}
              </Text>
            ))}
          </TextColumn>
          <TextColumn>
            {unit.workers.map((worker) => (
              <Text
                fontFamily={FontFamily.MontserratRegular}
                key={`phone-${worker.id}`}
              >
                {formatPhoneNumber(worker.phoneNumber)}
              </Text>
            ))}
          </TextColumn>
          <TextColumn style={{marginLeft: 60}}>
            {unit.workers.map((worker) => (
              <CustomIcon
                style={{ zIndex: 1, marginTop: 20 }}
                name={getStatusIcon(worker.status)}
                size="24px"
                padding="2px"
                color={getStatusIconColor(worker.status)}
                title={getStatusHoverText(worker.status, t)}
              />
            ))}
          </TextColumn>
        </WorkerContainer>
      )}
    </UnitContainer>
  );
};

export default UnitComponent;
