import React, { createContext, useReducer, useContext } from "react";
import { tipActions } from "./tipStore.actions";
import {
  TipState,
  TipActions,
  tipInitState,
  tipStateReducer,
} from "./tipStore.reducer";

export type TipDispatch = (action: TipActions) => void;

const TipStateContext = createContext<TipState | undefined>(undefined);
const TipDispatchContext = createContext<TipDispatch | undefined>(undefined);

export const TipStoreProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(tipStateReducer, tipInitState);

  return (
    <TipStateContext.Provider value={state}>
      <TipDispatchContext.Provider value={dispatch}>
        {children}
      </TipDispatchContext.Provider>
    </TipStateContext.Provider>
  );
};

export const useTipStore = () => {
  const state = useContext(TipStateContext);
  const dispatch = useContext(TipDispatchContext);

  if (state === undefined || dispatch === undefined) {
    throw new Error("useTipStore must be used within a TipStoreProvider");
  }

  const actions = tipActions(dispatch, state);
  return [state, actions, dispatch] as const;
};
