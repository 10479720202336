import { Color } from "config/colors";
import { FontSize } from "config/font";
import styled from "styled-components";
import { TabsStyled } from "../tab/tab.styled";

export const ArrowSign = styled.span`
  padding: 0 10px;
  margin: 0;
  font-size: ${FontSize.H4};

  &:hover {
    background-color: ${Color.SeaBlue200};
    cursor: pointer;
  }
`;

export const MonthSelectContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 30px;
`;

export const Cell = styled.div`
  background-color: ${Color.White};
  margin: 10px 0;
  padding: 15px;
  display: grid;
  grid-row-gap: 10px;
  grid-column-gap: 20px;
  grid-template:
    "workerContainer divider1"
    "grossSalaryContainer netSalaryContainer taxContainer payrollTaxContainer deductionAmountContainer"
    "divider2"
    "hoursSalaryContainer"
    "divider3"
    "salaryActionContainer"
    / 1fr auto;

  border-bottom: 1px solid ${Color.SeaBlue200};

  @media (min-width: 640px) {
    grid-template:
      "workerContainer divider1 grossSalaryContainer taxContainer netSalaryContainer  payrollTaxContainer deductionAmountContainer divider2 hoursSalaryContainer divider3 salaryActionContainer" auto
      / 1fr auto;
  }

  @media (min-width: 970px) {
    grid-template:
      "workerContainer divider1 grossSalaryContainer taxContainer netSalaryContainer  payrollTaxContainer deductionAmountContainer divider2 hoursSalaryContainer divider3 salaryActionContainer" auto
      / 3fr 1px 2fr 2fr 2fr 2fr 2fr 1px 2fr 1px 1fr auto;
  }

  p {
    margin: 0;
  }
`;

export const DividerLine1 = styled.div`
  display: none;
  background-color: ${Color.SeaBlue300};
  grid-area: divider1;

  @media (min-width: 640px) {
    display: unset;
  }
`;

export const DividerLine2 = styled.div`
  display: none;
  background-color: ${Color.SeaBlue300};
  grid-area: divider2;

  @media (min-width: 970px) {
    display: unset;
  }
`;

export const DividerLine3 = styled.div`
  display: none;
  background-color: ${Color.SeaBlue300};
  grid-area: divider3;

  @media (min-width: 970px) {
    display: unset;
  }
`;

export const WorkerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px;
  grid-area: workerContainer;
`;

export const GrossSalaryContainer = styled(WorkerContainer)`
  grid-area: grossSalaryContainer;
`;

export const NetSalaryContainer = styled(WorkerContainer)`
  grid-area: netSalaryContainer;
`;

export const HoursContainer = styled(WorkerContainer)`
  grid-area: hoursSalaryContainer;
`;

export const SalaryActionContainer = styled(WorkerContainer)`
  grid-area: salaryActionContainer;
  flex-direction: row;
`;

export const TaxContainer = styled(WorkerContainer)`
  grid-area: taxContainer;
`;

export const PayrollTaxContainer = styled(WorkerContainer)`
  grid-area: payrollTaxContainer;
`;

export const DeductionAmountContainer = styled(WorkerContainer)`
  grid-area: deductionAmountContainer;
`;

export const SalaryNoticeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px;
`;

export const SumRow = styled(SalaryNoticeContainer)``;

export const SalaryPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px;
`;

type Props = {
  active: boolean;
};

export const StyledTabGroup = styled(TabsStyled)`
  margin-bottom: 40px;
`;

export const TabStyled = styled.div<Props>`
  width: 50%;
  flex-basis: 50%;
  text-align: center;
  border-bottom: ${(props) =>
    props.active
      ? `3px solid ${Color.BurntSienna}`
      : `1px solid ${Color.SeaBlue200}`};

  &:hover {
    background-color: ${Color.SeaBlue200};
    cursor: pointer;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 300px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid ${Color.Grey};
  border-radius: 4px;
  font-size: ${FontSize.Standard};
`;

export const DropdownList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  border: 1px solid ${Color.Grey};
  border-top: none;
  list-style: none;
  padding: 0;
  margin: 0;
  background: ${Color.White};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
`;

type DropDownProps = {
  isSelected: boolean;
};

export const DropdownItem = styled.li<DropDownProps>`
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: ${Color.White};
  }

  ${(props) =>
    props.isSelected &&
    `
    background-color: ${Color.White};
  `}
`;

export const WorkerId = styled.span`
  color: ${Color.DarkGrey};
  font-size: 0.8em;
`;

export const DropDownContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Logo = styled.img`
  height: 18px;
  width: 50px;
  margin-left: 10px;
  margin-top: 7px;
`;

export const EmptyDiv = styled.div`
  height: 18px;
  width: 50px;
  margin-left: 10px;
  margin-top: 7px;
`;
