import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { CustomIcon } from "components/icon/customIcon.component";
import { SettingsSelect } from "components/form/select/select.styled";
import {
  LabelStyled,
  InputContainer,
} from "components/form/inputs/inputs.styled";
import { Text, H4, CustomLink } from "components/Typography/text.styled";
import { LoggedInPageContainer } from "components/pageLayouts/pageLayouts.styled";
import {
  SlideShowWrapper,
  SlideShow,
  Slide,
  RowWrapper,
  TermsContainer,
} from "./companySettingsMobileView.styled";
import i18n from "services/translation/i18n";
import { TermsAndPrivacy } from "../companySubscription/termsAndPrivacy.component";
import { API_ROOT } from "services/baseURLs.config";

type Props = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  slide: string | null;
  children: JSX.Element;
};

export const CompanySettingsMobileView: React.FC<Props> = ({
  page,
  setPage,
  slide,
  children,
}) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const history = useHistory();

  return (
    <SlideShowWrapper>
      <SlideShow page={page}>
        <Slide>
          <LoggedInPageContainer>
            <H4 fontFamily={FontFamily.LeagueSpartanBold}>
              {t("General.Settings")}
            </H4>
            <InputContainer>
              <LabelStyled>{t("ProfileCompany.Company")}</LabelStyled>
              <SettingsSelect
                onClick={() => {
                  setPage(1);
                  history.push(`${path}?page=profile`);
                }}
              >
                <CustomIcon
                  name="person"
                  size="48px"
                  color={Color.BurntSienna}
                  padding="10px"
                />
                <Text
                  fontSize={FontSize.H4}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {t("ProfileCompany.Profile")}
                </Text>
              </SettingsSelect>
              <SettingsSelect
                onClick={() => {
                  setPage(1);
                  history.push(`${path}?page=details`);
                }}
              >
                <CustomIcon
                  name="document"
                  size="48px"
                  color={Color.BurntSienna}
                  padding="10px"
                />
                <Text
                  fontSize={FontSize.H4}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {t("ProfileCompany.Details")}
                </Text>
              </SettingsSelect>

              <SettingsSelect
                onClick={() => {
                  setPage(1);
                  history.push(`${path}?page=structure`);
                }}
              >
                <CustomIcon
                  name="company"
                  size="48px"
                  color={Color.BurntSienna}
                  padding="10px"
                />

                <Text
                  fontSize={FontSize.H4}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {t("CompanyStructure.Units")}
                </Text>
              </SettingsSelect>

              <SettingsSelect
                onClick={() => {
                  setPage(1);
                  history.push(`${path}?page=users`);
                }}
              >
                <CustomIcon
                  name="person"
                  size="48px"
                  color={Color.BurntSienna}
                  padding="10px"
                />
                <Text
                  fontSize={FontSize.H4}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {t("CompanyUsers.CompanyUsers")}
                </Text>
              </SettingsSelect>
              <SettingsSelect
                onClick={() => {
                  setPage(1);
                  history.push(`${path}?page=Ob`);
                }}
              >
                <CustomIcon
                  name="clock"
                  size="48px"
                  color={Color.BurntSienna}
                  padding="10px"
                />

                <Text
                  fontSize={FontSize.H4}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {t("ProfileCompany.OB")}
                </Text>
              </SettingsSelect>
              <SettingsSelect
                onClick={() => {
                  setPage(1);
                  history.push(`${path}?page=Subscription`);
                }}
              >
                <CustomIcon
                  name="wallet"
                  size="48px"
                  color={Color.BurntSienna}
                  padding="10px"
                />

                <Text
                  fontSize={FontSize.H4}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {t("ProfileCompany.Subscription")}
                </Text>
              </SettingsSelect>
              <SettingsSelect
                onClick={() => {
                  setPage(1);
                  history.push(`${path}?page=Tip`);
                }}
              >
                <CustomIcon
                  name="cashier"
                  size="48px"
                  color={Color.BurntSienna}
                  padding="10px"
                />
                <Text
                  fontSize={FontSize.H4}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {t("CompanyTip.Tip")}
                </Text>
              </SettingsSelect>
              <SettingsSelect
                onClick={() => {
                  setPage(1);
                  history.push(`${path}?page=StampClock`);
                }}
              >
                <CustomIcon
                  name="clock"
                  size="48px"
                  color={Color.BurntSienna}
                  padding="10px"
                />

                <Text
                  fontSize={FontSize.H4}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {t("ProfileCompany.StampClock")}
                </Text>
              </SettingsSelect>
            </InputContainer>
            <InputContainer>
              <LabelStyled>{t("General.Simflexity")}</LabelStyled>
              <SettingsSelect
                onClick={() => {
                  window.open(
                    `https://www.simflexity.com/${i18n.language}/contact`
                  );
                }}
              >
                <CustomIcon
                  name="mail"
                  size="48px"
                  padding="10px"
                  color={Color.BurntSienna}
                />
                <Text
                  fontSize={FontSize.H4}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {t("General.ContactSupport")}
                </Text>
              </SettingsSelect>
            </InputContainer>

            <TermsContainer>
              <TermsAndPrivacy />
            </TermsContainer>
          </LoggedInPageContainer>
        </Slide>
        <Slide>
          <LoggedInPageContainer>
            {children}
            {slide && (
              <RowWrapper>
                <CustomLink color={Color.Destructive} to={"./settings"}>
                  {t("General.Cancel")}
                </CustomLink>
              </RowWrapper>
            )}
          </LoggedInPageContainer>
        </Slide>
      </SlideShow>
    </SlideShowWrapper>
  );
};
