import React, { useState } from "react";
import { Flex } from "../tips.styled";
import { Color } from "config/colors";
import Modal from "@material-ui/core/Modal";
import { useTranslation } from "react-i18next";
import { Text } from "components/Typography/text.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import { LinkWrapper, ModalBody } from "../../../workgroup/workgroup.styled";

type AlertModaProps = {
  children: (
    openAlertModal: (
      text: string,
      showCancel?: boolean,
      onConfirm?: () => void
    ) => void
  ) => React.ReactNode;
};

export const AlertModal: React.FC<AlertModaProps> = ({ children }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState("");
  const [showCancel, setShowCancel] = useState(false);
  const [onConfirm, setOnConfirm] = useState<() => void>(() => {});

  const openAlertModal = (
    text: string,
    showCancel = false,
    onConfirm?: () => void
  ) => {
    setText(text);
    setShowCancel(showCancel);
    setOnConfirm(() => onConfirm || (() => {}));
    setIsOpen(true);
  };

  const closeAlertModal = () => setIsOpen(false);

  return (
    <>
      {children(openAlertModal)}
      <Modal open={isOpen} onClose={closeAlertModal}>
        <ModalBody>
          <LinkWrapper>
            <CustomIcon
              color={Color.LighterDestructive}
              onClick={closeAlertModal}
              name={"cross"}
            >
              {t("General.Close")}
            </CustomIcon>
          </LinkWrapper>
          <Text style={{ display: "flex", justifyContent: "center" }}>
            {t(`${text}`)}
          </Text>
          <Flex>
            <ButtonStyled
              style={{ marginTop: 40 }}
              backgroundColor={Color.ModernGreen}
              onClick={() => {
                if (onConfirm) {
                  onConfirm();
                }
                closeAlertModal();
              }}
            >
              {t("General.Ok")}
            </ButtonStyled>

            {showCancel && (
              <ButtonStyled
                style={{ marginTop: 40 }}
                isCancel
                backgroundColor={Color.LighterDestructive}
                onClick={closeAlertModal}
              >
                {t("General.Abort")}
              </ButtonStyled>
            )}
          </Flex>
        </ModalBody>
      </Modal>
    </>
  );
};
