import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyGigDto, GigType } from "model/Gig";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { getWorkerImages } from "web-apps/company/utils/utils";
import { CustomIcon } from "components/icon/customIcon.component";
import { OutlineCircle, SmallImage } from "../gig/gigs.styled";
import { Text } from "components/Typography/text.styled";
import {
  ImageContainer,
  ImageWrapper,
  WorkerContainer,
  DropDownRow,
  Row,
} from "./gigDetail.styled";
import { Modal } from "@material-ui/core";
import { OfferModal } from "./offerModal.component";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { IconCircularContainer } from "../dashboard/dashboard.styled";
import { Filter, FilterType, GroupType } from "model/Calendar";
import { Api } from "services/api/api.service";
import {
  ApplicationDto,
  ApplicationResolution,
  ApplicationStatus,
} from "model/Application";
import { ApplicantDetailsModal } from "../applicantDetails/applicantDetailsModal.component";

type Props = {
  gigData: CompanyGigDto;
  isModalOpen: boolean;
  shouldDefaultOpen?: boolean;
};

export const GigDetailWorkers: React.FC<Props> = ({
  gigData,
  isModalOpen,
  shouldDefaultOpen = false,
}) => {
  const { t } = useTranslation();
  const [workerImages, setWorkerImages] = useState<string[]>([]);
  const [offeredWorkerImages, setOfferedWorkerImages] = useState<string[]>([]);
  const [offerModalOpen, setOfferModalOpen] = useState(false);
  const [offerHourlyRate, setOfferHourlyRate] = useState(gigData.maxRate);
  const [offerList, setOfferList] = useState<
    {
      workerId: number;
      rate: number;
      usingOfferRate: boolean;
      type: "favorite" | "workgroup";
    }[]
  >([]);
  const [useOfferRateForAll, setUseOfferRateForAll] = useState(false);
  const [workersOpen, setWorkersOpen] = useState<boolean>(
    shouldDefaultOpen || isModalOpen
  );

  useEffect(() => {
    if (shouldDefaultOpen || isModalOpen) {
      setWorkersOpen(true);
    }
  }, [shouldDefaultOpen, isModalOpen]);

  const [workerIds, setWorkerIds] = useState<(number | null)[]>([]);
  const [offers, setOffers] = useState<ApplicationDto[]>();
  const [accepted, setAccepted] = useState<ApplicationDto[]>();
  const [gigApplications, setGigApplications] = useState<ApplicationDto[]>();
  const [companyState] = useCompanyStore();
  const [applicationModalOpen, setApplicationModalOpen] = useState(false);
  const lastShiftEndTime = gigData.shifts[gigData.shifts.length - 1]?.endTime;
  const isShiftOngoing = new Date(lastShiftEndTime).getTime() > Date.now();
  const [hoverOffer, setHoverOffer] = useState(false);
  const [filter, setFilter] = useState<Filter[]>([
    {
      filterId: -1,
      groupType: GroupType.NONE,
      filterType: FilterType.NONE,
    },
  ]);
  const [application, setApplication] = useState<ApplicationDto | null>();

  useEffect(() => {
    if (!gigData.workers) return;

    const workerFirebaseIds = gigData.workers.map(
      (worker) => worker.firebaseId
    );
    const workerIds = gigData.workers.map((worker) => worker.id);
    const workerImageUrls = Array(workerIds.length).fill(null);

    getWorkerImages(workerFirebaseIds).then((data) => {
      workerImageUrls.splice(0, data.length, ...data);
      setWorkerImages(workerImageUrls);
      setWorkerIds(workerIds);
    });
  }, [gigData.workers]);

  useEffect(() => {
    setApplicationModalOpen(true);
  }, [application]);

  const fetchGigApplications = () => {
    if (companyState.company?.id) {
      Api()
        .company.application.getGigApplications(
          companyState.company.id,
          gigData.id
        )
        .then((res) => {
          setGigApplications(res.data);
        })
        .catch((err) => {
          console.log("Error fetching gig applications", err);
        });
    }
  };

  useEffect(() => {
    fetchGigApplications();
  }, []);

  useEffect(() => {
    let offered: ApplicationDto[] = [];
    let accepted: ApplicationDto[] = [];
    if (gigApplications) {
      gigApplications.forEach((application) => {
        if (application.status === ApplicationStatus.OFFERED) {
          offered.push(application);
        }
        if (application.resolution === ApplicationResolution.ACCEPTED) {
          accepted.push(application);
        }
      });
      setOffers(offered);
      setAccepted(accepted);
    }
  }, [gigApplications]);

  useEffect(() => {
    if (!offers) return;

    const workerFirebaseIds = offers.map((offer) => offer.worker.firebaseId);

    const offerIds = offers.map((offer) => offer.worker.id);
    const workerImageUrls = Array(
      offers && offerIds.length + workerIds.length > gigData.amountOfWorkers
        ? offerIds.length
        : gigData.amountOfWorkers - workerIds.length
    ).fill(null);

    getWorkerImages(workerFirebaseIds).then((data) => {
      workerImageUrls.splice(0, data.length, ...data);
      setOfferedWorkerImages(workerImageUrls);
    });
  }, [offers, workerIds]);

  return (
    <>
      {!isModalOpen ? (
        <DropDownRow
          onClick={() => {
            setWorkersOpen(!workersOpen);
          }}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
          >
            {`${t("GigsCompany.Worker")} (${gigData.workers.length} / ${
              gigData.amountOfWorkers
            } st)`}
          </Text>
          <CustomIcon
            name={workersOpen ? "upward_arrow" : "downward_arrow"}
            size="24px"
            color={Color.BurntSienna}
            style={{ marginTop: 10 }}
          />
        </DropDownRow>
      ) : (
        <Row>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
          >
            {`${t("GigsCompany.Worker")} (${gigData.amountOfWorkers} st)`}
          </Text>
        </Row>
      )}
      {workersOpen && (
        <>
          <WorkerContainer>
            <ImageContainer>
              {gigData.type !== GigType.PUBLIC && (
                <IconCircularContainer
                  onClick={() => {
                    if (isShiftOngoing) {
                      setOfferModalOpen(true);
                    }
                  }}
                  isAccept
                  style={{
                    cursor: isShiftOngoing ? "pointer" : "auto",
                    margin: "0 auto",
                    padding: 4,
                    marginTop: 2,
                    marginRight: 4,
                  }}
                  isMedium
                  onMouseEnter={() => setHoverOffer(true)}
                  onMouseLeave={() => setHoverOffer(false)}
                  isDisabled={!isShiftOngoing}
                >
                  <CustomIcon
                    name="addPerson"
                    size="34px"
                    color={Color.White}
                  />
                </IconCircularContainer>
              )}
              {hoverOffer && (
                <Text
                  fontSize={FontSize.Small}
                  color={Color.Black}
                  style={{
                    marginLeft: 4,
                    backgroundColor: Color.LightGrey03,
                    padding: 4,
                    borderRadius: 4,
                    marginRight: 4,
                  }}
                >
                  {t("GigsCompany.SendOffer")}
                </Text>
              )}
              {workerImages.map((url, index) => {
                const handleClick = () => {
                  const application = accepted ? accepted[index] : undefined;

                  setApplication(application);
                };
                return (
                  <ImageWrapper key={index}>
                    {url && url !== "no-image" ? (
                      <SmallImage
                        src={url}
                        style={{
                          cursor: "pointer",
                          border: `2px solid ${Color.LighterPositive}`,
                        }}
                        onClick={handleClick}
                      />
                    ) : (
                      <OutlineCircle accepted>
                        <CustomIcon
                          name="person"
                          size="34px"
                          color={Color.SeaBlue500}
                          style={{
                            cursor: isShiftOngoing ? "pointer" : "auto",
                            margin: "0 auto",
                            padding: 4,
                            marginTop: 2,
                          }}
                          onClick={handleClick}
                        />
                      </OutlineCircle>
                    )}
                  </ImageWrapper>
                );
              })}
              {offeredWorkerImages.map((url, index) => {
                const handleClick = () => {
                  const application = offers ? offers[index] : undefined;
                  setApplication(application);
                };
                return (
                  <ImageWrapper key={index}>
                    {url && url !== "no-image" ? (
                      <SmallImage
                        src={url}
                        style={{
                          cursor: "pointer",
                          border: "2px solid orange",
                        }}
                        onClick={handleClick}
                      />
                    ) : url && url == "no-image" ? (
                      <OutlineCircle>
                        <CustomIcon
                          name="person"
                          size="34px"
                          color={Color.SeaBlue500}
                          style={{
                            cursor: "pointer",
                            margin: "0 auto",
                            padding: 4,
                            marginTop: 2,
                          }}
                          onClick={handleClick}
                        />
                      </OutlineCircle>
                    ) : (
                      <CustomIcon
                        name="person"
                        size="34px"
                        color={Color.SeaBlue500}
                        style={{
                          cursor: isShiftOngoing ? "pointer" : "auto",
                          margin: "0 auto",
                          padding: 4,
                          marginTop: 2,
                        }}
                        onClick={() => {
                          if (
                            isShiftOngoing &&
                            gigData.type !== GigType.PUBLIC
                          ) {
                            setOfferModalOpen(true);
                          }
                        }}
                      />
                    )}
                  </ImageWrapper>
                );
              })}
            </ImageContainer>
          </WorkerContainer>
        </>
      )}
      <Modal
        open={offerModalOpen}
        onClose={() => {
          setOfferModalOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <OfferModal
          offerList={offerList}
          setOfferList={setOfferList}
          createGig={false}
          gigData={gigData}
          setOfferModalOpen={setOfferModalOpen}
          offerRate={offerHourlyRate}
          setOfferRate={setOfferHourlyRate}
          useOfferRateForAll={useOfferRateForAll}
          setUseOfferRateForAll={setUseOfferRateForAll}
          filter={filter}
          fromGigView
          fetchGigApplications={() => fetchGigApplications()}
          type={gigData?.type}
          selectedWorkGroup={
            gigData?.type == GigType.WORKPOOL ? gigData.workGroupId : null
          }
          amountOfWorkers={gigData?.amountOfWorkers}
        />
      </Modal>
      {applicationModalOpen && application && (
        <ApplicantDetailsModal
          applicant={application}
          setWorkerApplication={setApplication}
          setShowApplicationModal={setApplicationModalOpen}
          showApplicationModal={applicationModalOpen}
        />
      )}
    </>
  );
};
