import React from "react";
import { FontFamily, FontSize } from "config/font";
import { Text } from "components/Typography/text.styled";
import { TabsStyled, TabStyled } from "../tab/tab.styled";
import { useTranslation } from "react-i18next";
import { Color } from "config/colors";
import { WorkGroupDetailsStatus } from "model/Workgroup";

type Props = {
  setStatus: React.Dispatch<React.SetStateAction<WorkGroupDetailsStatus>>;
  status: string;
  membersLength: number;
  invitedLength: number;
  declinedLength: number;
  favorite: boolean;
};

export const WorkGroupTabs: React.FC<Props> = ({
  setStatus,
  status,
  membersLength,
  invitedLength,
  declinedLength,
  favorite,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <TabsStyled>
        <TabStyled
          active={status === WorkGroupDetailsStatus.Members}
          onClick={() => {
            if (membersLength == 0) return;
            setStatus(WorkGroupDetailsStatus.Members);
          }}
          isDisabled={membersLength == 0}
        >
          <Text
            fontSize={FontSize.Standard}
            fontFamily={FontFamily.MontserratSemiBold}
            color={membersLength == 0 ? Color.Disabled : Color.PitchBlack}
          >
            {t("WorkGroup.Members")} ({membersLength})
          </Text>
        </TabStyled>
        {!favorite && (
          <>
            <TabStyled
              active={status === WorkGroupDetailsStatus.Invited}
              onClick={() => {
                if (invitedLength == 0) return;
                setStatus(WorkGroupDetailsStatus.Invited);
              }}
              isDisabled={invitedLength == 0}
            >
              <Text
                fontSize={FontSize.Standard}
                fontFamily={FontFamily.MontserratSemiBold}
                color={invitedLength == 0 ? Color.Disabled : Color.PitchBlack}
              >
                {t("WorkGroup.Invited")} ({invitedLength})
              </Text>
            </TabStyled>
            <TabStyled
              active={status === WorkGroupDetailsStatus.Declined}
              onClick={() => {
                if (declinedLength == 0) return;
                setStatus(WorkGroupDetailsStatus.Declined);
              }}
              isDisabled={declinedLength == 0}
            >
              <Text
                fontSize={FontSize.Standard}
                fontFamily={FontFamily.MontserratSemiBold}
                color={declinedLength == 0 ? Color.Disabled : Color.PitchBlack}
              >
                {t("WorkGroup.Declined")} ({declinedLength})
              </Text>
            </TabStyled>
          </>
        )}
      </TabsStyled>
    </>
  );
};
