import { H3 } from "components/Typography/text.styled";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { PreferedPaymentSetting } from "../../companySettingsDesktopView/preferedPaymentSetting.component";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { useAlertStore } from "stores/alertStore/alertStore";
import { PaymentMethod } from "model/Gig";
import { SystemFormData } from "model/Company";
import { Prompt } from "react-router-dom";
import { Api } from "services/api/api.service";

export const CompanySystemSettings: React.FC = () => {
  const { t } = useTranslation();
  const [companyState] = useCompanyStore();
  const [, , alertDispatch] = useAlertStore();
  const [preferedPaymentMethod, setPreferedPaymentMethod] = useState<
    PaymentMethod | undefined
  >();
  const [formData, setFormData] = useState<SystemFormData>({
    preferedPaymentMethod: undefined,
  });
  const [changedSettings, setChangedSettings] = useState<
    {
      key: "DefaultGigPaymentType";
      value: string | undefined | null;
    }[]
  >();

  const showAlert = (error?: boolean) => {
    if (!error) {
      alertDispatch({
        type: "SHOW_ALERT",
        payload: {
          icon: "checkmark",
          title: t("Alerts.Updated"),
          message: t("System.CompanySystemSettingsUpdated"),
        },
      });
    } else {
      alertDispatch({
        type: "SHOW_ALERT",
        payload: {
          icon: "alert",
          title: t("Alerts.OhNo"),
          message: t("System.CompanySystemSettingsNotUpdated"),
        },
      });
    }
  };

  const handleUpdate = () => {
    changedSettings?.forEach((setting) => {
      if (
        companyState.company &&
        setting.value !== undefined &&
        setting.value !== null
      ) {
        Api()
          .company.systemSettings.updateCompanySetting(
            companyState.company.id,
            setting.key,
            setting.value
          )
          .then(() => {
            setPreferedPaymentMethod(formData.preferedPaymentMethod);
            showAlert();
          })
          .catch(() => {
            showAlert(true);
          });
      }
    });
  };

  useEffect(() => {
    if (preferedPaymentMethod !== formData.preferedPaymentMethod) {
      setChangedSettings((prevSettings) => {
        const updatedSettings = prevSettings ? [...prevSettings] : [];
        const index = updatedSettings.findIndex(
          (setting) => setting.key === "DefaultGigPaymentType"
        );

        if (index !== -1) {
          updatedSettings[index].value = formData.preferedPaymentMethod;
        } else {
          updatedSettings.push({
            key: "DefaultGigPaymentType",
            value: formData.preferedPaymentMethod,
          });
        }

        return updatedSettings;
      });
    }
  }, [formData.preferedPaymentMethod]);

  const madeChanges = useMemo(() => {
    const checkboxesChanged =
      formData.preferedPaymentMethod !== preferedPaymentMethod;
    return checkboxesChanged;
  }, [formData, preferedPaymentMethod]);

  return (
    <>
      <Prompt
        when={madeChanges}
        message={t("ProfileCompany.YouHaveUnSavedChanges")}
      />
      <H3>{t("System.System")}</H3>
      <PreferedPaymentSetting
        setPreferedPaymentMethod={setPreferedPaymentMethod}
        setFormData={setFormData}
        formData={formData}
      />

      <ButtonStyled disabled={!madeChanges} onClick={() => handleUpdate()}>
        {t("General.Save")}
      </ButtonStyled>
    </>
  );
};
