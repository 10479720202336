import styled from "styled-components";
import { Color } from "config/colors";

export const ModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 840px;
  min-width: 660px;
  max-height: 90%;
  overflow-y: auto;
  border-radius: 10px;
  background-color: ${Color.White};
  outline: none;
  padding: 20px;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.2);
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    margin: 0 10px;
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    margin: 5px;
  }
`;

export const CheckInCheckOutHoverModalBody = styled.div`
  border-radius: 10px;
  background-color: ${Color.White};
  outline: none;
  padding: 20px;
`;

export const TotalCostWrapper = styled.div`
  display: inline-block;
  margin-bottom: 20px;
  background-color: ${Color.Positive};
  border-radius: 8px;
  padding: 15px;
  width: 70%;
  margin-bottom: 40px;
`;

export const InfoCircle = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${Color.BurntSienna};
  border-radius: 40px;
  width: 17px;
  height: 17px;
  align-items: center;
  margin: 0 5px;

  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.95);
  }
`;

export const FlexRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
  border-bottom: 1px solid ${Color.SeaBlue200};
`;

export const BreakInfoRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
`;

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  div {
    margin: 0 0 10px 0;
  }
`;

export const InfoContainer = styled.div`
  z-index: 100;
  position: absolute;
  top: 50px;
  background-color: ${Color.LightGrey};
  border-radius: 8px;
  padding: 6px;

  p {
    margin: 0;
  }
`;

export const DividerLine = styled.div`
  margin-top: 10px;
  width: 100%;
  margin: 0 auto;
  height: 1px;
  background-color: ${Color.BurntSienna};
`;

export const BreakInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
`;
