import React from "react";
import { CalculationType } from "model/Tip";
import { useTranslation } from "react-i18next";
import { FontFamily, FontSize } from "config/font";
import { Text } from "components/Typography/text.styled";
import { TipTabStyled, TipTabsStyled } from "../tips.styled";

type DistributionTabsProps = {
  selectedDistribution: CalculationType;
  setAutomaticAlternative: (alternative: CalculationType) => void;
};

export const DistributionTabs: React.FC<DistributionTabsProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <TipTabsStyled style={{ width: "auto" }}>
        <TipTabStyled
          active={props.selectedDistribution === CalculationType.Time}
          onClick={() => props.setAutomaticAlternative(CalculationType.Time)}
        >
          <Text
            fontSize={FontSize.Standard}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTip.PerHour")}
          </Text>
        </TipTabStyled>
        <TipTabStyled
          active={props.selectedDistribution === CalculationType.Workgroup}
          onClick={() =>
            props.setAutomaticAlternative(CalculationType.Workgroup)
          }
        >
          <Text
            fontSize={FontSize.Standard}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTip.PerWorkgroup")}
          </Text>
        </TipTabStyled>
      </TipTabsStyled>
    </>
  );
};
