import React, { useState } from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import {
  PrivateRoute,
  PublicRoute,
} from "web-apps/company/components/customRoute/customRoute.component";
import { CompanyStoreProvider } from "./stores/companyStore/companyStore";
import { ApplicationPage } from "./pages/application/application.page";
import { ApplicantDetailPage } from "./pages/application/applicantDetail.page";
import { GigApplicantsPage } from "./pages/application/gigApplicants.page";
import { CompanySettingsPage } from "./pages/companySettings/companySettings.page";
import { ProfilePage } from "./pages/profile/profile.page";
import { CalendarPage } from "./pages/calendar/calendar.page";
import { WorkgroupPage } from "./pages/workgroup/workgroup.page";
import { FavoritePage } from "./pages/profile/favorite/favorite.page";
import { FavoriteDetailPage } from "./pages/profile/favorite/favoriteDetail.page";
import { LoginPage } from "./pages/login/login.page";
import { CreateGigPage } from "./pages/createGig/createGig.page";
import { GigPage } from "./pages/gig/gig.page";
import { NavBar } from "components/nav/navBar/navBar.component";
import { Drawer } from "components/nav/drawer/drawer.component";
import { ChatStoreProvider } from "./stores/chatStore/chatStore";
import { ApplicationStoreProvider } from "./stores/applicationStore/applicationStore";
import { TimeReportStoreProvider } from "./stores/timeReportStore/timeReportStore";
import { RegistrationPage } from "./pages/registration/registration.page";
import { EditGigPage } from "./pages/editGig/editGig.page";
import { SignUpPage } from "./pages/signUp/signUp.page";
import { GigTimeReportsPage } from "./pages/timeReport/gigTimeReports.page";
import { TimeReportsPage } from "./pages/timeReport/timeReports.page";
import { ChatPage } from "./pages/chat/chat.page";
import { CompanySelectPage } from "./pages/companySelect/companySelect.page";
import { CalendarStoreProvider } from "./stores/calendarStore/calendarStore";
import { DashboardPage } from "./pages/dashboard/dashboard.page";
import { CompanyEmployeesPage } from "./pages/companyEmployees/companyEmployees.page";
import { ClockStampPage } from "./pages/clockStamp/clockStamp.page";
import { StaffingWorkersStoreProvider } from "./stores/staffingStore/staffingWorkersStore";
import { WorkgroupStoreProvider } from "./stores/workgroupStore/workgroupStore";
import { SalaryPage } from "./pages/salary/salary.page";
import { TipStoreProvider } from "./stores/tipStore/tipStore";

export const CompanyApp = () => {
  const { path } = useRouteMatch();
  const [open, setOpen] = useState(false);

  return (
    <CompanyStoreProvider>
      <ChatStoreProvider>
        <ApplicationStoreProvider>
          <TimeReportStoreProvider>
            <TipStoreProvider>
              <WorkgroupStoreProvider>
                <StaffingWorkersStoreProvider>
                  <CalendarStoreProvider>
                    <NavBar open={open} setOpen={setOpen} />
                    <Drawer open={open} setOpen={setOpen} />
                    <Switch>
                      <PublicRoute path={`${path}/login`}>
                        <LoginPage />
                      </PublicRoute>
                      <PublicRoute path={`${path}/sign-up`}>
                        <SignUpPage />
                      </PublicRoute>
                      <PrivateRoute path={`${path}/company-select`}>
                        <CompanySelectPage />
                      </PrivateRoute>
                      <PublicRoute path={`${path}/registration`}>
                        <RegistrationPage />
                      </PublicRoute>
                      <PrivateRoute path={`${path}/overview`}>
                        <div>Overview</div>
                      </PrivateRoute>
                      <PrivateRoute path={`${path}/overview`}>
                        <div>Overview</div>
                      </PrivateRoute>
                      <PrivateRoute
                        path={`${path}/applications/application/:id`}
                        render={(props) => <ApplicantDetailPage {...props} />}
                      />
                      <PrivateRoute
                        path={`${path}/applications/:id`}
                        render={(props) => <GigApplicantsPage {...props} />}
                      />
                      <PrivateRoute path={`${path}/applications`}>
                        <ApplicationPage />
                      </PrivateRoute>
                      <PrivateRoute path={`${path}/gig-create`}>
                        <CreateGigPage />
                      </PrivateRoute>
                      <PrivateRoute path={`${path}/stampclock`}>
                        <ClockStampPage />
                      </PrivateRoute>
                      <PrivateRoute path={`${path}/gigs`}>
                        <GigPage />
                      </PrivateRoute>
                      <PrivateRoute path={`${path}/dashboard`}>
                        <DashboardPage />
                      </PrivateRoute>
                      <PrivateRoute
                        path={`${path}/gig-edit/:id`}
                        render={(props) => <EditGigPage {...props} />}
                      />
                      <PrivateRoute
                        path={`${path}/gig-edit/:id/clone`}
                        render={(props) => <EditGigPage {...props} />}
                      />
                      <PrivateRoute
                        path={`${path}/profile/favorites/:id`}
                        render={(props) => <FavoriteDetailPage {...props} />}
                      />
                      <PrivateRoute path={`${path}/profile/favorites`}>
                        <FavoritePage />
                      </PrivateRoute>
                      <PrivateRoute path={`${path}/profile`}>
                        <ProfilePage />
                      </PrivateRoute>
                      <PrivateRoute path={`${path}/settings`}>
                        <CompanySettingsPage />
                      </PrivateRoute>
                      <PrivateRoute path={`${path}/chat`}>
                        <ChatPage />
                      </PrivateRoute>
                      <PrivateRoute
                        path={`${path}/time-reports/:id`}
                        render={(props) => <GigTimeReportsPage {...props} />}
                      />
                      <PrivateRoute
                        path={`${path}/time-reports/:id`}
                        render={(props) => <GigTimeReportsPage {...props} />}
                      />
                      <PrivateRoute path={`${path}/time-reports`}>
                        <TimeReportsPage />
                      </PrivateRoute>
                      <PrivateRoute path={`${path}/calendar`}>
                        <CalendarPage />
                      </PrivateRoute>
                      <PrivateRoute path={`${path}/work-group`}>
                        <WorkgroupPage />
                      </PrivateRoute>
                      <PrivateRoute path={`${path}/salary`}>
                        <SalaryPage />
                      </PrivateRoute>
                      <PrivateRoute path={`${path}/employees`}>
                        <CompanyEmployeesPage />
                      </PrivateRoute>
                    </Switch>
                  </CalendarStoreProvider>
                </StaffingWorkersStoreProvider>
              </WorkgroupStoreProvider>
            </TipStoreProvider>
          </TimeReportStoreProvider>
        </ApplicationStoreProvider>
      </ChatStoreProvider>
    </CompanyStoreProvider>
  );
};
