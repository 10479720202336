import { Logo } from "components/nav/logo/logo.styled";
import React from "react";
import styled from "styled-components";
import Modal from "@material-ui/core/Modal";
import SimflexityPayLogo from "images/logo/svg/simflexityPay-logo.svg";
import { useTranslation } from "react-i18next";

import { ButtonStyled } from "components/buttons/buttons.styled";

import { Color } from "config/colors";
import { H4 } from "components/Typography/text.styled";
import { FontFamily, FontSize } from "config/font";

type PropsTypes = {
  setShowTerms: React.Dispatch<React.SetStateAction<boolean>>;
  showTerms: boolean;
};

export const SimflexityPayTerms = (props: PropsTypes) => {
  const { t } = useTranslation();
  const userLang = navigator.language;

  return (
    <Modal
      open={props.showTerms}
      onClose={() => {
        props.setShowTerms(false);
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{ overflow: "auto" }}
    >
      <ModalBody>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Logo
            src={SimflexityPayLogo}
            style={{ height: "30px", width: "auto" }}
            alt="logo"
          />
        </div>
        {userLang == "sv-SE" ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
                marginBottom: 10,
              }}
            >
              <H4
                fontSize={FontSize.H4}
                style={{ margin: 0, marginBottom: 10 }}
              >
                Allmänna villkor för SimflexityPay
              </H4>
            </div>
            <div>
              <TermsText>
                <SmallTitle>
                  1. Tjänsteupplägg: SimflexityPay – Total, Flex och Solo
                </SmallTitle>
                <TermsText>
                  Simflexity erbjuder tre olika tjänsteupplägg för
                  lönehantering:
                  <ul>
                    <li>
                      Total – Simflexity är arbetsgivare med vissa
                      arbetsgivarrelaterade skyldigheter men exkluderar sjuklön
                      om inte annat avtalas.
                    </li>
                    <li>
                      Flex – Simflexity fungerar endast som en plattform för
                      löneutbetalningar (Pay-on-demand) och är inte
                      arbetsgivare.
                    </li>
                    <li>
                      Solo – Simflexity tillhandahåller endast löneunderlag och
                      plattformsstöd, medan uppdragsgivaren är fullt ansvarig
                      som arbetsgivare.
                    </li>
                  </ul>
                </TermsText>

                <SmallTitle>1.1 Total</SmallTitle>
                <TermsText>
                  Simflexity är arbetsgivare och ansvarar för:
                  <ul>
                    <li>
                      Betalning av löner, preliminärskatt och sociala avgifter.
                    </li>
                    <li>Olycksfallsförsäkring (exklusive sjuklön).</li>
                    <li>
                      Nettolöneutbetalning efter godkänd tidrapport, inklusive
                      semesterersättning och eventuella OB-tillägg.
                    </li>
                    <li>
                      Ersättning för hela schemalagda pass, även om
                      arbetstagaren skickas hem tidigare än planerat.
                    </li>
                  </ul>
                </TermsText>
                <SmallTitle>Uppdragsgivarens ansvar</SmallTitle>
                <TermsText>
                  <ul>
                    <li>
                      Att arbetsmiljölagstiftning och gällande lagar följs, samt
                      att nödvändiga försäkringar tecknas i enlighet med
                      gällande krav.
                    </li>
                    <li>
                      Att säkerställa en trygg och säker arbetsmiljö innan
                      arbetstagaren påbörjar sitt arbete.
                    </li>
                    <li>
                      Att meddela Simflexity om eventuella arbetsmiljörisker
                      eller säkerhetsbrister som kan påverka arbetstagarens
                      arbetsförhållanden.
                    </li>
                    <li>
                      Att vid schemaändringar informera Simflexity i god tid. Om
                      arbetstiden kortas ner utan godkänd ändring, har
                      arbetstagaren rätt att få full ersättning för det
                      schemalagda passet.
                    </li>
                    <li>
                      Att vid eventuella tvister eller konflikter på
                      arbetsplatsen mellan arbetstagaren och uppdragsgivaren i
                      första hand hantera detta internt och vid behov eskalera
                      till Simflexity för eventuell medling.
                    </li>
                    <li>
                      Att följa gällande regler om diskriminering, trakasserier
                      och anständig arbetsmiljö och att rapportera eventuella
                      incidenter till Simflexity.
                    </li>
                    <li>
                      Att godkänna arbetstagarens tidrapport innan lön kan
                      betalas ut. Alla godkända tidrapporter kommer att
                      faktureras uppdragsgivaren, eftersom denne har godkänt
                      tiderna.
                    </li>
                    <li>
                      Att informera Simflexity om eventuella fel i godkända
                      tidrapporter inom 48 timmar efter godkännande. Efter denna
                      period kan inga ändringar göras av uppdragsgivaren.
                    </li>
                    <li>
                      Att säkerställa korrekt rapportering av övertid och extra
                      arbetstimmar samt meddela Simflexity i förväg om
                      eventuella avvikelser.
                    </li>
                    <li>
                      Att ansvara för eventuell misskötsel eller olämpligt
                      beteende från arbetstagarens sida. Uppdragsgivaren har
                      rätt att avbryta samarbetet med en specifik arbetstagare,
                      men eventuella ekonomiska åtaganden för redan bokade pass
                      kvarstår.
                    </li>
                    <li>
                      Att vid avtalsbrott eller bristande ansvar gentemot
                      arbetsmiljö och anställningsvillkor kunna bli föremål för
                      avtalssanktioner eller uppsägning av samarbetet med
                      Simflexity.
                    </li>
                    <li>
                      Att betala fakturor i tid. Vid sen betalning tillkommer
                      dröjsmålsränta enligt räntelagen samt en påminnelseavgift.
                      Vid fortsatt utebliven betalning kan inkassoåtgärder
                      vidtas och tillgång till Simflexitys tjänster kan
                      begränsas.
                    </li>
                    <li>
                      Att säga upp samarbetet med minst 30 dagars skriftlig
                      uppsägningstid. Bokade pass under uppsägningstiden ska
                      fullgöras enligt avtal.
                    </li>
                    <li>
                      Att hantera och förebygga trakasserier eller
                      diskriminering på arbetsplatsen. Vid anmälda
                      arbetsmiljöproblem kan Simflexity frysa samarbetet tills
                      situationen är utredd.
                    </li>
                    <li>
                      Att följa GDPR och tillämpliga regler för hantering av
                      personuppgifter. Uppgifter relaterade till samarbetet kan
                      sparas av Simflexity enligt lagstadgade krav.
                    </li>
                    <li>
                      Att kontakta Simflexity för support vid löne- eller
                      schemafrågor. Simflexity åtar sig att hantera akuta frågor
                      inom 24 timmar på vardagar.
                    </li>
                  </ul>
                </TermsText>
                <SmallTitle>1.2 Sjuklön</SmallTitle>
                <TermsText>
                  Ingen sjuklön erbjuds i Total-upplägget om inte annat
                  avtalats. Arbetstagaren hänvisas till Försäkringskassan för
                  sjukpenning.
                </TermsText>

                <SmallTitle>1.3 Intermittent anställning</SmallTitle>
                <TermsText>
                  Varje pass är en separat anställning som börjar och slutar
                  enligt uppdragsavtalet. Ingen anställning föreligger mellan
                  passen.
                </TermsText>

                <SmallTitle>2. Flex</SmallTitle>
                <TermsText>
                  Simflexity hanterar endast löneutbetalning av redan intjänad
                  lön. Uppdragsgivaren är arbetsgivare och ansvarar för:
                  <ul>
                    <li>
                      Sjuklön, sociala avgifter, pensionsavsättningar och
                      arbetsmiljö.
                    </li>
                    <li>
                      Löner, enligt företagets lönesättning och eventuell
                      OB-ersättning för hela schemalagda pass. Företaget
                      ansvarar för dialog med arbetare gällande om det är
                      inklusive eller exklusive semesterersättning i den
                      nettolön som betalas ut.
                    </li>
                    <li>Eventuella försäkringar för arbetstagaren.</li>
                  </ul>
                </TermsText>
                <SmallTitle>Uppdragsgivarens ansvar</SmallTitle>

                <TermsText>
                  <ul>
                    <li>
                      Att godkänna arbetstagarens tidrapport innan lön kan
                      betalas ut. Alla godkända tidrapporter kommer att
                      faktureras uppdragsgivaren, eftersom denne har godkänt
                      tiderna.
                    </li>
                    <li>
                      Att informera Simflexity om eventuella fel i godkända
                      tidrapporter inom 48 timmar efter godkännande. Efter denna
                      period kan inga ändringar göras av uppdragsgivaren.
                    </li>
                    <li>
                      Att säkerställa korrekt rapportering av övertid och extra
                      arbetstimmar samt meddela Simflexity i förväg om
                      eventuella avvikelser.
                    </li>
                    <li>
                      Att följa GDPR och tillämpliga regler för hantering av
                      personuppgifter. Uppgifter relaterade till samarbetet kan
                      sparas av Simflexity enligt lagstadgade krav.
                    </li>
                    <li>
                      Att kontakta Simflexity för support vid löne- eller
                      schemafrågor. Simflexity åtar sig att hantera akuta frågor
                      inom 24 timmar på vardagar.
                    </li>
                  </ul>
                </TermsText>

                <SmallTitle>3. Solo</SmallTitle>
                <TermsText>
                  Simflexity erbjuder endast löneunderlag; allt
                  arbetsgivaransvar, inklusive försäkringar och sociala
                  avgifter, ligger hos uppdragsgivaren. Simflexity förser med
                  arbetade timmar, eventuell OB-ersättning och frånvarorapporter
                  för sina arbetare.
                </TermsText>

                <SmallTitle>4. Försäkringar</SmallTitle>
                <TermsText>
                  Simflexity erbjuder endast de försäkringar som anges i valt
                  upplägg. Uppdragsgivaren är ansvarig för att säkerställa att
                  nödvändiga försäkringar finns på plats i Flex- och
                  Solo-uppläggen.
                </TermsText>

                <SmallTitle>
                  5. Förhållandet mellan Företaget och Simflexity
                </SmallTitle>
                <TermsText>
                  Simflexity är endast arbetsgivare i Total-upplägget. I Flex
                  och Solo har uppdragsgivaren fullt arbetsgivaransvar och ska
                  följa tillämpliga lagar och regler för anställning och
                  arbetsmiljö.
                </TermsText>

                <SmallTitle>6. Avbokningspolicy för Total-upplägg</SmallTitle>
                <TermsText>
                  <ul>
                    <li>
                      Avbokning mer än 36 timmar innan starttid: Ingen
                      ersättning.
                    </li>
                    <li>
                      Avbokning mellan 36 och 12 timmar innan start: 50 % av
                      lönen för det schemalagda passet betalas och faktureras
                      uppdragsgivaren.
                    </li>
                    <li>
                      Avbokning senare än 12 timmar innan passet eller efter
                      passets start: Full lön utgår och faktureras
                      uppdragsgivaren.
                    </li>
                  </ul>
                </TermsText>

                <SmallTitle>7. Fakturering och betalningsvillkor</SmallTitle>
                <TermsText>
                  <ul>
                    <li>
                      Total: Beställande företag faktureras baserat på avgift
                      enligt abonnemang. Detta kan vara en procentandel av
                      totallönekostnaden (bruttolön + sociala avgifter) eller
                      ett fast pris per timme, beroende på abonnemanget.
                      Kontrollera ditt abonnemang för att se din inställning och
                      avgift.
                    </li>
                    <li>
                      Flex: Beställande företag faktureras baserat på avgift
                      enligt abonnemang. Detta kan vara en procentandel av
                      arbetarens nettolön eller ett fast pris per timme,
                      beroende på abonnemanget. Kontrollera ditt abonnemang för
                      att se din inställning och avgift.
                    </li>
                    <li>
                      Betalningsvillkor: Fakturor ska betalas inom 14 dagar
                      efter fakturadatum om inget annat överenskommits. Vid sen
                      betalning tillkommer dröjsmålsränta enligt räntelagen samt
                      en påminnelseavgift. Vid fortsatt utebliven betalning kan
                      inkassoåtgärder vidtas och tillgång till Simflexitys
                      tjänster kan begränsas.
                    </li>
                    <li>
                      Simflexity för support vid löne- eller schemafrågor.
                      Simflexity åtar sig att hantera akuta frågor inom 4 timmar
                      via e-post till support@simflexity.com på vardagar.
                    </li>
                  </ul>
                </TermsText>
              </TermsText>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
                marginBottom: 10,
              }}
            >
              <H4
                fontSize={FontSize.H4}
                style={{ margin: 0, marginBottom: 10 }}
              >
                Allmänna villkor för SimflexityPay
              </H4>
            </div>
            <div>
              <TermsText>
                <SmallTitle>
                  1. Service plan: SimflexityPay – Total, Flex and Solo
                </SmallTitle>
                <TermsText>
                  Simflexity offers three different service plans for payroll
                  processing:
                  <ul>
                    <li>
                      Total – Simflexity is an employer with certain
                      employer-related obligations but excludes sick pay unless
                      otherwise agreed.
                    </li>
                    <li>
                      Flex – Simflexity only acts as a platform for salary
                      payments (Pay-on-demand) and are not employers.
                    </li>
                    <li>
                      Solo – Simflexity only provides payroll and platform
                      support, while the client is fully responsible as an
                      employer.
                    </li>
                  </ul>
                </TermsText>

                <SmallTitle>1.1 Total</SmallTitle>
                <TermsText>
                  Simflexity is an employer and is responsible for:
                  <ul>
                    <li>
                      Payment of wages, preliminary tax and social security
                      contributions.
                    </li>
                    <li>Accident insurance (excluding sick pay).</li>
                    <li>
                      Net salary payment after approved time report, including
                      holiday pay and any OB supplements.
                    </li>
                    <li>
                      Compensation for entire scheduled shifts, even if the
                      employee is sent home earlier than planned.
                    </li>
                  </ul>
                </TermsText>
                <SmallTitle>Client's responsibility</SmallTitle>
                <TermsText>
                  <ul>
                    <li>
                      That work environment legislation and applicable laws are
                      complied with, and that necessary insurance is taken out
                      in accordance with applicable requirements.
                    </li>
                    <li>
                      To ensure a safe and secure working environment before the
                      employee begins work.
                    </li>
                    <li>
                      To notify Simflexity of any work environment risks or
                      safety deficiencies that may affect the employee's working
                      conditions.
                    </li>
                    <li>
                      To inform Simflexity in good time of any schedule changes.
                      If working hours are shortened without an approved change,
                      the employee is entitled to full compensation for the
                      scheduled shift.
                    </li>
                    <li>
                      In the event of any disputes or conflicts in the workplace
                      between the employee and the client, to primarily handle
                      this internally and, if necessary, escalate to Simflexity
                      for possible mediation.
                    </li>
                    <li>
                      To comply with applicable rules regarding discrimination,
                      harassment and a decent work environment and to report any
                      incidents to Simflexity.
                    </li>
                    <li>
                      To approve the employee's timesheet before salary can be
                      paid. All approved timesheets will be invoiced to the
                      client, as they have approved the times.
                    </li>
                    <li>
                      To inform Simflexity of any errors in approved timesheets
                      within 48 hours of approval. After this period, no changes
                      can be made by the client.
                    </li>
                    <li>
                      To ensure correct reporting of overtime and extra working
                      hours and to notify Simflexity in advance of any
                      deviations.
                    </li>
                    <li>
                      To be responsible for any mismanagement or inappropriate
                      behavior on the part of the employee. The client has the
                      right to terminate the collaboration with a specific
                      employee, but any financial obligations for already booked
                      passes remain.
                    </li>
                    <li>
                      In the event of breach of contract or lack of
                      responsibility towards the work environment and employment
                      conditions, you may be subject to contractual sanctions or
                      termination of the collaboration with Simflexity.
                    </li>
                    <li>
                      To pay invoices on time. In case of late payment, interest
                      will be charged according to the Interest Act and a
                      reminder fee. If payment is not made, debt collection
                      measures may be taken and access to Simflexity's services
                      may be restricted.
                    </li>
                    <li>
                      To terminate the collaboration with at least 30 days'
                      written notice. Passes booked during the notice period
                      must be completed according to the agreement.
                    </li>
                    <li>
                      To manage and prevent harassment or discrimination in the
                      workplace. In the event of reported work environment
                      problems, Simflexity may freeze cooperation until the
                      situation is investigated.
                    </li>
                    <li>
                      To comply with GDPR and applicable rules for the handling
                      of personal data. Data related to the collaboration may be
                      saved by Simflexity according to legal requirements.
                    </li>
                    <li>
                      To contact Simflexity for support with payroll or
                      scheduling issues. Simflexity is committed to handling
                      urgent issues within 24 hours on weekdays.
                    </li>
                  </ul>
                </TermsText>
                <SmallTitle>1.2 Sick pay</SmallTitle>
                <TermsText>
                  No sick pay is offered in the Total arrangement unless
                  otherwise agreed. The employee is referred to the Swedish
                  Social Insurance Agency for sick pay.
                </TermsText>

                <SmallTitle>1.3 Intermittent employment</SmallTitle>
                <TermsText>
                  Each shift is a separate employment that begins and ends
                  according to the assignment agreement. There is no employment
                  between shifts.
                </TermsText>

                <SmallTitle>2. Flex</SmallTitle>
                <TermsText>
                  Simflexity only handles salary payments for already earned
                  salary. The client is the employer and is responsible for:
                  <ul>
                    <li>
                      Sick pay, social security contributions, pension
                      provisions and work environment.
                    </li>
                    <li>
                      Salaries, according to the company's salary structure and
                      any OB compensation for full scheduled shifts. The company
                      is responsible for dialogue with workers regarding whether
                      holiday compensation is included or excluded in the net
                      salary paid.
                    </li>
                    <li>Any insurance for the employee.</li>
                  </ul>
                </TermsText>
                <SmallTitle>The client's responsibility</SmallTitle>

                <TermsText>
                  <ul>
                    <li>
                      To approve the employee's timesheet before salary can be
                      paid. All approved timesheets will be invoiced to the
                      client, as they have approved the times.
                    </li>
                    <li>
                      To inform Simflexity of any errors in approved timesheets
                      within 48 hours of approval. After this period, no changes
                      can be made by the client.
                    </li>
                    <li>
                      To ensure correct reporting of overtime and extra working
                      hours and to notify Simflexity in advance of any
                      deviations.
                    </li>
                    <li>
                      To comply with GDPR and applicable regulations for the
                      handling of personal data. Data related to the
                      collaboration may be saved by Simflexity according to
                      legal requirements.
                    </li>
                    <li>
                      To contact Simflexity for support with payroll or
                      scheduling issues. Simflexity is committed to handling
                      urgent issues within 24 hours on weekdays.
                    </li>
                  </ul>
                </TermsText>

                <SmallTitle>3. Solo</SmallTitle>
                <TermsText>
                  Simflexity only offers payroll; all employer responsibility,
                  including insurance and social security contributions, lies
                  with the client. Simflexity provides hours worked, any OB
                  compensation and absence reports for its workers.
                </TermsText>

                <SmallTitle>4. Insurances</SmallTitle>
                <TermsText>
                  Simflexity only offers the insurances specified in the chosen
                  plan. The client is responsible for ensuring that the
                  necessary insurances are in place in the Flex and Solo plans.
                </TermsText>

                <SmallTitle>
                  5. The relationship between the Company and Simflexity
                </SmallTitle>
                <TermsText>
                  Simflexity is only the employer in the Total arrangement. In
                  Flex and Solo, the client has full employer responsibility and
                  must comply with applicable laws and regulations for
                  employment and the work environment.
                </TermsText>

                <SmallTitle>6. Cancellation policy for Total-plan</SmallTitle>
                <TermsText>
                  <ul>
                    <li>
                      Cancellation more than 36 hours before start time: No
                      compensation.
                    </li>
                    <li>
                      Cancellation between 36 and 12 hours before start: 50% of
                      the salary for the scheduled session is paid and invoiced
                      to the client.
                    </li>
                    <li>
                      Cancellation later than 12 hours before the session or
                      after the session starts: Full salary will be paid and
                      invoiced to the client.
                    </li>
                  </ul>
                </TermsText>

                <SmallTitle>7. Invoicing and payment terms</SmallTitle>
                <TermsText>
                  <ul>
                    <li>
                      Total: Ordering companies are invoiced based on a
                      subscription fee. This can be a percentage of the total
                      salary cost (gross salary + social security contributions)
                      or a fixed price per hour, depending on the subscription.
                      Check your subscription to see your settings and fee.
                    </li>
                    <li>
                      Flex: Beställande företag faktureras baserat på avgift
                      enligt abonnemang. Detta kan vara en procentandel av
                      arbetarens nettolön eller ett fast pris per timme,
                      beroende på abonnemanget. Kontrollera ditt abonnemang för
                      att se din inställning och avgift.
                    </li>
                    <li>
                      Betalningsvillkor: Fakturor ska betalas inom 14 dagar
                      efter fakturadatum om inget annat överenskommits. Vid sen
                      betalning tillkommer dröjsmålsränta enligt räntelagen samt
                      en påminnelseavgift. Vid fortsatt utebliven betalning kan
                      inkassoåtgärder vidtas och tillgång till Simflexitys
                      tjänster kan begränsas.
                    </li>
                    <li>
                      Simflexity för support vid löne- eller schemafrågor.
                      Simflexity åtar sig att hantera akuta frågor inom 4 timmar
                      via e-post till support@simflexity.com på vardagar.
                    </li>
                  </ul>
                </TermsText>
              </TermsText>
            </div>
          </>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <ButtonStyled
            style={{ margin: 0 }}
            onClick={() => {
              props.setShowTerms(false);
            }}
            backgroundColor={Color.BurntSienna}
            color={Color.White}
          >
            {t("General.Close")}
          </ButtonStyled>
        </div>
      </ModalBody>
    </Modal>
  );
};

const ModalBody = styled.div`
  maxHeight: '100%',
  top: '50%',
  left: '0',
  transform: 'translate(0, -50%)',
  overflowY: 'auto'
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 270px;
  padding: 20px 20px 30px;
  border-radius: 8px;
  background-color: ${Color.White};
  @media screen and (max-width: 480px){
    width: 100%;
  }
  @media screen and (min-width: 481px){
    width: 50%;
    top: 10%;
    left: 25%;
    position: absolute;
  }
  p {
    margin-bottom: 30px;
  }
  li {
    list-style-type: decimal;
    margin-bottom: 8px;
  }
  
`;

const DashLi = styled.li`
  list-style-type: disc !important;
  margin-bottom: 8px;
`;

const TermsText = styled.span`
  font-family: ${FontFamily.MontserratRegular};
  line-height: 1.3rem;
  font-size 0.75rem;
  display: block;
  margin-bottom: 12px;
`;
const SmallTitle = styled.span`
  font-family: ${FontFamily.MontserratSemiBold};
  font-size 0.75rem;
  display: block;
  margin-bottom: 12px;
`;
