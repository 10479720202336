import { CustomIcon } from "components/icon/customIcon.component";
import { Text } from "components/Typography/text.styled";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { NewWorker, Unit, Worker, WorkerStatus } from "model/CompanyStructure";
import React, { useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { IconCircularContainer } from "../../../dashboard/dashboard.styled";
import { TextInput } from "../../../gig/gigs.styled";
import PhoneInput from "../phoneInput.component";
import {
  AddedWorkerRow,
  LinkWrapper,
  Row,
  WorkerTable,
  WorkgroupModalBody,
} from "./companyStructureEditUnit.styled";
import { formatPhoneNumber } from "../companyStructureUtils";
import { DividerLine } from "./companyStructureEditUnitChooseExisting.styled";
import { WorkGroupAddWorkerModal } from "web-apps/company/components/workgroup/workgroupAddWorkerModal.component";
import { useWorkgroupStore } from "web-apps/company/stores/workgroupStore/workgroupStore";
import Modal from "@material-ui/core/Modal/Modal";

interface WorkerTableComponentProps {
  unit: Unit | null;
  newWorker: NewWorker;
  newWorkerIsValid: boolean;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  addNewWorker: () => void;
  deleteWorker: (workerId: number) => void;
  t: (key: string) => string;
}

const WorkerTableComponent: React.FC<WorkerTableComponentProps> = ({
  unit,
  newWorker,
  newWorkerIsValid,
  handleInputChange,
  addNewWorker,
  deleteWorker,
  t,
}) => {
  return (
    <WorkerTable>
      <tbody>
        {unit?.workers && unit?.workers?.length > 0 ? (
          unit?.workers.map((worker) => (
            <WorkerRow
              key={worker.id}
              worker={worker}
              deleteWorker={deleteWorker}
            />
          ))
        ) : (
          <Text
            style={{
              fontFamily: "Montserrat-Italic",
              width: 150,
              borderBottom: Color.SeaBlue200,
            }}
            color={Color.MidnightBlue}
            fontSize={FontSize.Small}
          >
            {t("CompanyStructure.NoWorkersAdded")}
          </Text>
        )}

        <Text
          fontFamily={FontFamily.MontserratSemiBold}
          color={Color.MidnightBlue}
          fontSize={FontSize.Small}
        >
          {t("CompanyStructure.AddNew")}
        </Text>

        <NewWorkerRow
          newWorker={newWorker}
          newWorkerIsValid={newWorkerIsValid}
          handleInputChange={handleInputChange}
          addNewWorker={addNewWorker}
          unit={unit}
          t={t}
        />
      </tbody>
    </WorkerTable>
  );
};

interface WorkerRowProps {
  worker: Worker;
  deleteWorker: (workerId: number) => void;
}

export const getStatusIcon = (status: WorkerStatus) => {
  switch (status) {
    case WorkerStatus.Pending: {
      return "bell";
    }
    case WorkerStatus.Created: {
      return "clock";
    }
    case WorkerStatus.Accepted: {
      return "checkmark";
    }
  }
};

export const getStatusIconColor = (status: WorkerStatus) => {
  switch (status) {
    case WorkerStatus.Pending: {
      return Color.DarkGrey;
    }
    case WorkerStatus.Created: {
      return Color.Black;
    }
    case WorkerStatus.Accepted: {
      return Color.BurntSienna;
    }
  }
};

export const getStatusHoverText = (status: WorkerStatus, t: TFunction) => {
  switch (status) {
    case WorkerStatus.Pending: {
      return t("CompanyStructure.WorkerHasNotAcceptedTheInvite");
    }
    case WorkerStatus.Created: {
      return t("CompanyStructure.WorkerHadNotYetLoggedIn");
    }
    case WorkerStatus.Accepted: {
      return t("CompanyStructure.WorkerAccepted");
    }
  }
};

const WorkerRow: React.FC<WorkerRowProps> = ({ worker, deleteWorker }) => {
  const { t } = useTranslation();
  const [showAddWorkerModal, setShowAddWorkerModal] = useState(false);
  const [showWorkgroups, setShowWorkgroups] = useState(false);
  const [state] = useWorkgroupStore();
  const numberOfWorkgroups = worker.workGroups?.length;

  return (
    <>
      <AddedWorkerRow>
        <td>
          <Text fontFamily={FontFamily.MontserratRegular}>
            {worker.firstName} {worker.lastName}
          </Text>
        </td>
        <td>
          <Row>
            <DividerLine />
            <Text fontFamily={FontFamily.MontserratRegular}>
              {formatPhoneNumber(worker.phoneNumber)}
            </Text>
          </Row>
        </td>
        <td>
          <Text fontFamily={FontFamily.MontserratRegular}>{worker.email}</Text>
        </td>
        <td>
          <Text fontFamily={FontFamily.MontserratRegular}>
            {worker.status !== WorkerStatus.Pending
              ? worker.personalIdentityNumber
              : ""}
          </Text>
        </td>
        <td>
          <Text
            style={{
              padding: 2,
              borderRadius: 5,
              paddingLeft: 7,
              cursor:
                numberOfWorkgroups !== undefined && numberOfWorkgroups
                  ? "pointer"
                  : "auto",
            }}
            onClick={() => {
              if (numberOfWorkgroups !== null && numberOfWorkgroups !== 0) {
                setShowWorkgroups(true);
              } else {
                return null;
              }
            }}
          >
            {t("WorkGroup.InXWorkgroups", {
              number:
                numberOfWorkgroups !== undefined ? numberOfWorkgroups : "0",
            })}
          </Text>
        </td>
        <td>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
            }}
          >
            <CustomIcon
              style={{ zIndex: 1 }}
              name={getStatusIcon(worker.status)}
              size="24px"
              padding="2px"
              color={getStatusIconColor(worker.status)}
              title={getStatusHoverText(worker.status, t)}
            />
          </div>
        </td>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
            marginTop: "10px",
          }}
        >
          <td>
            <IconCircularContainer onClick={() => deleteWorker(worker.id)}>
              <CustomIcon
                style={{ zIndex: 1 }}
                name="cross"
                size="24px"
                padding="2px"
                color={Color.White}
              />
            </IconCircularContainer>
          </td>
          <td>
            <IconCircularContainer
              isAccept
              onClick={() => setShowAddWorkerModal(true)}
            >
              <CustomIcon
                style={{ zIndex: 1 }}
                name="group"
                size="24px"
                padding="2px"
                color={Color.White}
                backgroundColor={Color.LighterPositive}
                title={t("WorkGroup.AddWorkGroup")}
              />
            </IconCircularContainer>
          </td>
        </div>
      </AddedWorkerRow>
      {showAddWorkerModal && (
        <WorkGroupAddWorkerModal
          showAddWorkerModal={showAddWorkerModal}
          setShowAddWorkerModal={setShowAddWorkerModal}
          workGroups={state.workgroups}
          fromCompanyStructure
          worker={worker}
        />
      )}
      {showWorkgroups && (
        <Modal
          open={showWorkgroups}
          onClose={() => {
            setShowWorkgroups(false);
          }}
          disableEnforceFocus
          disableAutoFocus
        >
          <WorkgroupModalBody>
            <LinkWrapper>
              <CustomIcon
                color={Color.LighterDestructive}
                onClick={() => {
                  setShowWorkgroups(false);
                }}
                name={"cross"}
              >
                {t("General.Close")}
              </CustomIcon>
            </LinkWrapper>
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              color={Color.PitchBlack}
              fontSize={FontSize.H4}
            >
              {t("WorkGroup.Workgroups")}
            </Text>
            {worker.workGroups &&
              worker.workGroups.map((item, index) => (
                <Text key={index + item.name}>{item.name}</Text>
              ))}
          </WorkgroupModalBody>
        </Modal>
      )}
    </>
  );
};

interface NewWorkerRowProps {
  newWorker: NewWorker;
  newWorkerIsValid: boolean;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  addNewWorker: () => void;
  unit: Unit | null;
  t: (key: string) => string;
}

const NewWorkerRow: React.FC<NewWorkerRowProps> = ({
  newWorker,
  newWorkerIsValid,
  handleInputChange,
  addNewWorker,
  unit,
  t,
}) => (
  <tr>
    <td>
      <TextInput
        style={{ marginBottom: 16, minWidth: 120 }}
        name="firstName"
        disabled={!unit}
        fontSize={FontSize.Large}
        value={newWorker.firstName}
        onChange={handleInputChange}
        placeholder={`${t("CompanyStructure.FirstName")}`}
      />
    </td>
    <td>
      <TextInput
        style={{ marginBottom: 16, minWidth: 120 }}
        name="lastName"
        disabled={!unit}
        fontSize={FontSize.Large}
        value={newWorker.lastName}
        onChange={handleInputChange}
        placeholder={`${t("CompanyStructure.LastName")}`}
      />
    </td>
    <td>
      <PhoneInput
        name="phoneNumber"
        value={newWorker.phoneNumber}
        disabled={!unit}
        handleInputChange={handleInputChange}
      />
    </td>
    <td>
      <TextInput
        style={{ marginBottom: 16, minWidth: 150 }}
        name="email"
        disabled={!unit}
        fontSize={FontSize.Large}
        value={newWorker.email}
        onChange={handleInputChange}
        placeholder={`${t("CompanyStructure.Email")}`}
      />
    </td>
    <td colSpan={2}>
      <TextInput
        style={{ marginBottom: 16, minWidth: 150 }}
        name="personalIdentityNumber"
        disabled={!unit}
        fontSize={FontSize.Large}
        value={newWorker.personalIdentityNumber}
        onChange={handleInputChange}
        placeholder={`${t("CompanyStructure.PersonalIdentityNumber")}`}
      />
    </td>
    <td>
      <IconCircularContainer
        style={{ marginBottom: 16 }}
        isAccept={unit !== null && newWorkerIsValid}
        isDisabled={!unit || !newWorkerIsValid}
        backgroundColor={Color.SeaBlue300}
        onClick={addNewWorker}
      >
        <CustomIcon
          disabled={!unit || !newWorkerIsValid}
          style={{ zIndex: 1 }}
          name="plus_old"
          size="24px"
          padding="2px"
          color={Color.White}
        />
      </IconCircularContainer>
    </td>
  </tr>
);

export default WorkerTableComponent;
