import {
  TipStatus,
  WorkGroup,
  TipDetails,
  SaveTipPeriodRequest,
  WorkerStatus
} from "model/Tip";

export type TipState = {
  tips: TipDetails[];
  tipDetails: { [key: number]: TipDetails };
  workGroups: Record<number, WorkGroup[]>;
  calculationResult: {
    companyTips: TipDetails[];
    workGroups: WorkGroup[];
  };
  isLoading: boolean;
  error: string | null;
};

export const tipInitState: TipState = {
  tips: [],
  tipDetails: {},
  workGroups: [],
  calculationResult: {
    companyTips: [],
    workGroups: [],
  },
  isLoading: false,
  error: null,
};

type TipAction =
  | { type: "FETCH_TIPS_START" }
  | { type: "FETCH_TIPS_SUCCESS"; payload: TipDetails[] }
  | { type: "FETCH_TIPS_ERROR"; payload: string }
  | { type: "ADD_TIP"; payload: TipDetails }
  | { type: "FETCH_TIP_DETAILS_START" }
  | { type: "FETCH_TIP_DETAILS_SUCCESS"; payload: TipDetails }
  | {
      type: "CALCULATE_TIPS_SUCCESS";
      payload: { companyTips: TipDetails[]; workGroups: WorkGroup[] };
    }
  | {
      type: "SAVE_TIP_DISTRIBUTION_SUCCESS";
      payload: { companyTips: SaveTipPeriodRequest[] };
    }
  | { type: "UPDATE_WORKGROUPS"; payload: WorkGroup[] }
  | { type: "DELETE_TIP"; payload: number }
  | { type: "MANUAL_PAYOUT_SUCCESS"; payload: number[] }
  | { type: "REVERT_MANUAL_PAYOUT"; payload: number[] }
  | { type: "SET_IS_LOADING"; payload: boolean }
  | { type: "SET_REQUEST_ERROR"; payload: { message: string; err: any } };

export type TipActions = TipAction;

export const tipStateReducer = (
  state: TipState,
  action: TipAction
): TipState => {
  switch (action.type) {
    case "FETCH_TIPS_START":
      return { ...state, isLoading: true, error: null };

    case "FETCH_TIPS_SUCCESS":
      return { ...state, isLoading: false, tips: action.payload };

    case "FETCH_TIPS_ERROR":
      return { ...state, isLoading: false, error: action.payload };

    case "ADD_TIP":
      return {
        ...state,
        tips: [...state.tips, action.payload],
      };

    case "FETCH_TIP_DETAILS_START":
      return {
        ...state,
        isLoading: true,
      };

    case "FETCH_TIP_DETAILS_SUCCESS": {
      const updatedTip = action.payload;
      return {
        ...state,
        tips: state.tips.map((tip) =>
          tip.companyTipId === updatedTip.companyTipId ? updatedTip : tip
        ),
      };
    }

    case "CALCULATE_TIPS_SUCCESS":
      return {
        ...state,
        calculationResult: {
          companyTips: action.payload.companyTips,
          workGroups: action.payload.workGroups,
        },
      };

    case "SAVE_TIP_DISTRIBUTION_SUCCESS":
      const newState = state.tips.map((row) => {
        const updatedTip = action.payload.companyTips.find(
          (uRow) => uRow.companyTipId === row.companyTipId
        );
        if (!updatedTip) return row;
        return updatedTip.companyTipId === row.companyTipId
          ? {
              ...row,
              ...updatedTip,
              remainingAmount: updatedTip.remainingAmount,
              workers: updatedTip.workers,
            }
          : row;
      });

      return {
        ...state,
        tips: newState,
      };

    case "DELETE_TIP":
      return {
        ...state,
        tips: state.tips.filter((tip) => tip.companyTipId !== action.payload),
      };

      case "MANUAL_PAYOUT_SUCCESS":
        const updatedTips = state.tips.map((tip) =>
          action.payload.includes(tip.companyTipId)
            ? {
                ...tip,
                status: TipStatus.ClosedManually,
                workers: tip.workers.map((worker) => ({
                  ...worker,
                  status: WorkerStatus.Paid,
                })),
              }
            : tip
        );
      
        return {
          ...state,
          tips: updatedTips,
        };
      
      

    case "REVERT_MANUAL_PAYOUT":
      return {
        ...state,
        tips: state.tips.map((tip) =>
          action.payload.includes(tip.companyTipId)
            ? { ...tip, status: TipStatus.Done }
            : tip
        ),
      };

    case "SET_IS_LOADING":
      return { ...state, isLoading: action.payload };

    case "SET_REQUEST_ERROR":
      return {
        ...state,
        isLoading: false,
        error: action.payload.message,
      };

    default:
      return state;
  }
};
