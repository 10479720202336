import React, { useState } from 'react';
import { BreakInfoRow, BreakInputContainer, FlexRow, InfoCircle, InfoContainer } from './timeReportModal.styled';
import { FontSize, FontFamily } from "config/font";
import { Color } from 'config/colors';
import { ErrorMessage, TimeInput } from '../saveGig/shift/shiftForm.styled';
import { Text } from "components/Typography/text.styled";
import { useTranslation } from 'react-i18next';

interface BreakRowProps {
  breakStartTimeStr: string | null;
  breakEndTimeStr: string | null;
  setBreakStartTimeStr: (value: string) => void;
  setBreakEndTimeStr: (value: string) => void;
  breakMinutes: number;
  breakValidInsideShift: boolean;
  setIsEdited: (value: boolean) => void;
}

export const BreakRow: React.FC<BreakRowProps> = ({ breakStartTimeStr, setBreakStartTimeStr, breakEndTimeStr, setBreakEndTimeStr, breakMinutes, breakValidInsideShift, setIsEdited }) => {
  const { t } = useTranslation()
  const [showBreakInfo, setShowBreakInfo] = useState(false);

  return (
    <FlexRow>
      <BreakInfoRow>
        <Text
          fontFamily={FontFamily.MontserratSemiBold}
          fontSize={FontSize.H4}
        >
          {t("CompanyTimeReport.Break")}
        </Text>
        <InfoCircle
          onMouseEnter={() => setShowBreakInfo(true)}
          onMouseLeave={() => setShowBreakInfo(false)}
        >
          <Text
            style={{marginBottom: 10}}
            fontFamily={FontFamily.LeagueSpartanBold}
            color={Color.White}
            padding
            fontSize={FontSize.Small}
          >
            i
          </Text>
        </InfoCircle>
      </BreakInfoRow>

      {showBreakInfo && (
        <InfoContainer>
          <Text
            fontSize={FontSize.Small}
            color={Color.SeaBlue600}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.BreakHelpText")}
          </Text>
        </InfoContainer>
      )}

      <BreakInputContainer>
        <TimeInput
          fontFamily={FontFamily.MontserratBold}
          fontSize={FontSize.Large}
          name="breakStartTime"
          type="time"
          required
          value={breakStartTimeStr ? breakStartTimeStr : ""}
          onChange={(e) => {
            setBreakStartTimeStr(e.currentTarget.value);
            setIsEdited(true)
          }}
        />
        <Text style={{ marginLeft: 10 }}
          fontFamily={FontFamily.MontserratRegular}
          fontSize={FontSize.H4}
        >
          -
        </Text>
        {!breakValidInsideShift && (
          <ErrorMessage style={{ marginTop: 18 }}>
            {t("GigsCompany.EnterAValidTimeInsideTheShift")}
          </ErrorMessage>
        )}
        <TimeInput style={{ marginLeft: 10 }}
          fontFamily={FontFamily.MontserratBold}
          fontSize={FontSize.Large}
          name="breakEndTime"
          type="time"
          required
          value={breakEndTimeStr ? breakEndTimeStr : "-"}
          onChange={(e) => {
            setBreakEndTimeStr(e.currentTarget.value);
            setIsEdited(true)
          }}
        />
        <Text style={{ marginLeft: 10 }}
          fontFamily={FontFamily.MontserratRegular}
          fontSize={FontSize.H4}
        >
          ({breakMinutes} min)
        </Text>
      </BreakInputContainer>
    </FlexRow>
  );
};
