import React from "react";
import { TipFilter } from "model/Tip";
import { Checkbox } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Text } from "components/Typography/text.styled";
import { FilterContainer, CheckBoxContainer } from "../../../gig/gigs.styled";

type TipFiltersProps = {
  selectedFilter: TipFilter;
  setSelectedFilter: (filter: TipFilter) => void;
};

export const TipFilters: React.FC<TipFiltersProps> = (props) => {
  const { t } = useTranslation();

  return (
    <FilterContainer style={{ margin: 10 }}>
      {Object.values(TipFilter).map((filter) => (
        <CheckBoxContainer
          key={filter}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Checkbox
            checked={props.selectedFilter === filter}
            onChange={() => props.setSelectedFilter(filter)}
          />
          <Text>{t(`CompanyTip.${filter}`)}</Text>
        </CheckBoxContainer>
      ))}
    </FilterContainer>
  );
};
