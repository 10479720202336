import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FontFamily, FontSize } from "config/font";
import { TabStyled } from "../tab/tab.styled";
import { Text } from "components/Typography/text.styled";
import { MonthlySalary } from "./monthlySalary.component";
import { StyledTabGroup } from "./salary.styled";
import { IndividualSalary } from "./individualSalary.component"

export const SalaryTabs: React.FC = () => {
  const [currentTab, setCurrentTab] = useState<"Month" | "Individual">("Month");
  const { t } = useTranslation();

  return (
    <>
      <StyledTabGroup>
        <TabStyled
          active={currentTab === "Month"}
          onClick={() => setCurrentTab("Month")}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
          >
            {t("Salary.MonthlyOverview")}
          </Text>
        </TabStyled>
        <TabStyled
          active={currentTab === "Individual"}
          onClick={() => setCurrentTab("Individual")}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
          >
            {t("Salary.IndividualSalary")}
          </Text>
        </TabStyled>
      </StyledTabGroup>

      {currentTab === "Month" && <MonthlySalary />}
      {currentTab === "Individual" && <IndividualSalary />}
    </>
  );
};
