import { Color } from "config/colors";
import styled from "styled-components";

export const EditUnitModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1040px;
  max-height: 90%;
  overflow-y: auto;
  overflow-x: auto;
  border-radius: 10px;
  background-color: ${Color.White};
  outline: none;
  padding: 20px;
  padding-bottom: 40px;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  th {
    background-color: #f2f2f2;
    text-align: left;
  }

  .no-wrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .input-container input {
    width: 100%;
    box-sizing: border-box;
  }
`;

export const UnitContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 200px;
  margin-left: 4px;
`;

export const Text = styled.p<{
  align?: string;
  fontSize?: string;
  fontFamily?: string;
}>`
  text-align: ${(props) => props.align || "left"};
  font-size: ${(props) => props.fontSize || "16px"};
  font-family: ${(props) => props.fontFamily || "Arial, sans-serif"};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 40px;
  margin-top: 0px;
`;

export const ChooseContainer = styled.div`
  display: flex;
  color: ${Color.Destructive};
  backgroundcolor: ${Color.Destructive};

  justify-content: flex-start;
  align-items: flex-start;
  width: 100px; /* Full view width */
  height: 40px;
  margin-left: -4;
  padding: 0; /* Ensure no padding */
  position: relative; /* Create a positioning context */
`;

export const ChildItem = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  padding: 0; /* Ensure no padding */
`;

export const RemoveButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`;

export const WorkerTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
  overflow-x: auto;

  td {
    padding: 4px;
    border: none;
  }
`;

export const AddedWorkerRow = styled.tr`
  width: 100%;

  padding: 4px;
  border: none;
  border-bottom: 1px solid ${Color.SeaBlue200};
`;
export const WorkgroupModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 270px;
  width: 50%;
  max-width: 370px;
  padding: 20px 20px 30px;
  border-radius: 8px;
  background-color: ${Color.White};
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000

  p {
    margin-bottom: 30px;
  }
`;

export const Row = styled.div`
  align-self: start;
  display: flex;
  align-items: center;
`;

export const LinkTextContainer = styled.div`
  align-self: start;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  p {
    margin: 0;
  }
  div {
    margin: 0;
    padding: 0 0 0 5px;
  }
`;

export const DisabledLinkTextContainer = styled.div`
  align-self: start;
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }
  div {
    margin: 0;
    padding: 0 0 0 5px;
  }
`;

export const Spacer = styled.div`
  height: 20px;
`;

export const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const LoadingContainer = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const EditUnitButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  div {
    margin: 0 0 10px 0;
  }
`;