import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import styled, { css } from "styled-components";

type TabProps = {
  active: boolean;
};

type CellProps = {
  backgroundColor?: boolean;
};

type ButtonProps = {
  fullWidth?: boolean;
  disabled?: boolean;
  backgroundColor?: Color;
  color?: Color;
  icon?: boolean;
  fontSize?: FontSize;
  lessPadding?: boolean;
  isCancel?: boolean;
  notCenter?: boolean;
  isAction?: boolean;
};

export const TipTabStyled = styled.div<TabProps>`
  flex-basis: auto;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  border-bottom: ${(props) =>
    props.active
      ? `1px solid ${Color.ModernGreen}`
      : `1px solid ${Color.SeaBlue200}`};

  &:hover {
    background-color: ${Color.SeaBlue200};
    cursor: pointer;
  }
`;

export const TipTabsStyled = styled.div`
  width: 100%;
  display: flex;
`;

export const WorkerContainer = styled.div<CellProps>`
  display: flex;
  align-items: center;

  justify-content: center;
  background-color: ${(props) =>
    props.backgroundColor ? Color.Destructive : "undefined"};
`;

export const TipDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid ${Color.SeaBlue200};
`;

export const AddTipContainer = styled.div`
  display: flex;
  alignitems: center;
  margin-bottom: 50px;
  margin-top: 20px;
`;

export const DropdownContainer = styled.div<{ isOpen: boolean }>`
  width: 100%;
  overflow: hidden;
  transition: max-height 0.6s ease-in-out, opacity 0.3s ease;
  max-height: ${({ isOpen }) => (isOpen ? "3000px" : "0px")};
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  position: relative;
`;

export const DropDown = styled.div`
  width: 100%;
  margin-top: 10px;
`;

export const DetailsContainer = styled.div`
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  width: full;
  padding: 0;
  margin: 0;
`;

export const TipGridHeader = styled.div<{ hoverable?: boolean }>`
  display: grid;
  grid-template-columns:
    0.8fr
    1fr
    0.8fr
    1fr 1fr;
  align-items: center;
  font-weight: bold;
  padding-bottom: 12px;
  padding-top: 20px;
  border-bottom: 1px solid ${Color.SeaBlue200};
  padding; 10px 0;
`;

export const TipCellContainer = styled.div<{
  isExpanded: boolean;
  isSelected: boolean;
}>`
  display: grid;
  grid-template-columns:
    0.8fr 4px
    1fr 4px
    0.8fr 4px
    1fr 1fr;
  align-items: center;
  border-bottom: 1px solid ${Color.SeaBlue200};

  background-color: ${(props) =>
    props.isSelected
      ? Color.SeaBlue300
      : props.isExpanded
      ? Color.SeaBlue300
      : "transparent"};

  &:hover {
    background-color: ${Color.SeaBlue300};
  }
`;

export const WeekContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const TipColumnDivider = styled.div`
  width: 1px;
  height: 50%;
  background-color: ${Color.SeaBlue300};
  margin: 0 auto;
`;

export const WorkGroupGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 100%;
  max-width: 100%;
  margin-top: 20px;
  margin-bottom: 40px;
`;

export const TipButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  margin-top: 40px;
`;

export const TipButtonStyled = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: none;
  padding: 10px 20px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : FontSize.Large)};
  font-family: ${FontFamily.MontserratRegular}, Helvetica;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s;
  color: ${(props) =>
    props.disabled
      ? Color.SeaBlue500
      : props.color
      ? props.color
      : Color.White};
  background: ${(props) =>
    props.disabled
      ? Color.Disabled
      : props.backgroundColor
      ? props.backgroundColor
      : Color.BurntSienna};
  outline: none;
  transition: opacity 0.15s;
  cursor: pointer;
  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        background-color: ${props.isCancel
          ? Color.LightWarning
          : props.isAction
          ? Color.ActionColor
          : Color.Positive};
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
        transform: translateY(-2px);
      }

      &:active {
        transform: translateY(0);
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.5);
      }
    `}
`;

export const TipInput = styled.input<{ fontSize?: FontSize; width?: string }>`
  border: 1px solid ${Color.SeaBlue400};
  padding: 10px;
  margin-top: 3px;
  margin-bottom: 3px;
  width: ${(props) => props.width};
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : FontSize.Standard};
  color: ${(props) => (props.disabled ? Color.SeaBlue600 : Color.MidnightBlue)};
  font-family: ${FontFamily.MontserratRegular}, Helvetica;
  border-radius: 4px;
  ::placeholder {
    color: ${Color.SeaBlue400};
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /*-webkit-text-fill-color: ${(props) =>
    props.disabled
      ? Color.SeaBlue600
      : Color.MidnightBlue}; /* Override safari disabled font color change */
  */ &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
`;

export const GridCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Color.SeaBlue200};
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  min-height: 90px;
  aspect-ratio: 1.6 / 1.1;
  text-align: center;
`;

export const SelectRow = styled.div`
  flex-basis: 50%;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 10px;
`;

export const LabelWithIcon = styled.div`
  position: absolute;
  display: inline-block;
`;

export const ActionsCell = styled.div<{ isMultiSelectActive: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-left: ${(props) => (props.isMultiSelectActive ? "10px" : "50px")};
  // margin-right: ${(props) => (props.isMultiSelectActive ? "10px" : "0")};
  margin-right: 10px;
  margin-left: 10px;
`;

export const TipModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 850px;
  background-color: ${Color.White};
  padding: 20px;
  display: flex;
  flex-direction: column;
  outline: none;
  border-radius: 8px;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.2);
  button {
    font-size: ${FontSize.Small};
  }
  height: auto;
  max-height: 80%;
  overflow-y: scroll;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const AnimatedText = styled.h4<{
  isFading: boolean;
  fontFamily?: string;
}>`
  font-family: ${({ fontFamily }) => fontFamily || "inherit"};
  margin: 0;
  transition: all 2s ease;
  opacity: 1;
`;

export const SpaceBtwItemsCenter = styled.div`
  display: flex;
  align-items: center;
  justify-items; center;
  justify-content: space-between;
`;

export const TipHeadCell = styled.div`
  padding-bottom: 12px;
  padding-top: 12px;
  text-align: center;
  p {
    margin: 0;
    color: ${Color.MidnightBlue};
  }
`;

export const TipHeadCell2 = styled.div`
  padding-bottom: 12px;
  padding-top: 12px;
  display: flex;
  justify-content: space-between;
  p {
    margin: 0;
    color: ${Color.MidnightBlue};
  }
`;

export const TipHeadCell5 = styled.div`
  padding-bottom: 4px;
  padding-top: 4px;
  padding-left: 6px;
  padding-right: 6px;
  display: flex;
  justify-content: space-between;
  background-color: ${Color.MidnightBlue};
  p {
    margin: 0;
    color: ${Color.White};
  }
`;

export const DetailsHeadCell = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  padding-bottom: 12px;
  border-bottom: 1px solid ${Color.SeaBlue200};
  text-align: left;
  p {
    margin: 0;
    color: ${Color.MidnightBlue};
  }
`;

export const WorkerCell = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 100%;
`;

export const TipCell = styled.div`
  display: flex;
  justify-content: center;
  margin: 0px;
  width: 100%;
`;

export const TipInputCell = styled.div`
  padding-bottom: 12px;
  padding-top: 12px;
  text-align: center;
  margin: 0;
  p {
    margin: 0;
    color: ${Color.MidnightBlue};
  }
`;

export const InfoContainer = styled.div`
  margin-top: 10px;
  font-family: ${FontFamily.MontserratBold}, Helvetica;
  background-color: ${Color.SeaBlue200};
  border-radius: 8px;
  padding: 10px;
  display: inline-block;
  max-width: 600px;
`;

export const InfoCircle = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${Color.SeaBlue600};
  border-radius: 40px;
  width: 17px;
  height: 17px;
  align-items: center;
  margin: 0 5px;
`;
