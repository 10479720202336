import { getWorkerImg } from "services/firebase/firebase.service";
import { CompanyGigUserDto, GigCosts, GigType, PaymentMethod } from "model/Gig";
import { GigTemplateDto, GigTemplateShift } from "model/GigTemplate";
import { ShiftDto, ShiftRequestDto } from "model/Shift";
import { TimeReportDto, TimeReportResolution } from "model/TimeReport";
import { Api } from "services/api/api.service";
import moment from "moment";

export const getWorkerImages = async (
  workerIds: Array<CompanyGigUserDto["firebaseId"]>
): Promise<string[]> => {
  let arr = [];

  for (const id of workerIds) {
    const url = await getWorkerImg(id!);

    if (url) {
      arr.push(url);
    } else {
      arr.push('no-image')
    }
  }

  return arr;
};

export const validateEmail = (email: string) => {
  const re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  if (email && !re.test(email)) {
    return false;
  } else {
    return true;
  }
};

export const formatWorkerDateOfBirth = (birthString: string) => {
  const year = birthString.slice(0, 4);
  const month = birthString.slice(4, 6);
  const day = birthString.slice(6, 8);
  const workerDate = `${year}-${month}-${day}`;
  return workerDate;
};

/**
  Clamp a given value between a min and max number
 */
export const clamp = (value: number, min: number, max: number) =>
  Math.min(Math.max(value, min), max);

export const dateDiff = (a: Date, b: Date, divider = 1) =>
  Math.abs(b.getTime() - a.getTime()) / divider;

/**
 * Helper function to alter template shfits to start from today's date when called
 * and adjusts all subsequent shifts from that date.
 */
export const adjustShiftsFromToday = (gigTemplate: GigTemplateDto) => {
  const { shifts } = gigTemplate;

  // Sort our shift dates, then traverse each date and adjust its shifts to start from today
  // and expand based on duration between shifts.
  const sorted = [...shifts].sort(
    (e1, e2) =>
      new Date(e1.startTime).getTime() - new Date(e2.startTime).getTime()
  );
  const newShifts: GigTemplateShift[] = [];
  let initialDate: Date;
  let firstStartDate: Date;
  sorted.forEach((shift) => {
    let startDate = new Date(shift.startTime);
    let endDate = new Date(shift.endTime);
    const shiftDuration = dateDiff(startDate, endDate);

    if (!initialDate) {
      // Need to keep track of initial, unmodified, first shift. So we can get duration between following shifts.
      initialDate = new Date(startDate);

      // Also keep track of first modified start date, meaning first shift starting from today.
      // All following shifts will append upon this.
      const now = new Date();
      startDate.setMonth(now.getMonth());
      startDate.setDate(now.getDate());
      startDate.setFullYear(now.getFullYear());
      if (startDate.getTime() < now.getTime()) {
        startDate.setDate(startDate.getDate() + 1);
      }
      firstStartDate = new Date(startDate);
    } else {
      startDate = new Date(
        firstStartDate.getTime() + dateDiff(initialDate, startDate)
      );
    }

    endDate = new Date(startDate.getTime() + shiftDuration);
    newShifts.push({
      id: shift.id,
      breakEndTime: shift.breakEndTime,
      breakStartTime: shift.breakStartTime,
      startTime: startDate.toISOString(),
      endTime: endDate.toISOString(),
    });
  });

  return newShifts;
};

/**
 * Similar to above function but adjusted from a date instead of today's date and will retain
 * the weekday of each shift adjusted from the start date so thei fit into the week as such.
 */
export const adjustShiftsToWeek = (
  gigTemplate: GigTemplateDto,
  adjustFrom: Date
) => {
  const { shifts } = gigTemplate;

  const sorted = [...shifts].sort(
    (e1, e2) =>
      new Date(e1.startTime).getTime() - new Date(e2.startTime).getTime()
  );
  const newShifts: GigTemplateShift[] = [];
  let initialDate: Date;
  let firstStartDate: Date;

  sorted.forEach((shift) => {
    let startDate = new Date(shift.startTime);
    let endDate = new Date(shift.endTime);
    const shiftDuration = dateDiff(startDate, endDate);

    if (!initialDate) {
      initialDate = new Date(startDate);

      const day = initialDate.getDay();
      const startDay = adjustFrom.getDay();
      const diff = day - startDay;

      let newDay = adjustFrom.getDate() + diff;
      let newMonth = adjustFrom.getMonth();
      let newYear = adjustFrom.getFullYear();
      const daysInMonth = new Date(
        adjustFrom.getFullYear(),
        adjustFrom.getMonth() + 1,
        0
      ).getDate();

      // Passed Month
      if (newDay > daysInMonth) {
        newDay = Math.abs(newDay - daysInMonth);
        newMonth++;

        if (newMonth > 11) {
          newYear++;
          newMonth = 0;
        }
      }
      startDate.setDate(newDay);
      startDate.setMonth(newMonth);
      startDate.setFullYear(newYear);
      if (startDate < adjustFrom) {
        startDate.setDate(startDate.getDate() + 7);
      }
      firstStartDate = new Date(startDate);
    } else {
      startDate = new Date(
        firstStartDate.getTime() + dateDiff(initialDate, startDate)
      );
    }

    endDate = new Date(startDate.getTime() + shiftDuration);
    newShifts.push({
      id: shift.id,
      breakEndTime: shift.breakEndTime,
      breakStartTime: shift.breakStartTime,
      startTime: startDate.toISOString(),
      endTime: endDate.toISOString(),
    });
  });

  return newShifts;
};

export const removeShiftIfDeleteEntry = (shifts: ShiftRequestDto[]) => {
  return shifts.filter((shift) => !shift.deleteEntry);
};

export const shiftWithAbsence = (timeReport: any) => {
  return (
    timeReport.resolution == TimeReportResolution.SICKLEAVE ||
    timeReport.resolution == TimeReportResolution.SICKLEAVECHILD ||
    timeReport.resolution == TimeReportResolution.VACATION ||
    timeReport.resolution == TimeReportResolution.PARENTALLEAVE ||
    timeReport.resolution == TimeReportResolution.LEAVEOFABSENCE ||
    timeReport.resolution == TimeReportResolution.OVERTIMECOMPENSATION ||
    timeReport.resolution == TimeReportResolution.FUNERALLEAVE ||
    timeReport.resolution == TimeReportResolution.MILITARYSERVICE ||
    timeReport.resolution == TimeReportResolution.WITHDRAWN ||
    timeReport.resolution == TimeReportResolution.CANCELLED ||
    timeReport.resolution == TimeReportResolution.OTHER
  );
};

export const getCosts = async (
  shifts: ShiftDto[] | ShiftRequestDto[],
  minHourlyRate: number,
  maxHourlyRate: number,
  gigType: GigType | undefined, //hääär
  companyId: number,
  paymentType: PaymentMethod | undefined
): Promise<{ minCost: GigCosts; maxCost: GigCosts }> => {
  const shiftsMin = shifts.map((shift) => {
    let breakStartTime = shift.breakStartTime;
    let breakEndTime = shift.breakEndTime;
    if (shift.breakTime) {
      breakEndTime = moment(shift.startTime)
        .add("minutes", shift.breakTime)
        .format("YYYY-MM-DDTHH:mm");
      breakStartTime = moment(shift.startTime).format("YYYY-MM-DDTHH:mm");
    }

    return {
      breakStartTime: breakStartTime,
      breakEndTime: breakEndTime,
      startTime: shift.startTime,
      endTime: shift.endTime,
      companyId: companyId,
      gigType: gigType,
      hourlyRate: minHourlyRate,
      workerId: null,
      paymentType: paymentType,
    };
  });

  const shiftsMax = shifts.map((shift) => {
    let breakStartTime = shift.breakStartTime;
    let breakEndTime = shift.breakEndTime;
    if (shift.breakTime) {
      breakEndTime = moment(shift.startTime)
        .add("minutes", shift.breakTime)
        .format("YYYY-MM-DDTHH:mm");
      breakStartTime = moment(shift.startTime).format("YYYY-MM-DDTHH:mm");
    }
    return {
      breakStartTime: breakStartTime,
      breakEndTime: breakEndTime,
      startTime: shift.startTime,
      endTime: shift.endTime,
      companyId: companyId,
      gigType: gigType,
      hourlyRate: maxHourlyRate,
      workerId: null,
      paymentType: paymentType,
    };
  });

  const minCostPromise = Api().company.gig.getGigCost(companyId, shiftsMin);
  const maxCostPromise = Api().company.gig.getGigCost(companyId, shiftsMax);

  const [minCostResponse, maxCostResponse] = await Promise.all([
    minCostPromise,
    maxCostPromise,
  ]);
  const minCost = minCostResponse;
  const maxCost = maxCostResponse;
  // @ts-ignore: Unreachable code error
  return { minCost, maxCost };
};

export const getCostsTimeReports = async (
  timeReports: TimeReportDto[],
  companyId: number
): Promise<GigCosts> => {
  const costsDto = timeReports.map((timeReport) => ({
    breakStartTime: timeReport.breakStartTime,
    breakEndTime: timeReport.breakEndTime,
    startTime: timeReport.startTime,
    endTime: timeReport.endTime,
    companyId: timeReport.companyId,
    gigType: timeReport.gigType,
    hourlyRate: timeReport.hourlyRate,
    workerId: timeReport.workerId,
    paymentType: timeReport.paymentType,
  }));

  return Api()
    .company.gig.getGigCost(companyId, costsDto)
    .then((costs) => costs)
    .catch((err) => err);
};

export const fetchWorker = async (workerId: number, companyState: any) => {
  if (companyState.company?.id) {
    return Api()
      .company.getWorkerDetailsByWorkerId(companyState.company?.id, workerId)
      .then((worker) => worker)
      .catch((err) => err);
  }
};
