import { LocalDate } from "@js-joda/core";
import { Text } from "components/Typography/text.styled";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import {
  ArrowSign,
  Cell,
  DeductionAmountContainer,
  DividerLine1,
  DividerLine2,
  DividerLine3,
  EmptyDiv,
  GrossSalaryContainer,
  HoursContainer,
  Logo,
  LogoContainer,
  MonthSelectContainer,
  NetSalaryContainer,
  PayrollTaxContainer,
  SalaryActionContainer,
  SalaryNoticeContainer,
  SalaryPageContainer,
  TaxContainer,
  WorkerContainer,
} from "./salary.styled";
import { FontFamily, FontSize } from "config/font";
import { Api } from "services/api/api.service";
import { Pagination } from "../gig/pagination.component";
import { SalaryNotice } from "model/Salary";
import { Color } from "config/colors";
import { IconCircularContainer } from "../dashboard/dashboard.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import { formatDuration } from "utils/utils";
import { useDownloadPDF } from "hooks/useDownloadPDF";
import { PaymentMethod } from "model/Gig";
import SimflexityPayLogo from "images/logo/svg/simflexityPay-logo.svg";

export const MonthlySalary = () => {
  const { t } = useTranslation();
  const [currentDate, setCurrentDate] = useState<LocalDate>(LocalDate.now());
  const [companyState] = useCompanyStore();
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const fetchAmount = 25;
  const [salaryNotices, setSalaryNotices] = useState<SalaryNotice[]>([]);
  const { fetchPdf } = useDownloadPDF();

  const getSalaries = useCallback(() => {
    if (companyState.company?.id) {
      Api()
        .company.salary.getSalaries(
          companyState.company.id,
          page,
          fetchAmount,
          currentDate.year(),
          currentDate.monthValue()
        )
        .then((res) => {
          setSalaryNotices(res.data.salaryNotices);
          setTotalPages(Math.ceil(res.data.salaryNotices.length / fetchAmount));
        })
        .catch((e) => {
          console.error("Error when getting salaries", e);
        });
    }
  }, [companyState.company?.id, page, fetchAmount, currentDate]);

  const handleDownloadSalaryPdf = (
    workerId: number,
    period: string,
    workerName: string,
    pdfId: number
  ) => {
    if (!companyState.company) return;

    fetchPdf(
      Api().company.salary.generateSalaryNotice(
        companyState.company.id,
        workerId,
        pdfId
      ),
      `SalaryNotice_${period}_${workerName}.pdf`
    );
  };

  useEffect(() => {
    getSalaries();
  }, [getSalaries]);

  return (
    <>
      <SalaryPageContainer>
        <MonthSelectContainer>
          <ArrowSign
            onClick={() => {
              setCurrentDate((prevState: LocalDate) =>
                prevState.minusMonths(1)
              );
            }}
          >
            &#60;
          </ArrowSign>
          <Text
            fontSize={FontSize.H4}
            fontFamily={FontFamily.MontserratSemiBold}
            style={{ margin: 0 }}
          >
            {t(`CompanyTimeReport.${currentDate.month()}`) +
              " " +
              currentDate.year()}
          </Text>
          <ArrowSign
            onClick={() => {
              setCurrentDate((prevState: LocalDate) => prevState.plusMonths(1));
            }}
          >
            &#62;
          </ArrowSign>
        </MonthSelectContainer>
        {salaryNotices.length > 0 ? (
          <>
            <SalaryNoticeContainer>
              {salaryNotices.map((salary) => {
                let currency = salary.currency == "SEK" ? " kr" : "";
                return (
                  <Cell>
                    <WorkerContainer>
                      <Text
                        style={{ margin: 0, padding: 0 }}
                        fontSize={FontSize.Small}
                        color={Color.SeaBlue600}
                      >
                        {t("General.Giggers")}
                      </Text>
                      <Text
                        color={Color.MidnightBlue}
                        fontFamily={FontFamily.MontserratRegular}
                      >{`${salary.workerName}`}</Text>
                    </WorkerContainer>
                    <DividerLine1 />
                    <HoursContainer>
                      <Text
                        style={{ margin: 0, padding: 0 }}
                        fontSize={FontSize.Small}
                        color={Color.SeaBlue600}
                      >
                        {t("Salary.TotalWorkTime")}
                      </Text>
                      <Text
                        color={Color.MidnightBlue}
                        fontFamily={FontFamily.MontserratRegular}
                      >{`${formatDuration(
                        salary.workedHours.toString()
                      )}`}</Text>
                    </HoursContainer>
                    <DividerLine2 />
                    <GrossSalaryContainer>
                      <Text
                        style={{ margin: 0, padding: 0 }}
                        fontSize={FontSize.Small}
                        color={Color.SeaBlue600}
                      >
                        {t("Salary.GrossSalary")}
                      </Text>
                      <Text
                        color={Color.MidnightBlue}
                        fontFamily={FontFamily.MontserratRegular}
                      >{`${salary.grossSalary}${currency}`}</Text>
                    </GrossSalaryContainer>
                    <NetSalaryContainer>
                      <Text
                        style={{ margin: 0, padding: 0 }}
                        fontSize={FontSize.Small}
                        color={Color.SeaBlue600}
                      >
                        {t("Salary.NetSalary")}
                      </Text>
                      <Text
                        color={Color.MidnightBlue}
                        fontFamily={FontFamily.MontserratRegular}
                      >{`${salary.netSalary}${currency}`}</Text>
                    </NetSalaryContainer>
                    <TaxContainer>
                      <Text
                        style={{ margin: 0, padding: 0 }}
                        fontSize={FontSize.Small}
                        color={Color.SeaBlue600}
                      >
                        {t("Salary.Tax")}
                      </Text>
                      <Text
                        color={Color.MidnightBlue}
                        fontFamily={FontFamily.MontserratRegular}
                      >{`${salary.preliminaryTax}${currency}`}</Text>
                    </TaxContainer>
                    <PayrollTaxContainer>
                      <Text
                        style={{ margin: 0, padding: 0 }}
                        fontSize={FontSize.Small}
                        color={Color.SeaBlue600}
                      >
                        {t("Salary.PayrollTax")}
                      </Text>
                      <Text
                        color={Color.MidnightBlue}
                        fontFamily={FontFamily.MontserratRegular}
                      >{`${salary.payrollTax}${currency}`}</Text>
                    </PayrollTaxContainer>
                    <DeductionAmountContainer>
                      <Text
                        style={{ margin: 0, padding: 0 }}
                        fontSize={FontSize.Small}
                        color={Color.SeaBlue600}
                      >
                        {t("Salary.DeductionAmount")}
                      </Text>
                      <Text
                        color={Color.MidnightBlue}
                        fontFamily={FontFamily.MontserratRegular}
                      >{`${salary.deductionAmount}${currency}`}</Text>
                    </DeductionAmountContainer>
                    <DividerLine3 />
                    <SalaryActionContainer>
                      <IconCircularContainer
                        isAccept
                        onClick={() => {
                          handleDownloadSalaryPdf(
                            salary.workerId,
                            salary.period,
                            salary.workerName,
                            salary.id
                          );
                        }}
                      >
                        <CustomIcon
                          className="icon"
                          name="document"
                          size="20px"
                          padding="2px"
                          color={Color.White}
                          title={t("Salary.DownloadPdf")}
                        />
                      </IconCircularContainer>

                      {salary.payrollType === PaymentMethod.SIMFLEXITY_PAY ? (
                        <LogoContainer>
                          <Logo
                            src={SimflexityPayLogo}
                            alt="SimflexityPay logo"
                          />
                        </LogoContainer>
                      ) : (
                        <EmptyDiv />
                      )}
                    </SalaryActionContainer>
                  </Cell>
                );
              })}
            </SalaryNoticeContainer>

            <Pagination setPage={setPage} page={page} totalPages={totalPages} />
          </>
        ) : (
          <Text style={{ textAlign: "center", marginTop: 40 }}>
            {t("Salary.PeriodNotClosed", {
              month: currentDate.monthValue() + 1,
            })}
          </Text>
        )}
      </SalaryPageContainer>
    </>
  );
};
