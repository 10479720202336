import { useState, useEffect } from "react";
import moment, { Moment } from "moment";
import { Color } from "config/colors";
import { TipList } from "./components/TipList";
import { useTranslation } from "react-i18next";
import { FontSize, FontFamily } from "config/font";
import { AlertModal } from "./components/alertModal";
import { TipFilters } from "./components/TipFilters";
import { CalculateModal } from "./components/calculateModal";
import { H3, Text } from "components/Typography/text.styled";
import { FocusedInputShape, DateRangePicker } from "react-dates";
import { SelectText } from "../../timeReport/timeReports.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DatePickerContainer } from "../../saveGig/shift/shiftForm.styled";
import { CircularProgressWrapper } from "../../calendar/weeklyCalendar.styled";
import {
  TipStatus,
  TipFilter,
  WorkerStatus,
  CalculationType,
  TipDetails,
} from "model/Tip";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import {
  ArrowSign,
  MonthSelectContainer,
} from "../../timeReport/monthlyOverview.styled";
import {
  SelectRow,
  InfoCircle,
  TipHeadCell,
  TipGridHeader,
  TipButtonStyled,
  AddTipContainer,
  TipButtonContainer,
  SpaceBtwItemsCenter,
} from "./tips.styled";
import { formatNumber } from "utils/utils";
import { LabelStyled } from "components/form/inputs/inputs.styled";
import { useTipStore } from "web-apps/company/stores/tipStore/tipStore";

export const CompanyTipPayout = () => {
  const { t } = useTranslation();
  const [companyState] = useCompanyStore();
  const companyId = companyState.company?.id;
  const [isLoading, setIsLoading] = useState(true);
  const [showHelpInfo, setShowHelpInfo] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(moment());
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [selectedTipIds, setSelectedTipIds] = useState<number[]>([]);
  const [expandedTipIds, setExpandedTipIds] = useState<number[]>([]);
  const [isMultiSelectActive, setIsMultiSelectActive] = useState(false);
  const [tipAmountAlertShown, setTipAmountAlertShown] = useState(false);
  const [selectedTips, setSelectedTips] = useState<number[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<TipFilter>(
    TipFilter.All
  );
  const [activeTab, setActiveTab] = useState<CalculationType>(
    CalculationType.Time
  );
  const [remainingAmounts, setRemainingAmounts] = useState<{
    [tipId: number]: number;
  }>({});
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null
  );
  const [workerStatuses, setWorkerStatuses] = useState<{
    [date: string]: { [workerId: number]: WorkerStatus | null };
  }>({});
  const [tipState, tipActions] = useTipStore();
  const { workGroups } = tipState;
  const tipsList = tipState.tips;
  const totalTipsAmount = tipsList.reduce((acc, tip) => acc + tip.amount, 0);

  useEffect(() => {
    if (!companyId) return;

    setIsLoading(false);
    setSelectedFilter(TipFilter.All);
    setIsMultiSelectActive(false);
    handleFetchTips();
  }, [companyId, currentDate]);

  const handleFetchTips = async () => {
    if (!companyId) return;

    const selectedMonth = currentDate.format("YYYY-MM");
    await tipActions.fetchTips(companyId, selectedMonth);
  };

  const handleDatesChange = (arg: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    setStartDate(arg.startDate);
    setEndDate(arg.endDate);
  };

  const handleAddTip = async () => {
    if (!startDate || !endDate || !companyId) return;

    let current = moment(startDate);
    const datesToAdd: string[] = [];

    while (current.isSameOrBefore(endDate, "day")) {
      datesToAdd.push(current.format("YYYY-MM-DD"));
      current.add(1, "day");
    }

    await Promise.all(
      datesToAdd.map((dateStr) => tipActions.addTip(companyId, dateStr))
    );

    const firstDate = moment(datesToAdd[0]);
    const monthOfFirstDate = firstDate.startOf("month");

    if (!monthOfFirstDate.isSame(currentDate, "month")) {
      setCurrentDate(monthOfFirstDate);
    }

    setStartDate(null);
    setEndDate(null);
  };

  const handleDelete = async (tipId: number) => {
    if (!companyId) return;

    await tipActions.deleteTip(companyId, tipId);
    setSelectedTips((prev) => prev.filter((id) => id !== tipId));
  };

  const getFilteredTips = () => {
    switch (selectedFilter) {
      case TipFilter.Saved:
        return tipsList.filter((tip) => tip.status === TipStatus.Saved);
      case TipFilter.ReadyToPayout:
        return tipsList.filter((tip) => tip.status === TipStatus.Done);
      case TipFilter.PaidOut:
        return tipsList.filter(
          (tip) =>
            tip.status === TipStatus.Closed ||
            tip.status === TipStatus.ClosedManually
        );
      default:
        return tipsList;
    }
  };
  const filteredTips = getFilteredTips();

  useEffect(() => {
    setIsMultiSelectActive(false);
    setSelectedTips([]);
    setExpandedTipIds([]);
  }, [selectedFilter]);

  const openDistributeModal = async (tipId: number) => {
    if (!companyId) return;

    await tipActions.calculateTips(
      companyId,
      [tipId],
      Object.values(workGroups)
        .flat()
        .map((group) => ({
          id: group.workGroupId,
          percentage: group.percentage,
        }))
    );

    setSelectedTips([tipId]); // viktig för CalculateModal
    setIsModalOpen(true);
  };

  const isInputDisabled = (tip: TipDetails) =>
    selectedFilter === TipFilter.Saved ||
    selectedFilter === TipFilter.ReadyToPayout;

  useEffect(() => {
    const updatedStatuses: {
      [date: string]: { [workerId: number]: WorkerStatus | null };
    } = {};

    tipState.tips.forEach((tip) => {
      if (!updatedStatuses[tip.date]) {
        updatedStatuses[tip.date] = {};
      }

      tip.workers.forEach((worker) => {
        updatedStatuses[tip.date][worker.workerId] = worker.status ?? null;
      });
    });

    setWorkerStatuses(updatedStatuses);
  }, [tipState.tips]);

  const getWorkerStatus = (workerId: number, date: string): JSX.Element => {
    const status = workerStatuses[date][workerId];

    if (status === null) {
      return (
        <Text
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0",
          }}
        >
          {t("CompanyTip.NotInitiated")}
        </Text>
      );
    }
    switch (status) {
      case WorkerStatus.Created:
        return (
          <Text
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0",
            }}
          >
            {t("CompanyTip.OnGoing")}
          </Text>
        );
      case WorkerStatus.Paid:
        return (
          <Text
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0",
            }}
          >
            {t("CompanyTip.PaidOut")}
            <CustomIcon
              name="checkmark"
              color={Color.BurntSienna}
              padding="4px"
            />
          </Text>
        );
      case WorkerStatus.Failed:
        return (
          <Text
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {t("CompanyTip.Failed")}
            <CustomIcon name="alert" color={Color.BurntSienna} padding="4px" />
          </Text>
        );
      default:
        return <Text>{t("CompanyTip.NotInitiated")}</Text>;
    }
  };

  const toggleSelectTip = (id: number) => {
    setSelectedTips((prev) => {
      const isAlreadySelected = prev.includes(id);
      const updatedSelection = isAlreadySelected
        ? prev.filter((tipId) => tipId !== id)
        : [...prev, id];

      return updatedSelection;
    });
  };

  const handleCalculateTips = () => {
    if (!companyId || selectedTips.length === 0) return;

    tipActions.calculateTips(
      companyId,
      selectedTips,
      Object.values(workGroups)
        .flat()
        .map((group) => ({
          id: group.workGroupId,
          percentage: group.percentage,
        }))
    );

    setIsModalOpen(true);
  };

  const updateRemainingAmounts = (updatedAmounts: {
    [date: string]: number;
  }) => {
    setRemainingAmounts(updatedAmounts);
  };

  const toggleDropdown = (tipId: number) => {
    const isCurrentlyExpanded = expandedTipIds.includes(tipId);

    if (!isCurrentlyExpanded) {
      if (!tipState.tipDetails[tipId]) {
        tipActions.fetchTipDetails(companyId, tipId);
      }

      // setTimeout(() => {
      setExpandedTipIds((prevExpandedTipIds) => [...prevExpandedTipIds, tipId]);
      // }, 100);
    } else {
      setExpandedTipIds((prevExpandedTipIds) =>
        prevExpandedTipIds.filter((id) => id !== tipId)
      );
    }
  };

  return (
    <AlertModal>
      {(openAlertModal) => {
        const handleTipAmountChange = async (
          tipId: number,
          newAmount: string,
          date: string
        ) => {
          if (!/^\d*$/.test(newAmount) || !companyId) return;

          const parsedAmount =
            newAmount.trim() === "" ? 0 : parseFloat(newAmount);
          if (isNaN(parsedAmount)) return;

          const tip = tipsList.find((t) => t.companyTipId === tipId);
          if (!tip) return;

          const updateAmount = async () => {
            await tipActions.updateTipAmount(companyId, tipId, parsedAmount);
            setRemainingAmounts((prev) => ({ ...prev, [tipId]: parsedAmount }));
          };

          if (
            (tip.status === TipStatus.Saved || tip.status === TipStatus.Done) &&
            !tipAmountAlertShown
          ) {
            openAlertModal(
              t("CompanyTip.IfYouChangeTipAllAmountsWillReset"),
              true,
              async () => {
                await updateAmount();
                setTipAmountAlertShown(true);
              }
            );
            return;
          }

          await updateAmount();
        };

        const handleManualPayout = async (companyTipIds: number | number[]) => {
          if (!companyId) return;

          const ids = Array.isArray(companyTipIds)
            ? companyTipIds
            : [companyTipIds];

          openAlertModal(
            t("CompanyTip.AreYouSureToComplete"),
            true,
            async () => {
              await tipActions.payoutTips(companyId, ids);

              openAlertModal(t("CompanyTip.TipCompletedManually"), false);
            }
          );
        };

        return (
          <>
            {isLoading ? (
              <CircularProgressWrapper>
                <CircularProgress />
              </CircularProgressWrapper>
            ) : (
              <>
                <H3>{t("CompanyTip.Tip")}</H3>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <LabelStyled>{t("CompanyTip.EnterDate")}</LabelStyled>
                  <InfoCircle
                    onMouseEnter={() => setShowHelpInfo(true)}
                    onMouseLeave={() => setShowHelpInfo(false)}
                  >
                    <Text
                      fontFamily={FontFamily.LeagueSpartanBold}
                      color={Color.White}
                      padding
                      fontSize={FontSize.Small}
                    >
                      i
                    </Text>
                  </InfoCircle>
                </div>

                {showHelpInfo && (
                  <Text fontSize={FontSize.Small}>
                    {t("CompanyTip.HelpInfo")}
                  </Text>
                )}

                <AddTipContainer>
                  <DatePickerContainer>
                    <DateRangePicker
                      startDate={startDate}
                      startDateId="startDate"
                      endDate={endDate}
                      endDateId="endDate"
                      onDatesChange={handleDatesChange}
                      focusedInput={focusedInput}
                      numberOfMonths={1}
                      hideKeyboardShortcutsPanel={true}
                      startDatePlaceholderText={t("GigsCompany.StartDate")}
                      endDatePlaceholderText={t("GigsCompany.EndDate")}
                      displayFormat="DD/MM/YYYY"
                      firstDayOfWeek={1}
                      minimumNights={0}
                      isOutsideRange={(day) => day.isAfter(moment(), "day")}
                      onFocusChange={(focusedInput: FocusedInputShape | null) =>
                        setFocusedInput(focusedInput)
                      }
                      isDayHighlighted={(day) =>
                        day.format("DD/MM/YYYY") ===
                        moment().format("DD/MM/YYYY")
                      }
                    />
                  </DatePickerContainer>
                  <TipButtonStyled
                    backgroundColor={Color.ModernGreen}
                    style={{ marginLeft: "10px" }}
                    onClick={handleAddTip}
                  >
                    {t("CompanyTip.Add")}
                  </TipButtonStyled>
                </AddTipContainer>

                <SpaceBtwItemsCenter>
                  <TipFilters
                    selectedFilter={selectedFilter}
                    setSelectedFilter={setSelectedFilter}
                  />
                  <SelectRow>
                    <SelectText
                      fontFamily={FontFamily.MontserratSemiBold}
                      onClick={() => {
                        setIsMultiSelectActive(!isMultiSelectActive);
                        setSelectedTips([]);
                      }}
                    >
                      {t("CompanyTimeReport.SelectMultiple")}
                    </SelectText>
                    <CustomIcon
                      name="checkmark"
                      size="27px"
                      color={Color.White}
                      backgroundColor={
                        isMultiSelectActive ? Color.MidnightBlue : Color.White
                      }
                      padding="2px"
                      onClick={() => {
                        setIsMultiSelectActive(!isMultiSelectActive);
                        setSelectedTips([]);
                      }}
                      style={{
                        border: `2px solid ${Color.MidnightBlue}`,
                        flexShrink: 0,
                        marginLeft: "8px",
                        marginRight: "12px",
                      }}
                    />
                  </SelectRow>
                </SpaceBtwItemsCenter>

                <MonthSelectContainer>
                  <ArrowSign
                    onClick={() => {
                      setCurrentDate((prevState) =>
                        moment(prevState).subtract(1, "months")
                      );
                    }}
                    title={t("CompanyTip.PreviousMonth")}
                  >
                    &#60;
                  </ArrowSign>
                  <Text
                    fontSize={FontSize.H4}
                    fontFamily={FontFamily.MontserratSemiBold}
                    style={{ margin: 0 }}
                  >
                    {currentDate.format("MMMM")[0].toUpperCase() +
                      currentDate.format("MMMM").slice(1)}{" "}
                    {currentDate.year()}
                  </Text>
                  <ArrowSign
                    onClick={() => {
                      setCurrentDate((prevState) =>
                        moment(prevState).add(1, "months")
                      );
                    }}
                    title={t("CompanyTip.NextMonth")}
                  >
                    &#62;
                  </ArrowSign>
                </MonthSelectContainer>

                <TipGridHeader>
                  <TipHeadCell>
                    <Text
                      fontSize={FontSize.Large}
                      fontFamily={FontFamily.MontserratSemiBold}
                    >
                      {t("CompanyTip.Date")}
                    </Text>
                  </TipHeadCell>
                  <TipHeadCell>
                    <Text
                      fontSize={FontSize.Large}
                      fontFamily={FontFamily.MontserratSemiBold}
                    >
                      {t("CompanyTip.EnterTip")}
                    </Text>
                  </TipHeadCell>
                  <TipHeadCell>
                    <Text
                      fontSize={FontSize.Large}
                      fontFamily={FontFamily.MontserratSemiBold}
                    >
                      {t("CompanyTip.Remaining")}
                    </Text>
                  </TipHeadCell>
                  <TipHeadCell>
                    <Text
                      fontSize={FontSize.Large}
                      fontFamily={FontFamily.MontserratSemiBold}
                    >
                      {t("CompanyTip.Status")}
                    </Text>
                  </TipHeadCell>
                </TipGridHeader>

                {companyId && (
                  <TipList
                    tipState={tipState}
                    tips={filteredTips}
                    selectedTips={selectedTips}
                    selectedTipIds={selectedTipIds}
                    expandedTipIds={expandedTipIds}
                    remainingAmounts={remainingAmounts}
                    isMultiSelectActive={isMultiSelectActive}
                    handleDelete={handleDelete}
                    toggleDropdown={toggleDropdown}
                    isInputDisabled={isInputDisabled}
                    toggleSelectTip={toggleSelectTip}
                    getWorkerStatus={getWorkerStatus}
                    handleManualPayout={handleManualPayout}
                    openDistributeModal={openDistributeModal}
                    handleTipAmountChange={handleTipAmountChange}
                  />
                )}

                {selectedFilter === "All" && (
                  <Text
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    fontSize={FontSize.Large}
                    fontFamily={FontFamily.MontserratSemiBold}
                  >
                    {t("CompanyTip.TotalTipForMonth")}
                    {formatNumber(totalTipsAmount)} {t("CompanyTip.Kr")}
                  </Text>
                )}

                {selectedFilter !== TipFilter.PaidOut && (
                  <TipButtonContainer
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {selectedFilter === TipFilter.ReadyToPayout ? (
                      <TipButtonStyled
                        backgroundColor={Color.MidnightBlue}
                        onClick={() => handleManualPayout(selectedTips)}
                        disabled={selectedTips.length === 0}
                      >
                        {t("CompanyTip.PayoutSelectedTips")} (
                        {selectedTips.length})
                      </TipButtonStyled>
                    ) : (
                      <TipButtonStyled
                        backgroundColor={Color.MidnightBlue}
                        onClick={handleCalculateTips}
                        disabled={
                          selectedTips.length === 0 ||
                          selectedTips.some(
                            (tipId) =>
                              tipsList.find((tip) => tip.companyTipId === tipId)
                                ?.amount === 0
                          )
                        }
                      >
                        {t("CompanyTip.DistributeSelectedTips")} (
                        {selectedTips.length})
                      </TipButtonStyled>
                    )}
                  </TipButtonContainer>
                )}

                {companyId && (
                  <CalculateModal
                    open={isModalOpen}
                    activeTab={activeTab}
                    companyId={companyId}
                    setActiveTab={setActiveTab}
                    selectedTipIds={selectedTips}
                    onSaveSuccess={handleFetchTips}
                    onClose={() => setIsModalOpen(false)}
                    calculationResult={tipState.calculationResult}
                    onUpdateRemainingAmount={updateRemainingAmounts}
                  />
                )}
              </>
            )}
          </>
        );
      }}
    </AlertModal>
  );
};
