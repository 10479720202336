import styled from "styled-components";
import { FontFamily } from "config/font";
import { Color } from "config/colors";

export const NumberInputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  font-family: ${FontFamily.MontserratBold}, Helvetica;
`;

export const HourlyRateContainer = styled.div`
  display: flex;
  align-center: center;
  margin-top: 10px;
`;

export const FeesContainer = styled.div`
  margin-top: 10px;
  font-family: ${FontFamily.MontserratBold}, Helvetica;
  background-color: ${Color.SeaBlue200};
  border-radius: 8px;
  padding: 10px;
  display: inline-block;
  max-width: 600px;
`;

export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    margin: 5px;
  }
`;

export const InfoCircle = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${Color.SeaBlue600};
  border-radius: 40px;
  width: 17px;
  height: 17px;
  align-items: center;
  margin: 0 5px;
`;
