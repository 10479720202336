import { AxiosResponse } from "axios";
import { axiosRequest } from "../api.utils";
import { API_ROOT } from "services/baseURLs.config";

const getCompanySetting = async (
  companyId: number,
  key: string
): Promise<AxiosResponse<any>> => {
  return await axiosRequest.auth.get(
    `${API_ROOT}/companies/${companyId}/settings/${key}`
  );
};

const updateCompanySetting = async (
  companyId: number,
  setting: string,
  value: string
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.put<any>(
    `${API_ROOT}/companies/${companyId}/settings`,
    { key: setting, value }
  );
};

export const systemSettings = {
  getCompanySetting,
  updateCompanySetting,
};
