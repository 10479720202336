import moment from "moment";
import { AxiosResponse } from "axios";
import { TipDispatch } from "./tipStore";
import { TipState } from "./tipStore.reducer";
import { Api } from "services/api/api.service";
import {
  PayoutType,
  AddTipRequest,
  GetTipsResponse,
  WorkGroupPayload,
  GetTipsQueryParams,
  CalculateTipsRequest,
  SaveTipPeriodRequest,
} from "model/Tip";

const fetchTips =
  (dispatch: TipDispatch) =>
  async (
    companyId: number,
    month: string = moment().format("YYYY-MM")
  ): Promise<AxiosResponse<GetTipsResponse> | void> => {
    dispatch({ type: "FETCH_TIPS_START" });

    try {
      const queryParams: GetTipsQueryParams = {
        month,
        sortAscending: true,
      };

      const response = await Api().company.tip.getTips(companyId, queryParams);

      dispatch({
        type: "FETCH_TIPS_SUCCESS",
        payload: response.data,
      });
      return response;
    } catch (e: any) {
      console.error("Could not fetch tips", e);
      dispatch({
        type: "SET_REQUEST_ERROR",
        payload: {
          message: e.response?.data?.errorMessage || "Error fetching tips",
          err: e,
        },
      });
      return Promise.reject(e);
    }
  };

const addTip =
  (dispatch: TipDispatch) =>
  async (companyId: number, date: string): Promise<void> => {
    dispatch({ type: "SET_IS_LOADING", payload: true });

    try {
      const response = await Api().company.tip.addTips(companyId, {
        date,
        amount: 0,
      });

      dispatch({ type: "ADD_TIP", payload: response.data });
    } catch (e: any) {
      console.error("Could not add tip:", e);
      dispatch({
        type: "SET_REQUEST_ERROR",
        payload: {
          message: e.response?.data?.errorMessage || "Error adding tip",
          err: e,
        },
      });
    } finally {
      dispatch({ type: "SET_IS_LOADING", payload: false });
    }
  };

const updateTipAmount =
  (dispatch: TipDispatch) =>
  async (companyId: number, tipId: number, amount: number): Promise<void> => {
    dispatch({ type: "SET_IS_LOADING", payload: true });

    try {
      await Api().company.tip.updateTip(companyId, tipId, {
        amount,
      } as AddTipRequest);

      const response = await Api().company.tip.getTipById(companyId, tipId);

      dispatch({ type: "FETCH_TIP_DETAILS_SUCCESS", payload: response.data });
    } catch (e: any) {
      console.error("Could not update amount:", e);
      dispatch({
        type: "SET_REQUEST_ERROR",
        payload: {
          message: e.response?.data?.errorMessage || "Error updating tip",
          err: e,
        },
      });
    } finally {
      dispatch({ type: "SET_IS_LOADING", payload: false });
    }
  };

const fetchTipDetails =
  (dispatch: TipDispatch) =>
  async (companyId: number | undefined, tipId: number): Promise<void> => {
    if (!companyId) {
      console.error("companyId is undefined, cannot fetch tip details.");
      return;
    }
    dispatch({ type: "FETCH_TIP_DETAILS_START" });

    try {
      const response = await Api().company.tip.getTipById(companyId, tipId);

      dispatch({
        type: "FETCH_TIP_DETAILS_SUCCESS",
        payload: response.data,
      });
    } catch (e: any) {
      console.error("Could not fetch tip details", e);
      dispatch({
        type: "SET_REQUEST_ERROR",
        payload: {
          message:
            e.response?.data?.errorMessage || "Error fetching tip details",
          err: e,
        },
      });
    }
  };

const calculateTips =
  (dispatch: TipDispatch) =>
  async (
    companyId: number,
    selectedTipIds: number[],
    workGroups: WorkGroupPayload[]
  ) => {
    dispatch({ type: "SET_IS_LOADING", payload: true });

    try {
      const payload: CalculateTipsRequest = {
        companyTipIds: selectedTipIds,
        workGroups: workGroups?.map((group) => ({
          id: group.id,
          percentage: group.percentage,
        })),
      };

      const response = await Api().company.tip.calculateTips(
        companyId,
        payload
      );

      dispatch({
        type: "CALCULATE_TIPS_SUCCESS",
        payload: {
          companyTips: response.data?.companyTips || [],
          workGroups: response.data?.workGroups || [],
        },
      });
    } catch (e: any) {
      console.error("Could not calculate tips:", e);
      dispatch({
        type: "SET_REQUEST_ERROR",
        payload: {
          message: e.response?.data?.errorMessage || "Error calculating tips",
          err: e,
        },
      });
    } finally {
      dispatch({ type: "SET_IS_LOADING", payload: false });
    }
  };

const saveTipDistribution =
  (dispatch: TipDispatch) =>
  async (companyId: number, companyTips: SaveTipPeriodRequest[]) => {
    dispatch({ type: "SET_IS_LOADING", payload: true });

    try {
      const payload = companyTips
        .filter((tip) => tip.workers.length > 0)
        .map(({ workers, remainingAmount, companyTipId }) => ({
          companyTipId,
          workers: workers.map(
            ({ workerId, workTime, tipAmount, workGroupId }) => ({
              workerId,
              workTime,
              tipAmount,
              workGroupId,
            })
          ),
          remainingAmount,
        }));

      const { data } = await Api().company.tip.saveTipPeriod(
        companyId,
        payload
      );
      // console.log("data", data);

      // data.companyTips.forEach((row) => {
        dispatch({
          type: "SAVE_TIP_DISTRIBUTION_SUCCESS",
          payload: data,
        });
      // });
      // await fetchTips(dispatch)(companyId);
      
    } catch (e: any) {
      console.error("Could not save tip distribution:", e);
      dispatch({
        type: "SET_REQUEST_ERROR",
        payload: {
          message:
            e.response?.data?.errorMessage || "Error saving distribution",
          err: e,
        },
      });
    } finally {
      dispatch({ type: "SET_IS_LOADING", payload: false });
    }
  };

const payoutTips =
  (dispatch: TipDispatch) =>
  async (companyId: number, companyTipIds: number[]): Promise<void> => {
    if (!companyId || companyTipIds.length === 0) return;

    dispatch({ type: "SET_IS_LOADING", payload: true });

    try {
      const payload = {
        companyTipIds,
        payoutType: PayoutType.Manual,
      };

      await Api().company.tip.payoutTips(companyId, payload);

      dispatch({
        type: "MANUAL_PAYOUT_SUCCESS",
        payload: companyTipIds,
      });
    } catch (e: any) {
      console.error("Could not complete manual payout:", e);
      dispatch({
        type: "SET_REQUEST_ERROR",
        payload: {
          message:
            e.response?.data?.errorMessage || "Error completing manual payout",
          err: e,
        },
      });
      dispatch({
        type: "REVERT_MANUAL_PAYOUT",
        payload: companyTipIds,
      });
    } finally {
      dispatch({ type: "SET_IS_LOADING", payload: false });
    }
  };

const deleteTip =
  (dispatch: TipDispatch) =>
  async (companyId: number, tipId: number): Promise<void> => {
    dispatch({ type: "SET_IS_LOADING", payload: true });

    try {
      await Api().company.tip.deleteTipPeriod(companyId, tipId);
      dispatch({ type: "DELETE_TIP", payload: tipId });
    } catch (e: any) {
      console.error("Could not delete tip:", e);
      dispatch({
        type: "SET_REQUEST_ERROR",
        payload: {
          message: e.response?.data?.errorMessage || "Error deleting tip",
          err: e,
        },
      });
    } finally {
      dispatch({ type: "SET_IS_LOADING", payload: false });
    }
  };

export const tipActions = (dispatch: TipDispatch, state: TipState) => ({
  fetchTips: fetchTips(dispatch),
  addTip: addTip(dispatch),
  updateTipAmount: updateTipAmount(dispatch),
  fetchTipDetails: fetchTipDetails(dispatch),
  calculateTips: calculateTips(dispatch),
  saveTipDistribution: saveTipDistribution(dispatch),
  payoutTips: payoutTips(dispatch),
  deleteTip: deleteTip(dispatch),
});
