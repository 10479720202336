export enum TipStatus {
  New = "new",
  Created = "Created",
  Saved = "Saved",
  Done = "Done",
  Closed = "Closed",
  ClosedManually = "ClosedManually",
  Cancelled = "Cancelled",
}

export enum CalculationType {
  Time = "Time",
  Workgroup = "Workgroup",
  Manual = "Manual",
}

export type WorkGroup = {
  workGroupId: number;
  workGroupName: string;
  amount: number;
  remainingAmount: number;
  percentage: number;
  membersCount?: number;
};

export type WorkGroupPayload = {
  id: number;
  percentage: number;
};

export enum PayoutType {
  Manual = "Manual",
  Automatic = "Automatic",
}

export type TipDetails = {
  companyTipId: number;
  date: string;
  status: TipStatus;
  amount: number;
  remainingAmount: number;
  isReadyForPayout: boolean;
  calculationSettings?: CalculationSettings;
  approvedCount: number;
  totalCount: number;
  workers: Worker[];
  workGroups: WorkGroup[];
};

export type CalculateTipsResponse = {
  workGroups: WorkGroup[];
  companyTips: TipDetails[];
};

export enum WorkerStatus {
  null = "null",
  Created = "Created", // Betalning påbörjad
  Paid = "Paid",
  Failed = "Failed",
}

export type Worker = {
  workerId: number;
  firstName?: string;
  lastName?: string;
  externalId?: string; // För profilbild
  workTime: string;
  tipAmount: number;
  status?: WorkerStatus;
  workGroupId?: number;
  workGroupName?: string;
};

export type CalculationSettings = {
  type: CalculationType;
  workGroups?: WorkGroup[];
};

export type SelectTipsResponse = {
  workGroups: WorkGroupResponse[];
  companyTips: TipDetails[];
};

export type AddTipRequest = {
  date: string;
  amount: number;
};

export type UpdateTipResponse = {
  companyTipId: number;
  amount: number;
  status: TipStatus;
  date: string;
};

export enum TipFilter {
  All = "All",
  Saved = "Saved",
  ReadyToPayout = "ReadyToPayout",
  PaidOut = "PaidOut",
}

export type GetTipsResponse = TipDetails[];

export type AddTipsResponse = {
  date: string;
  amount: number;
};

export type GetTipsQueryParams = {
  status?: TipStatus;
  sortAscending: boolean;
  fetchPageAmount?: number;
  month: string;
};

export type GetTipByIdResponse = TipDetails;

export type WorkGroupResponse = {
  workGroupId: number;
  workGroupName: string;
  amount: number;
  remainingAmount: number;
  membersCount?: number;
  percentage?: number;
};

export type CalculateTipsRequest = {
  companyTipIds: number[];
  workGroups: {
    id: number;
    percentage: number;
  }[];
};

export type SaveTipPeriodRequest = {
  companyTipId: number;
  remainingAmount: number;
  workers: {
    workerId: number;
    workTime: string;
    tipAmount: number;
    workGroupId?: number;
  }[];
};

export type SaveTipPeriodResponse = SaveTipPeriodRequest[];
