import React from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "../gig/gigs.styled";
import { Text } from "components/Typography/text.styled";
import { FontSize } from "config/font";

type Props = {
  setMonthlyRate: React.Dispatch<React.SetStateAction<string>>;
  setHourlyRate: React.Dispatch<React.SetStateAction<string>>;
  monthlyRate: string;
  hourlyRate: string;
};

export const WorkGroupRate: React.FC<Props> = ({
  setMonthlyRate,
  setHourlyRate,
  monthlyRate,
  hourlyRate,
}) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {/*<Text
        style={{ marginTop: 10, marginBottom: 0 }}
        fontSize={FontSize.Small}
      >
        {t("WorkGroup.MonthlyRate")}
      </Text>
      <TextInput
        style={{ marginTop: 10 }}
        value={monthlyRate}
        onChange={(e: any) => {
          const input = e.currentTarget.value;
          if (/^\d*\.?\d*$/.test(input)) {
            setMonthlyRate(input);
          }
        }}
        maxLength={8}
      />*/}
      <Text
        style={{ marginTop: 10, marginBottom: 0 }}
        fontSize={FontSize.Small}
      >
        {t("WorkGroup.HourlyRate")}
      </Text>
      <TextInput
        style={{ marginTop: 10 }}
        value={hourlyRate}
        onChange={(e: any) => {
          const input = e.currentTarget.value;
          if (/^\d*\.?\d*$/.test(input)) {
            setHourlyRate(input);
          }
        }}
        maxLength={8}
      />
    </div>
  );
};
