import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";

export const useDownloadPDF = () => {
  const [companyState] = useCompanyStore();

  const downloadSalaryPdf = (blob: any, filename: string) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const fetchPdf = async (endpoint: any, filename: string) => {
    if (!companyState.company?.id) return;

    try {
      const response = await endpoint;

      downloadSalaryPdf(new Blob([response.data]), filename);
    } catch (error) {
      console.error("Failed to download PDF:", error);
    }
  };

  return {
    fetchPdf,
  };
};
