import { AxiosResponse } from "axios";
import { axiosRequest } from "../api.utils";
import { API_ROOT } from "services/baseURLs.config";

const getSalaries = async (
  companyId: number,
  page: number,
  pageSize: number,
  year: number,
  month: number
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/salaries?page=${page}&pageSize=${pageSize}&year=${year}&month=${month}`
  );
};

const generateSalaryNotice = async (
  companyId: number,
  workerId: number,
  pdfId: number
): Promise<AxiosResponse<Blob>> => {
  return await axiosRequest.auth.get<Blob>(
    `${API_ROOT}/companies/${companyId}/salaries/workers/${workerId}/pdf/${pdfId}`,
    {
      headers: {
        "Content-Type": "application/pdf",
      },
      responseType: "blob",
    }
  );
};

const getCompanyWorkers = async (companyId: number): Promise<AxiosResponse> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/salaries/workers`
  );
};

const getWorkerSalaries = async (
  companyId: number,
  workerId: number,
  year?: number
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/salaries`,
    {
      params: {
        workerId,
        year,
      },
    }
  );
};

export const salary = {
  getSalaries,
  generateSalaryNotice,
  getCompanyWorkers,
  getWorkerSalaries,
};
