import React, { useEffect, useState } from "react";
import moment from "moment";
import { Color } from "config/colors";
import { formatNumber } from "utils/utils";
import { useTranslation } from "react-i18next";
import { TipDetails, TipStatus } from "model/Tip";
import { TipDetailsComponent } from "./TipDetails";
import { FontFamily, FontSize } from "config/font";
import { Text } from "components/Typography/text.styled";
import { TipStatusIndicator } from "./tipStatusIndicator";
import { CustomIcon } from "components/icon/customIcon.component";
import { IconCircularContainer } from "../../../dashboard/dashboard.styled";
import { TipState } from "web-apps/company/stores/tipStore/tipStore.reducer";
import {
  TipCell,
  TipInput,
  ActionsCell,
  WeekContainer,
  TipColumnDivider,
  TipCellContainer,
} from "../tips.styled";

type TipListProps = {
  tipState: TipState;
  tips: TipDetails[];
  selectedTips: number[];
  selectedTipIds: number[];
  expandedTipIds: number[];
  isMultiSelectActive: boolean;
  remainingAmounts: Record<string, number>;
  handleDelete: (tipId: number) => void;
  toggleDropdown: (tipId: number) => void;
  toggleSelectTip: (tipId: number) => void;
  handleManualPayout: (tipId: number) => void;
  openDistributeModal: (tipId: number) => void;
  isInputDisabled: (tip: TipDetails) => boolean;
  getWorkerStatus: (workerId: number, date: string) => JSX.Element;
  handleTipAmountChange: (tipId: number, value: string, date: string) => void;
};

export const TipList: React.FC<TipListProps> = (props) => {
  const { t } = useTranslation();
  const [tipsWithWeeks, setTipsWithWeeks] = useState<{
    [week: number]: TipDetails[];
  }>({});
  const [tipInputValues, setTipInputValues] = useState<{
    [key: number]: string;
  }>({});

  useEffect(() => {
    if (!props.tips || props.tips.length === 0) {
      setTipsWithWeeks({});
      return;
    }

    const grouped: { [week: number]: TipDetails[] } = {};

    props.tips.forEach((tip) => {
      const weekNumber = moment(tip.date).isoWeek();
      if (!grouped[weekNumber]) {
        grouped[weekNumber] = [];
      }
      grouped[weekNumber].push(tip);
    });

    setTipsWithWeeks(grouped);
  }, [props.tips]);

  const shouldShowCheckmark = (tip: TipDetails): boolean => {
    return (
      tip.status === TipStatus.Saved ||
      tip.status === TipStatus.Done ||
      (tip.status === TipStatus.Created && tip.amount > 0)
    );
  };

  const closedTip = (tip: TipDetails): boolean => {
    return (
      tip.status === TipStatus.Closed || tip.status === TipStatus.ClosedManually
    );
  };

  return (
    <>
      {Object.keys(tipsWithWeeks).length === 0 ? (
        <Text>{t("CompanyTip.NoPeriodsWithChosenFilter")}</Text>
      ) : (
        Object.entries(tipsWithWeeks).map(([weekNumber, weekTips]) => (
          <div key={weekNumber}>
            {weekTips.map((tip: TipDetails, index: number) => (
              <div key={tip.companyTipId}>
                {index === 0 && (
                  <WeekContainer>
                    <Text
                      style={{ margin: "8px" }}
                      fontFamily={FontFamily.MontserratSemiBold}
                    >
                      {t("CompanyTip.Week")} {weekNumber}
                    </Text>
                  </WeekContainer>
                )}

                <TipCellContainer
                  isSelected={props.selectedTipIds.includes(tip.companyTipId)}
                  isExpanded={props.expandedTipIds.includes(tip.companyTipId)}
                >
                  <TipCell>
                    <Text fontSize={FontSize.Large}>
                      {moment(tip.date).format("ddd").toUpperCase()}{" "}
                      {moment(tip.date).format("DD/MM")}
                    </Text>
                  </TipCell>
                  <TipColumnDivider />
                  <TipCell>
                    <TipInput
                      width="16vh"
                      type="text"
                      maxLength={5}
                      placeholder="0"
                      disabled={closedTip(tip) || props.isInputDisabled(tip)}
                      value={
                        tipInputValues[tip.companyTipId] ??
                        (tip.amount === 0 ? "" : tip.amount.toString())
                      }
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setTipInputValues((prev) => ({
                          ...prev,
                          [tip.companyTipId]: newValue,
                        }));
                        props.handleTipAmountChange(
                          tip.companyTipId,
                          newValue,
                          moment(tip.date).format("YYYY-MM-DD")
                        );
                      }}
                    />
                  </TipCell>
                  <TipColumnDivider />
                  <TipCell>
                    <Text fontSize={FontSize.Large}>
                      {formatNumber(
                        props.remainingAmounts[tip.companyTipId] ??
                          tip.remainingAmount
                      )}{" "}
                      {t("CompanyTip.Kr")}
                    </Text>
                  </TipCell>
                  <TipColumnDivider />
                  <TipCell>
                    <TipStatusIndicator status={tip.status} />
                  </TipCell>

                  <ActionsCell isMultiSelectActive={props.isMultiSelectActive}>
                    <CustomIcon
                      name="chevron-down"
                      size="20px"
                      title={t("CompanyTip.ShowDetails")}
                      color={Color.MidnightBlue}
                      onClick={() => {
                        props.toggleDropdown(tip.companyTipId);
                      }}
                      style={{
                        cursor: "pointer",
                        transition: "transform 0.2s",
                        transform: props.expandedTipIds.includes(
                          tip.companyTipId
                        )
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                    />
                    {props.isMultiSelectActive && shouldShowCheckmark(tip) && (
                      <CustomIcon
                        name="checkmark"
                        title={t("CompanyTip.Select")}
                        size="27px"
                        color={Color.White}
                        backgroundColor={
                          props.selectedTips.includes(tip.companyTipId)
                            ? Color.MidnightBlue
                            : Color.White
                        }
                        padding="2px"
                        onClick={() => {
                          props.toggleSelectTip(tip.companyTipId);
                        }}
                        style={{
                          border: `2px solid ${Color.MidnightBlue}`,
                          flexShrink: 0,
                          cursor: "pointer",
                        }}
                      />
                    )}
                    {!closedTip(tip) && (
                      <IconCircularContainer
                        onClick={() => props.handleDelete(tip.companyTipId)}
                      >
                        <CustomIcon
                          className="icon"
                          name="trashcan"
                          size="20px"
                          padding="2px"
                          color={Color.White}
                          title={t("CompanyTip.Remove")}
                        />
                      </IconCircularContainer>
                    )}
                  </ActionsCell>
                </TipCellContainer>

                <TipDetailsComponent
                  tip={tip}
                  tipState={props.tipState}
                  isExpanded={props.expandedTipIds.includes(tip.companyTipId)}
                  handleManualPayout={props.handleManualPayout}
                  getWorkerStatus={props.getWorkerStatus}
                  openDistributeModal={props.openDistributeModal}
                />
              </div>
            ))}
          </div>
        ))
      )}
    </>
  );
};
