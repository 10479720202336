import React, { useState, useEffect } from "react";
import { ChronoUnit, LocalDate, LocalTime } from "@js-joda/core";
import moment, { Moment } from "moment";
import "moment/locale/sv";
import { useTranslation } from "react-i18next";
import { ShiftRequestDto } from "model/Shift";
import { FontFamily, FontSize } from "config/font";
import { DateRangePicker, FocusedInputShape } from "react-dates";
import { ButtonStyled } from "components/buttons/buttons.styled";
import {
  LabelMargin,
  ShiftFormStyled,
  TimeInput,
  BreakInput,
  ShiftInputContainer,
  ErrorMessage,
  DatePickerContainer,
  MaxShiftError,
  ModalBody,
  BreakFormStyled,
  TextCenter,
} from "./shiftForm.styled";
import { CustomLink, Text } from "components/Typography/text.styled";
import i18n from "services/translation/i18n";
import { ModalButtonContainer } from "../../gig/gigs.styled";
import { Modal } from "@material-ui/core";
import { Color } from "config/colors";
import { Select } from "components/form/select/select.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import { calculateBreakTime, createBreakStrings } from "utils/utils";

type Props = {
  shifts: ShiftRequestDto[];
  onChange: (shifts: ShiftRequestDto[]) => void;
  multipleShiftsFormOpen: boolean;
  setMultipleShiftsFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  editGig: boolean;
};

export const MultipleShiftsForm: React.FC<Props> = ({
  shifts,
  onChange,
  multipleShiftsFormOpen,
  setMultipleShiftsFormOpen,
  editGig,
}) => {
  moment.locale(i18n.languages as string[]);
  const { t } = useTranslation();
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [breakTime, setBreakTime] = useState(0);
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    "startDate"
  );
  const [shiftDates, setShiftDates] = useState<Moment[]>([]);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showShiftBreakTimes, setShowShiftBreakTimes] = useState(false);
  const [breakStartTime, setBreakTimeStart] = useState("");
  const [breakEndTime, setBreakTimeEnd] = useState("");
  const [breakTimeMin, setBreakTimeMin] = useState(0);
  const [deduction, setDeduction] = useState(0);
  const today = LocalDate.now();
  const nowTime = LocalTime.now().truncatedTo(ChronoUnit.MINUTES);

  useEffect(() => {
    setStartTime(nowTime.plusHours(1).truncatedTo(ChronoUnit.HOURS).toString());
    setEndTime(nowTime.plusHours(2).truncatedTo(ChronoUnit.HOURS).toString());
    setBreakTimeStart(
      nowTime.plusHours(1).truncatedTo(ChronoUnit.HOURS).toString()
    );
    setBreakTimeEnd(
      nowTime.plusHours(1).truncatedTo(ChronoUnit.HOURS).toString()
    );
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      setShiftDates(enumerateDaysBetweenDates() || []);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (shiftDates.length > 0) {
      setBreakTime(
        calculateBreakTime(
          breakStartTime,
          breakEndTime,
          moment(moment(shiftDates[0]).format("YYYY-MM-DD") + " " + startTime),
          validateEndTime()
            ? moment(moment(shiftDates[0]).format("YYYY-MM-DD") + " " + endTime)
            : moment(
                moment(moment(shiftDates[0]).add(1, "days")).format(
                  "YYYY-MM-DD"
                ) +
                  " " +
                  endTime
              )
        )
      );
    }
  }, [breakStartTime, breakEndTime]);

  useEffect(() => {
    if (breakStartTime === breakEndTime) {
      setBreakTimeStart(startTime);
      setBreakTimeEnd(startTime);
    }
  }, [startTime, endTime]);

  const handlendDatesChange = (arg: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    setStartDate(arg.startDate);
    setEndDate(arg.endDate);
  };

  const enumerateDaysBetweenDates = () => {
    const dates = [];
    if (startDate && endDate) {
      let start = moment(startDate);
      while (
        moment(start).format("YYYY-MM-DD") <=
        moment(endDate).format("YYYY-MM-DD")
      ) {
        dates.push(moment(start));
        start = start.add(1, "days");
      }
      return dates;
    }
  };

  const handleSubmit = () => {
    if (validateShift()) {
      const newShifts = shiftDates.map((date) => {
        const breakStrings = createBreakStrings(
          startTime,
          endTime,
          breakStartTime,
          breakEndTime,
          date,
          validateEndTime() ? date : moment(date).add(1, "days")
        );
        return {
          startTime: new Date(
            `${moment(date).format("YYYY-MM-DD")}T${startTime}:00`
          ).toISOString(),
          endTime: validateEndTime()
            ? new Date(
                `${moment(date).format("YYYY-MM-DD")}T${endTime}:00`
              ).toISOString()
            : new Date(
                `${moment(date)
                  .add(1, "days")
                  .format("YYYY-MM-DD")}T${endTime}:00`
              ).toISOString(),
          ...(breakStartTime !== breakEndTime && {
            breakStartTime: breakStrings.breakStartString,
            breakEndTime: breakStrings.breakEndString,
          }),
          ...(breakTimeMin && {
            breakTime: breakTimeMin,
          }),
          deductionAmount: deduction
        };
      });
      onChange([...shifts, ...newShifts]);
    }
    setMultipleShiftsFormOpen(false);
  };

  const validateDate = () => {
    if (startDate) {
      //return today.toString() <= startDate.format("YYYY-MM-DD");
      return true;
    } else return true;
  };

  const validateStartTime = () => {
    if (startDate) {
      if (today.toString() === startDate.format("YYYY-MM-DD")) {
        return startTime >= nowTime.toString();
      } else {
        return true;
      }
    } else return true;
  };

  const validateEndTime = () => {
    if (startDate && endDate) {
      return endTime > startTime;
    } else return true;
  };

  const validateShift = () => {
    if (
      shiftDates.length > 0 &&
      startDate &&
      endDate &&
      startTime &&
      endTime &&
      typeof breakTime === "number" &&
      validateDate() &&
      validateBreakTime() &&
      validateStartTime() &&
      maxShifts()
    ) {
      return true;
    } else {
      return false;
    }
  };

  const validateBreakTime = () => {
    if (shiftDates.length > 0) {
      const startShift = new Date(
        `${shiftDates[0].format("YYYY-MM-DD")}T${startTime}:00`
      );
      const endShift = new Date(
        `${shiftDates[0].format("YYYY-MM-DD")}T${endTime}:00`
      );

      if (endShift < startShift) {
        endShift.setDate(endShift.getDate() + 1);
      }

      const startBreak = new Date(
        `${shiftDates[0].format("YYYY-MM-DD")}T${breakStartTime}:00`
      );

      if (startBreak < startShift) {
        startBreak.setDate(startBreak.getDate() + 1);
      }

      const endBreak = new Date(
        `${shiftDates[0].format("YYYY-MM-DD")}T${breakEndTime}:00`
      );

      if (breakEndTime < startTime) {
        endBreak.setDate(endBreak.getDate() + 1);
      }

      if (
        startBreak.getTime() === startShift.getTime() &&
        endBreak <= endShift
      ) {
        return true;
      }

      if (breakStartTime === breakEndTime) {
        return true;
      }

      if (startBreak < startShift || endBreak > endShift) {
        return false;
      }

      if (startBreak > endBreak) {
        return false;
      }

      if (breakTimeMin < 0) {
        return false;
      }

      return true;
    }
  };

  const maxShifts = () => {
    if (
      shiftDates.length +
        shifts.filter((shift) => shift.deleteEntry !== true).length >
      30
    ) {
      return false;
    } else return true;
  };

  const checkOverlapping = () => {
    const currentShifts = shifts.filter((shift) => !shift.deleteEntry);
    const overlapping = currentShifts.map((shift, index) => {
      const found = shiftDates.find((date) => {
        const newstart = new Date(
          `${moment(date).format("YYYY-MM-DD")}T${startTime}:00`
        );
        const newEnd = validateEndTime()
          ? new Date(`${moment(date).format("YYYY-MM-DD")}T${endTime}:00`)
          : new Date(
              `${moment(date)
                .add(1, "days")
                .format("YYYY-MM-DD")}T${endTime}:00`
            );
        return (
          (newstart >= new Date(shift.startTime) &&
            newstart <= new Date(shift.endTime)) ||
          (newEnd >= new Date(shift.startTime) &&
            newEnd <= new Date(shift.endTime))
        );
      });
      if (typeof found !== "undefined") {
        return found;
      }
    });

    if (
      overlapping.length > 0 &&
      typeof overlapping[overlapping.length - 1] !== "undefined"
    ) {
      setShowWarningModal(true);
    } else {
      handleSubmit();
    }
  };

  return (
    <div>
      <ShiftFormStyled open={multipleShiftsFormOpen}>
        <Text
          fontFamily={FontFamily.MontserratSemiBold}
          fontSize={FontSize.Large}
          align="center"
        >
          {t("GigsCompany.AddSeveralShifts")}
        </Text>
        <LabelMargin>{t("GigsCompany.Date")}</LabelMargin>
        <ShiftInputContainer>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
          >
            {t("GigsCompany.Date")}
          </Text>
          <DatePickerContainer>
            <DateRangePicker
              startDate={startDate}
              startDateId="startDate"
              endDate={endDate}
              endDateId="endDate"
              onDatesChange={handlendDatesChange}
              focusedInput={focusedInput}
              onFocusChange={(focusedInput: FocusedInputShape | null) =>
                setFocusedInput(focusedInput)
              }
              numberOfMonths={1}
              hideKeyboardShortcutsPanel={true}
              startDatePlaceholderText={t("GigsCompany.StartDate")}
              endDatePlaceholderText={t("GigsCompany.EndDate")}
              displayFormat="DD/MM/YYYY"
              firstDayOfWeek={1}
              isOutsideRange={() => false}
              isDayHighlighted={(day) =>
                day.format("DD/MM/YYYY") === moment().format("DD/MM/YYYY")
              }
            />
          </DatePickerContainer>

          {!validateDate() && (
            <ErrorMessage>{t("GigsCompany.DateHasPassed")}</ErrorMessage>
          )}
        </ShiftInputContainer>
        <LabelMargin>{t("GigsCompany.Time")}</LabelMargin>
        <ShiftInputContainer>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
          >
            {t("GigsCompany.ShiftStart")}
          </Text>
          <TimeInput
            fontFamily={FontFamily.MontserratBold}
            fontSize={FontSize.Large}
            name="startTime"
            type="time"
            value={startTime}
            onChange={(e) => {
              setStartTime(e.currentTarget.value);
            }}
          />
          {!validateStartTime() && (
            <ErrorMessage>{t("GigsCompany.EnterAValidTime")}</ErrorMessage>
          )}
        </ShiftInputContainer>

        <ShiftInputContainer>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
          >
            {t("GigsCompany.ShiftEnd")}
          </Text>
          <TimeInput
            fontFamily={FontFamily.MontserratBold}
            fontSize={FontSize.Large}
            name="endTime"
            type="time"
            required
            value={endTime}
            onChange={(e) => {
              setEndTime(e.currentTarget.value);
            }}
          />
        </ShiftInputContainer>

        {!showShiftBreakTimes && !editGig && (
          <>
            <ShiftInputContainer>
              <Text
                fontFamily={FontFamily.MontserratSemiBold}
                fontSize={FontSize.Large}
              >
                {t("GigsCompany.UnpaidBreak")}
              </Text>
              <BreakInput
                fontFamily={FontFamily.MontserratBold}
                fontSize={FontSize.Large}
                name="breakTime"
                type="number"
                value={breakTimeMin.toString()}
                onChange={(e) => {
                  setBreakTimeMin(parseInt(e.currentTarget.value));
                }}
                onBlur={(e) => {
                  !e.currentTarget.value.trim() && setBreakTime(0);
                }}
              />
            </ShiftInputContainer>
            <TextCenter>
              <CustomLink
                onClick={() => {
                  setShowShiftBreakTimes(true);
                  setBreakTimeMin(0);
                }}
                fontSize={FontSize.Small}
                color={Color.MidnightBlue}
              >
                {"(" + t("GigsCompany.ClickToEnterBreakTimeSpan") + ")"}
              </CustomLink>
            </TextCenter>
          </>
        )}
        {(showShiftBreakTimes || editGig) && (
          <>
            <Select style={{ height: 48 }}>
              <CustomIcon
                name="plus_old"
                size="48px"
                color={Color.MidnightBlue}
                padding="10px"
              />
              <Text
                fontFamily={FontFamily.MontserratSemiBold}
                fontSize={FontSize.Large}
                color={Color.MidnightBlue}
              >
                {`${t("GigsCompany.UnpaidBreak")}    ${breakTime} min`}
              </Text>
            </Select>
            <BreakFormStyled breakFormOpen={true}>
              <ShiftInputContainer style={{ marginTop: 10 }}>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  fontSize={FontSize.Large}
                  color={Color.White}
                >
                  {t("GigsCompany.StartTime")}
                </Text>
                <TimeInput
                  fontFamily={FontFamily.MontserratBold}
                  fontSize={FontSize.Large}
                  name="startTime"
                  type="time"
                  value={breakStartTime}
                  onChange={(e) => {
                    setBreakTimeStart(e.currentTarget.value);
                  }}
                />
                {!validateBreakTime() && shiftDates.length > 0 && (
                  <ErrorMessage>
                    {t("GigsCompany.EnterAValidTime")}
                  </ErrorMessage>
                )}
                {!validateBreakTime() && shiftDates.length == 0 && (
                  <ErrorMessage>{t("GigsCompany.EnterDates")}</ErrorMessage>
                )}
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  fontSize={FontSize.Large}
                  color={Color.White}
                >
                  {t("GigsCompany.EndTime")}
                </Text>
                <TimeInput
                  fontFamily={FontFamily.MontserratBold}
                  fontSize={FontSize.Large}
                  name="endTime"
                  type="time"
                  required
                  value={breakEndTime}
                  onChange={(e) => {
                    setBreakTimeEnd(e.currentTarget.value);
                  }}
                />
              </ShiftInputContainer>
            </BreakFormStyled>
            <TextCenter>
              <CustomLink
                onClick={() => {
                  setShowShiftBreakTimes(false);
                  setBreakTimeStart(startTime);
                  setBreakTimeEnd(startTime);
                }}
                fontSize={FontSize.Small}
                color={Color.MidnightBlue}
              >
                {"(" + t("GigsCompany.ClickToEnterMinutesBreak") + ")"}
              </CustomLink>
            </TextCenter>
          </>
        )}
        <ShiftInputContainer style={{ marginBottom: 0 }}>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
          >
            {t("GigsCompany.Deduction")}
          </Text>
          <BreakInput
            fontFamily={FontFamily.MontserratBold}
            fontSize={FontSize.Large}
            name="deduction"
            type="number"
            value={deduction.toString()}
            onChange={(e) => {
              setDeduction(parseInt(e.currentTarget.value) || 0);
            }}
            onBlur={(e) => {
              !e.currentTarget.value.trim() && setDeduction(0);
            }}
            maxLength={6}
          />
        </ShiftInputContainer>
        <Text
          fontSize={FontSize.Small}
          style={{ marginLeft: 20, marginTop: 0, marginBottom: 30 }}
        >
          {t("GigsCompany.DeductionInfo")}
        </Text>
        <ButtonStyled disabled={!validateShift()} onClick={checkOverlapping}>
          {t("GigsCompany.AddShifts")}
        </ButtonStyled>
        {!maxShifts() && (
          <MaxShiftError>{t("GigsCompany.ToManyShifts")}</MaxShiftError>
        )}
      </ShiftFormStyled>
      <Modal
        open={showWarningModal}
        onClose={() => {
          setShowWarningModal(false);
        }}
      >
        <ModalBody>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("GigsCompany.YourShiftsAreOverlappingMultiple")}
          </Text>
          <ModalButtonContainer>
            <ButtonStyled
              onClick={() => setShowWarningModal(false)}
              backgroundColor={Color.SeaBlue400}
            >
              {t("General.Abort")}
            </ButtonStyled>
            <ButtonStyled
              onClick={() => {
                setShowWarningModal(false);
                handleSubmit();
              }}
            >
              {t("General.Continue")}
            </ButtonStyled>
          </ModalButtonContainer>
        </ModalBody>
      </Modal>
    </div>
  );
};
