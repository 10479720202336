import React, { useEffect, useState } from "react";
import { FontFamily, FontSize } from "config/font";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Color } from "config/colors";
import { AlertModal } from "./alertModal";
import "react-tooltip/dist/react-tooltip.css";
import { formatNumber, parseWorkTime } from "utils/utils";
import { WorkGroup, WorkGroupPayload } from "model/Tip";
import { Text, H3, H4 } from "components/Typography/text.styled";
import { LabelStyled } from "components/form/inputs/inputs.styled";
import { useTipStore } from "web-apps/company/stores/tipStore/tipStore";
import {
  TipInput,
  GridCell,
  WorkerCell,
  TipHeadCell5,
  AnimatedText,
  WorkGroupGrid,
  TipButtonStyled,
  WorkerContainer,
  DetailsContainer,
  SpaceBtwItemsCenter,
} from "../tips.styled";

type WorkgroupTabProps = {
  onClose: () => void;
  companyId: number;
  workgroups: WorkGroup[];
  selectedTipIds: number[];
  onUpdateRemainingAmount: (updatedAmounts: { [date: string]: number }) => void;
};

export const WorkgroupTab: React.FC<WorkgroupTabProps> = (props) => {
  const { t } = useTranslation();
  const [tipState, tipActions] = useTipStore();
  const [isFading, setIsFading] = useState(false);
  const [workerTips, setWorkerTips] = useState<
    Record<string, Record<string, number>>
  >({});
  const [workgroupValues, setWorkgroupValues] = useState<
    Record<number, string>
  >({});
  const [allPercentagesFilled, setAllPercentagesFilled] = useState(false);
  const [distributionConfirmed, setDistributionConfirmed] = useState(false);

  const totalAmountsPerDay = tipState.calculationResult.companyTips?.reduce(
    (acc, tip) => {
      acc[tip.date] = tip.amount;
      return acc;
    },
    {} as Record<string, number>
  );

  const totalPercentage = Object.values(workgroupValues).reduce(
    (sum, val) => sum + (parseFloat(val) || 0),
    0
  );

  useEffect(() => {
    setAllPercentagesFilled(totalPercentage === 100);
  }, [totalPercentage]);

  // useEffect(() => {
  //   if (!calculationResult) return;

  //   const initialWorkerTips: Record<string, Record<string, number>> = {};

  //   calculationResult.companyTips?.forEach((tip) => {
  //     if (!initialWorkerTips[tip.date]) {
  //       initialWorkerTips[tip.date] = {};
  //     }

  //     tip.workers.forEach((worker: Worker) => {
  //       const uniqueWorkerKey = `${worker.workerId}-${worker.workGroupId}`;

  //       initialWorkerTips[tip.date][uniqueWorkerKey] = worker.tipAmount;
  //     });
  //   });

  //   setWorkerTips(initialWorkerTips);
  // }, [calculationResult]);

  const handlePercentageChange = (workGroupId: number, value: string) => {
    if (!/^\d*$/.test(value)) return;
    setWorkgroupValues((prev) => ({
      ...prev,
      [workGroupId]: value,
    }));
  };

  const handleSendPercentage = () => {
    const updatedWorkGroups: WorkGroupPayload[] = props.workgroups.map(
      (group) => ({
        id: group.workGroupId,
        percentage: parseFloat(workgroupValues[group.workGroupId] || "0"),
      })
    );

    tipActions.calculateTips(
      props.companyId,
      props.selectedTipIds,
      updatedWorkGroups
    );
    setDistributionConfirmed(true);
  };

  const getCurrentRemainingColor = () =>
    totalPercentage === 100 ? Color.ModernGreen : Color.MidnightBlue;

  return (
    <AlertModal>
      {(openAlertModal) => {
        const handleSaveDistribution = () => {
          tipActions.saveTipDistribution(
            props.companyId,
            tipState.calculationResult.companyTips
          );

          openAlertModal(t("CompanyTip.TipSaved"), false, () => {
            props.onClose();

            const newRemainingAmounts =
              tipState.calculationResult.companyTips.reduce((acc, tip) => {
                acc[tip.date] = tip.remainingAmount;
                return acc;
              }, {} as { [date: string]: number });

            props.onUpdateRemainingAmount(newRemainingAmounts);
          });
        };

        return (
          <div>
            <SpaceBtwItemsCenter>
              <H3>{t("CompanyTip.DistributePerWorkgroup")}</H3>
              <AnimatedText
                fontFamily={FontFamily.LeagueSpartanBold}
                isFading={isFading}
                style={{
                  color: getCurrentRemainingColor(),
                }}
              >
                <span
                  style={{
                    color: getCurrentRemainingColor(),
                    transition: "color 0.3s ease",
                  }}
                >
                  {t("CompanyTip.Distributed")} {totalPercentage}
                </span>
                /100%
              </AnimatedText>
            </SpaceBtwItemsCenter>
            <LabelStyled>{t("CompanyTip.WorkgroupsHelpText")}</LabelStyled>
            {props.workgroups.length === 0 ? (
              <Text>{t("CompanyTip.NoWorkgroups")}</Text>
            ) : (
              <WorkGroupGrid>
                {props.workgroups.map((group) => (
                  <GridCell key={group.workGroupId}>
                    <H4 style={{ marginTop: "0" }}>{group.workGroupName}</H4>
                    <TipInput
                      style={{ width: "100px" }}
                      type="text"
                      placeholder="%"
                      maxLength={3}
                      value={workgroupValues[group.workGroupId] || ""}
                      onChange={(e) =>
                        handlePercentageChange(
                          group.workGroupId,
                          e.target.value
                        )
                      }
                    />
                  </GridCell>
                ))}
              </WorkGroupGrid>
            )}

            <TipButtonStyled
              onClick={handleSendPercentage}
              disabled={!allPercentagesFilled}
              backgroundColor={Color.ModernGreen}
            >
              {t("CompanyTip.Next")}
            </TipButtonStyled>

            {distributionConfirmed && (
              <>
                <H3> {t("CompanyTip.CalculatedTips")}</H3>
                {tipState.calculationResult.companyTips.map((tip) => (
                  <div key={tip.companyTipId}>
                    <TipHeadCell5>
                      <Text>
                        {moment(tip.date).format("ddd D/M").toUpperCase()}
                      </Text>
                      <Text>
                        {t("CompanyTip.TotalTip")}{" "}
                        {formatNumber(totalAmountsPerDay[tip.date])}{" "}
                        {t("CompanyTip.Kr")}
                      </Text>
                    </TipHeadCell5>
                    {tip.workGroups?.map((group) => (
                      <DetailsContainer
                        key={group.workGroupId}
                        style={{ marginBottom: "10px" }}
                      >
                        <DetailsContainer>
                          <SpaceBtwItemsCenter
                            style={{
                              backgroundColor: Color.SeaBlue200,
                              paddingLeft: "6px",
                              paddingRight: "6px",
                            }}
                          >
                            <Text
                              style={{ margin: "0" }}
                              fontSize={FontSize.Large}
                              fontFamily={FontFamily.MontserratSemiBold}
                            >
                              {group.workGroupName} {group.percentage}%
                            </Text>
                            <Text
                              fontSize={FontSize.Large}
                              fontFamily={FontFamily.MontserratSemiBold}
                              style={{ margin: "10px", marginRight: "0" }}
                            >
                              {formatNumber(group.amount)} {t("CompanyTip.Kr")}
                            </Text>
                          </SpaceBtwItemsCenter>

                          {tip.workers
                            .filter(
                              (worker) =>
                                worker.workGroupId === group.workGroupId
                            )
                            .map((worker) => {
                              const uniqueWorkerKey = `${worker.workerId}-${worker.workGroupId}`;
                              return (
                                <WorkerContainer
                                  key={`${worker.workerId}-${worker.workGroupId}`}
                                  style={{
                                    paddingLeft: "6px",
                                    paddingRight: "6px",
                                  }}
                                >
                                  <WorkerCell>
                                    <Text>
                                      {worker.firstName} {worker.lastName}
                                    </Text>
                                  </WorkerCell>
                                  <WorkerCell
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Text>
                                      {parseWorkTime(worker.workTime).hours}
                                      {t("General.HourShortened")}{" "}
                                      {parseWorkTime(worker.workTime).minutes}
                                      {t("General.MinutesShortened")}
                                    </Text>
                                  </WorkerCell>
                                  <WorkerCell
                                    style={{
                                      display: "flex",
                                      alignItems: "end",
                                    }}
                                  >
                                    <Text>
                                      {workerTips[tip.date]?.[
                                        uniqueWorkerKey
                                      ] ?? formatNumber(worker.tipAmount)}
                                      {t("CompanyTip.Kr")}
                                    </Text>
                                  </WorkerCell>
                                </WorkerContainer>
                              );
                            })}
                        </DetailsContainer>
                      </DetailsContainer>
                    ))}
                  </div>
                ))}

                <TipButtonStyled
                  onClick={handleSaveDistribution}
                  backgroundColor={Color.ModernGreen}
                >
                  {t("CompanyTip.SaveDistribution")}
                </TipButtonStyled>
              </>
            )}
          </div>
        );
      }}
    </AlertModal>
  );
};
