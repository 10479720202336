import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import { H3, Text } from "components/Typography/text.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import {
  ButtonContainer,
  CalendarHeaderGrid,
  GigHeader,
  ModalBody,
  TitleRow,
  WeekDay,
  ViewContainer,
  CircularProgressWrapper,
  Row,
  CalenderWeekAndDatesContainer,
  IconContainer,
  CreateGigModalBody,
} from "./weeklyCalendar.styled";
import moment from "moment";
import { CompanyGigDto } from "model/Gig";
import { ShiftDto } from "model/Shift";
import { ApplicationDto } from "model/Application";
import { TimeReportDto } from "model/TimeReport";
import { CircularProgress, Modal } from "@material-ui/core";
import { SaveGigForm } from "../saveGig/saveGigForm/saveGigForm.component";
import { useCompanyStore } from "../../stores/companyStore/companyStore";
import { Api } from "services/api/api.service";
import { useCalendarStore } from "web-apps/company/stores/calendarStore/calendarStore";
import { addLeadingZero } from "utils/utils";
import { CalendarTemplatePicker } from "./template/calendarTemplatePicker.component";
import { DayShiftsModal } from "./dayShiftsModal/dayShiftsModal.component";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { useApplicationStore } from "web-apps/company/stores/applicationStore/applicationStore";
import { FilterCalendar } from "./filterCalendar.component";
import { Filter, FilterType, GroupType } from "model/Calendar";
import { WeeklyCalendarBlock } from "./calendarGig/weeklyCalendarBlock.component";
import { GigSummaryModal } from "../gigDetail/gigSummaryModal.component";

export type ChosenTimeReportWithWorker = TimeReportDto & {
  requestedRate: number | undefined;
  duration: string;
  worker: {
    id: number;
    firstName?: string | null;
    lastName?: string | null;
  };
};

export type WeekData = {
  gig: CompanyGigDto;
  applications: ApplicationDto[];
  timeReports: TimeReportDto[];
  shifts: ShiftDto[];
};

type CurrentDate = {
  date: moment.Moment;
  weekDay: string;
};

export const WeeklyCalendarComponent: React.FC = () => {
  const [companyState] = useCompanyStore();
  const [, calendarActions, calendarDispatch] = useCalendarStore();

  const { t } = useTranslation();
  const weekdayList = [
    t("WeekDays.MONDAYSHORT"),
    t("WeekDays.TUESDAYSHORT"),
    t("WeekDays.WEDNESDAYSHORT"),
    t("WeekDays.THURSDAYSHORT"),
    t("WeekDays.FRIDAYSHORT"),
    t("WeekDays.SATURDAYSHORT"),
    t("WeekDays.SUNDAYSHORT"),
  ];
  const today = moment();
  const todayWeekNumber = moment().week();
  const [currentDate, setCurrentDate] = useState(
    localStorage.getItem("CalendarDate")
      ? moment(localStorage.getItem("CalendarDate"))
      : today
  );
  const todayWeekday = currentDate.day();
  const [weekNumber, setWeekNumber] = useState(currentDate.week());
  const [firstDayOfWeek, setFirstDayOfWeek] = useState(
    moment(moment(currentDate).subtract(todayWeekday - 1, "days"))
  );
  const [currentDates, setCurrentDates] = useState<CurrentDate[]>([]);
  const [showCreateGigModal, setShowCreateGigModal] = useState(false);
  const [clickedDay, setClickedDay] = useState("");
  const [compactView, setCompactView] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDayShiftsModal, setShowDayShiftsModal] = useState(false);
  const [applicationState] = useApplicationStore();
  const [filter, setFilter] = useState<Filter[]>([
    {
      filterId: -1,
      groupType: GroupType.NONE,
      filterType: FilterType.NONE,
    },
  ]);

  useEffect(() => {
    fetchCalendarGigs();
  }, [currentDates, applicationState.applications, filter]);

  const fetchCalendarGigs = () => {
    if (
      companyState.company?.id &&
      currentDates.length > 0 &&
      filter !== undefined &&
      createDateNumbers().start &&
      createDateNumbers().end
    ) {
      Api()
        .company.calendar.getCalendarGigsV2(
          companyState.company.id,
          filter[0]?.filterId,
          filter[0]?.filterType,
          filter[0]?.groupType,
          createDateNumbers().start,
          createDateNumbers().end
        )
        .then((res) => {
          setIsLoading(false);
          calendarDispatch({
            type: "UPDATE_WEEKLY_CALENDAR_V2",
            payload: (res.data as any).items,
          });
        })
        .catch((err) => {
          setIsLoading(false);

          console.log("ERROR FETCHING CALENDAR GIGS", err);
        });
    }
  };

  const createDateNumbers = () => {
    const firstDate = currentDates[0].date;
    const lastDate = currentDates[6].date;

    const start = `${firstDate.year()}${addLeadingZero(
      firstDate.month() + 1
    )}${addLeadingZero(firstDate.date())}`;
    const end = `${lastDate.year()}${addLeadingZero(
      lastDate.month() + 1
    )}${addLeadingZero(lastDate.date())}`;
    return { start: parseInt(start), end: parseInt(end) };
  };

  const handleClickDay = (day: CurrentDate) => {
    setClickedDay(day.date.format("YYYY-MM-DD"));
    setShowDayShiftsModal(true);
  };

  useEffect(() => {
    localStorage.setItem(
      "CalendarDate",
      moment(currentDate).format("YYYY-MM-DD")
    );
    setWeekNumber(currentDate.week());
    setFirstDayOfWeek(
      moment(moment(currentDate).subtract(todayWeekday - 1, "days"))
    );
  }, [currentDate]);

  useEffect(() => {
    let dates: CurrentDate[] = [];
    for (let x = 0; x < 7; x++) {
      dates.push({
        date: moment(moment(firstDayOfWeek).add(x, "days")),
        weekDay: weekdayList[x],
      });
    }
    setCurrentDates(dates);
  }, [firstDayOfWeek]);

  const resetStateVariables = () => {
    setCurrentDates([]);
  };
  const clickPrevWeek = () => {
    setCurrentDate(moment(currentDate.subtract(7, "days")));
    resetStateVariables();
  };

  const clickNextWeek = () => {
    setCurrentDate(moment(currentDate.add(7, "days")));
    resetStateVariables();
  };

  const clickToday = () => {
    setCurrentDate(moment(today));
  };

  return (
    <>
      <TitleRow>
        <CustomIcon
          name="chevron-left"
          size="50px"
          color={Color.MidnightBlue}
          padding="10px"
          onClick={() => {
            clickPrevWeek();
          }}
        />
        <CalenderWeekAndDatesContainer>
          <Text
            align="center"
            fontSize={FontSize.H4}
            color={Color.MidnightBlue}
            fontFamily={FontFamily.MontserratSemiBold}
          >{`${t("CalendarCompany.Week")} ${weekNumber}`}</Text>

          <Text
            align="center"
            fontSize={FontSize.Standard}
            color={Color.MidnightBlue}
            fontFamily={FontFamily.MontserratRegular}
          >{`${moment(firstDayOfWeek).format("DD MMM")} - ${moment(
            moment(firstDayOfWeek).add(6, "days")
          ).format("DD MMM")}   ${moment(firstDayOfWeek).format("YYYY")} ${
            moment(moment(firstDayOfWeek).add(6, "days")).format("YYYY") !==
            moment(firstDayOfWeek).format("YYYY")
              ? "/" + moment(firstDayOfWeek).add(6, "days").format("YYYY")
              : ""
          }`}</Text>
        </CalenderWeekAndDatesContainer>
        <CustomIcon
          name="chevron-right"
          size="50px"
          color={Color.MidnightBlue}
          padding="10px"
          onClick={() => {
            clickNextWeek();
          }}
        />
      </TitleRow>
      <ViewContainer>
        <Row>
          <IconContainer>
            <CustomIcon
              onClick={() => setCompactView(!compactView)}
              name="list"
              size="28px"
              color={Color.MidnightBlue}
            />
          </IconContainer>
          <IconContainer>
            <CustomIcon
              onClick={
                todayWeekNumber === weekNumber &&
                today.format("YYYY") === currentDate.format("YYYY")
                  ? undefined
                  : clickToday
              }
              name="day"
              size="28px"
              color={
                todayWeekNumber === weekNumber &&
                today.format("YYYY") === currentDate.format("YYYY")
                  ? Color.Disabled
                  : Color.MidnightBlue
              }
            />
          </IconContainer>
        </Row>
        <CalendarTemplatePicker
          startDate={firstDayOfWeek.toDate()}
          refetchCalendarGigs={fetchCalendarGigs}
          currentDates={currentDates}
        />
      </ViewContainer>
      <FilterCalendar setFilter={setFilter} />
      <CalendarHeaderGrid>
        <GigHeader />
        {currentDates.map((date: CurrentDate) => (
          <WeekDay
            key={date.weekDay}
            future={
              date.date >= moment().set({ hour: 0, minutes: 0, seconds: 0 })
                ? true
                : false
            }
            today={
              date.date.format("DD-MM-YYYY") === today.format("DD-MM-YYYY")
                ? true
                : false
            }
            onClick={() => handleClickDay(date)}
          >
            <Text
              fontSize={FontSize.H4}
              color={Color.MidnightBlue}
              fontFamily={FontFamily.MontserratSemiBold}
            >
              {date.weekDay}
            </Text>
            <Text fontSize={FontSize.Small} color={Color.MidnightBlue}>
              {moment(date.date).format("D/M")}
            </Text>
          </WeekDay>
        ))}
      </CalendarHeaderGrid>
      {!isLoading ? (
        <WeeklyCalendarBlock compactView={compactView} filter={filter} />
      ) : (
        <CircularProgressWrapper>
          <CircularProgress />
        </CircularProgressWrapper>
      )}

      {showDayShiftsModal && (
        <DayShiftsModal
          setModalOpen={setShowDayShiftsModal}
          modalOpen={showDayShiftsModal}
          day={clickedDay}
        />
      )}
    </>
  );
};
