import {
  ChronoUnit,
  DateTimeFormatter,
  Instant,
  LocalDateTime,
} from "@js-joda/core";
import { TimeReportDto } from "model/TimeReport";
import { TFunction } from "react-i18next";

export const dateToUtcString = (date: Date): string => {
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:00.000Z`;
};

export const getDifferenceFromDates = (date1: Date, date2: Date): number => {
  const differenceInMs = date2.getTime() - date1.getTime();

  const differenceInMinutes = Math.floor(differenceInMs / (1000 * 60));

  return differenceInMinutes;
};

export const createStartAndEndDateStrings = (
  startTime: LocalDateTime,
  startTimeStr: string,
  endTimeStr: string
): { localStartDateStr: string; localEndDateStr: string } => {
  const formatter = DateTimeFormatter.ofPattern("yyyy-MM-dd'T'");
  const formattedStartDate = startTime.format(formatter);

  const localStartDateStr = `${formattedStartDate}${startTimeStr}`;
  const localEndDateStr = createEndDateString(localStartDateStr, endTimeStr);

  return { localStartDateStr, localEndDateStr };
};

export const createEndDateString = (
  startDate: string,
  endTime: string
): string => {
  try {
    if (!endTime || !endTime.includes(":") || endTime.length < 5) {
      const startDateTime = new Date(startDate);
      startDateTime.setHours(0, 0, 0, 0);
      return LocalDateTime.ofInstant(Instant.parse(startDateTime.toISOString()))
        .truncatedTo(ChronoUnit.MINUTES)
        .toString();
    }

    const startDateTime = new Date(startDate);

    const parts = endTime.split(":");
    const endHours = parseInt(parts[0]) || 0;
    const endMinutes = parseInt(parts[1]) || 0;

    const endDateTime = new Date(startDateTime);
    endDateTime.setHours(endHours, endMinutes, 0, 0);

    if (endDateTime <= startDateTime) {
      endDateTime.setDate(endDateTime.getDate() + 1);
    }

    const endDateIsoString = endDateTime.toISOString();

    return LocalDateTime.ofInstant(Instant.parse(endDateIsoString))
      .truncatedTo(ChronoUnit.MINUTES)
      .toString();
  } catch (error) {
    console.error("Error creating end date string:", error);
    return startDate;
  }
};

export const createBreakFullDates = (
  localStartDate: string,
  breakStartTimeStr: string,
  breakEndTimeStr: string
) => {
  const startDateTime = new Date(localStartDate);

  const [breakStartHours, breakStartMinutes] = breakStartTimeStr
    .split(":")
    .map(Number);
  const [breakEndHours, breakEndMinutes] = breakEndTimeStr
    .split(":")
    .map(Number);

  const breakStartDate = new Date(startDateTime);
  breakStartDate.setHours(breakStartHours, breakStartMinutes, 0, 0);

  const breakEndDate = new Date(startDateTime);
  breakEndDate.setHours(breakEndHours, breakEndMinutes, 0, 0);

  // Adjust break start and end dates if they are past midnight
  if (breakStartDate < startDateTime) {
    breakStartDate.setDate(breakStartDate.getDate() + 1);
  }
  if (breakEndDate < breakStartDate) {
    breakEndDate.setDate(breakEndDate.getDate() + 1);
  }

  return { breakStartDate, breakEndDate };
};

export const isBreakInsideShift = (
  localStartDateStr: string,
  localEndDateStr: string,
  breakStartDate: Date,
  breakEndDate: Date
): boolean => {
  const startDateTime = new Date(localStartDateStr);
  const endDateTime = new Date(localEndDateStr);

  const notNegativeBreak = breakEndDate >= breakStartDate;

  return (
    notNegativeBreak &&
    breakStartDate >= startDateTime &&
    breakEndDate <= endDateTime
  );
};

export const calculateTotalTimeString = (
  localStartDateStr: string,
  localEndDateStr: string,
  breakMinutes: number,
  t: TFunction
): string => {
  const totalMinutes =
    getDifferenceFromDates(
      new Date(localStartDateStr),
      new Date(localEndDateStr)
    ) - breakMinutes;

  if (totalMinutes < 0) return "N/A";

  const workMinutes = totalMinutes % 60;
  const workHours = Math.floor(totalMinutes / 60);

  const totalTimeStr = `${workHours + t("General.HourShortened")} ${
    workMinutes + t("General.MinutesShortened")
  }`;

  return totalTimeStr;
};

export const createEditedTimeReport = (
  timeReport: TimeReportDto,
  startTime: LocalDateTime,
  startTimeStr: string,
  endTimeStr: string,
  breakTime: string,
  isEdited: boolean,
  breakStartDate?: Date,
  breakEndDate?: Date
): TimeReportDto => {
  const formatter = DateTimeFormatter.ofPattern("yyyy-MM-dd'T'");
  const formattedStartDate = startTime.format(formatter);

  const startShiftUtcString = dateToUtcString(
    new Date(formattedStartDate + startTimeStr)
  );

  const endDate =
    endTimeStr > startTimeStr
      ? formattedStartDate
      : startTime.plusDays(1).format(formatter);

  const endShiftUtcString = dateToUtcString(new Date(endDate + endTimeStr));

  const breakStartUtcString = breakStartDate && dateToUtcString(breakStartDate);
  const breakEndUtcString = breakEndDate && dateToUtcString(breakEndDate);

  return {
    ...timeReport,
    startTime: startShiftUtcString,
    endTime: endShiftUtcString,
    breakStartTime: breakStartUtcString,
    breakEndTime: breakEndUtcString,
    breakTime: breakTime,
    isEditedByCompany: isEdited,
  };
};

export const toLocalString = (dateTime: LocalDateTime) =>
  dateTime.toLocalTime().truncatedTo(ChronoUnit.MINUTES).toString();
