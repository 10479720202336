import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { NumberInput, LabelStyled } from "components/form/inputs/inputs.styled";
import { Text } from "components/Typography/text.styled";
import {
  HourlyRateContainer,
  NumberInputContainer,
  FeesContainer,
  RowWrapper,
  InfoCircle,
} from "./hourlyRate.styled";
import { InputContainer } from "components/form/inputs/inputs.styled";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { ShiftRequestDto } from "model/Shift";
import { GigCosts, GigType, PaymentMethod } from "model/Gig";
import { getCosts } from "web-apps/company/utils/utils";

type Props = {
  minHourlyRate: number;
  maxHourlyRate: number;
  onChange: (min: number, max: number) => void;
  hours: number;
  shifts: ShiftRequestDto[];
  gigType: GigType | undefined;
  paymentType: PaymentMethod | undefined;
};

const emptyCosts = {
  grossSalary: 0,
  obAmount: 0,
  socialFee: 0,
  simflexityFee: 0,
  totalCost: 0,
};

export const HourlyRate: React.FC<Props> = ({
  minHourlyRate,
  maxHourlyRate,
  onChange,
  hours,
  shifts,
  gigType,
  paymentType,
}) => {
  const { t } = useTranslation();
  const [showFeeInfo, setShowFeeInfo] = useState(false);
  const [companyState] = useCompanyStore();
  const [gigMinCost, setGigMinCost] = useState<GigCosts>(emptyCosts);
  const [gigMaxCost, setGigMaxCost] = useState<GigCosts>(emptyCosts);
  const totalDeduction = shifts.reduce(
    (sum, shift) =>
      sum + (shift.deductionAmount !== undefined ? shift.deductionAmount : 0),
    0
  );

  const validateHourlyRate = () => {
    return maxHourlyRate >= minHourlyRate;
  };

  useEffect(() => {
    if (companyState.company?.id) {
      getCosts(
        shifts,
        minHourlyRate,
        maxHourlyRate,
        gigType,
        companyState.company.id,
        paymentType
      ).then((costs) => {
        setGigMinCost(costs.minCost);
        setGigMaxCost(costs.maxCost);
      });
    }
  }, [minHourlyRate, maxHourlyRate, shifts, paymentType]);
  return (
    <InputContainer>
      <LabelStyled>{t("GigsCompany.HourlyRate")}</LabelStyled>
      <HourlyRateContainer>
        <Text fontSize={FontSize.Large} color={Color.SeaBlue600}>
          {t("GigsCompany.SelectASpan")}
        </Text>
        <NumberInputContainer>
          <NumberInput
            type="number"
            name="minRate"
            min={0}
            value={minHourlyRate.toString()}
            onChange={(e) => {
              onChange(parseInt(e.currentTarget.value) || 0, maxHourlyRate);
            }}
          />
          <Text
            color={Color.SeaBlue600}
            fontSize={FontSize.H4}
            style={{ margin: "0px 5px" }}
          >
            -
          </Text>
          <NumberInput
            type="number"
            name="maxRate"
            min={minHourlyRate}
            value={maxHourlyRate.toString()}
            onChange={(e) => {
              onChange(minHourlyRate, parseInt(e.currentTarget.value) || 0);
            }}
          />
        </NumberInputContainer>
      </HourlyRateContainer>
      {!validateHourlyRate() && (
        <Text fontSize={FontSize.Small} color={Color.Destructive}>
          {t("GigsCompany.MaxRateMustBeHigherThanMinRate")}
        </Text>
      )}

      <RowWrapper>
        <FeesContainer>
          <RowWrapper style={{ justifyContent: "flex-start" }}>
            <LabelStyled>{t("GigsCompany.EstimatedCost")}</LabelStyled>
            <InfoCircle
              onMouseEnter={() => setShowFeeInfo(true)}
              onMouseLeave={() => setShowFeeInfo(false)}
            >
              <Text
                fontFamily={FontFamily.LeagueSpartanBold}
                color={Color.White}
                padding
                fontSize={FontSize.Small}
              >
                i
              </Text>
            </InfoCircle>
          </RowWrapper>
          {showFeeInfo && (
            <Text fontSize={FontSize.Small}>{t("GigsCompany.FeeInfo")}</Text>
          )}
          {hours ? (
            <div style={{ display: "inline-block" }}>
              <RowWrapper>
                <Text fontFamily={FontFamily.MontserratSemiBold}>
                  {t("GigsCompany.GrossSalary")}:
                </Text>
                <Text>
                  {(minHourlyRate * hours).toFixed(2)} -{" "}
                  {(maxHourlyRate * hours).toFixed(2)} kr
                </Text>
              </RowWrapper>
              <RowWrapper>
                <Text fontFamily={FontFamily.MontserratSemiBold}>
                  {t("ProfileCompany.OB")}:
                </Text>

                <Text>
                  {gigMinCost?.obAmount} - {gigMaxCost?.obAmount} kr
                </Text>
              </RowWrapper>
              <RowWrapper>
                <Text fontFamily={FontFamily.MontserratSemiBold}>
                  {t("GigsCompany.SocialFees")}:
                </Text>
                <Text>
                  {(
                    (gigMinCost?.socialFee ? gigMinCost.socialFee : 0) * 0.3142
                  ).toFixed(2)}
                  -
                  {(
                    (gigMaxCost?.socialFee ? gigMaxCost.socialFee : 0) * 0.3142
                  ).toFixed(2)}
                  kr
                </Text>
              </RowWrapper>
              {paymentType === PaymentMethod.SIMFLEXITY_PAY && (
                <RowWrapper>
                  <Text fontFamily={FontFamily.MontserratSemiBold}>
                    {t("GigsCompany.SimflexityFee")}:
                  </Text>

                  <Text>
                    {gigMinCost?.simflexityFee} - {gigMaxCost?.simflexityFee} kr
                  </Text>
                </RowWrapper>
              )}
              {totalDeduction !== 0 && totalDeduction !== undefined && (
                <RowWrapper>
                  <Text fontFamily={FontFamily.MontserratSemiBold}>
                    {t("GigsCompany.Deduction")}:
                  </Text>
                  <Text>{totalDeduction + " kr"}</Text>
                </RowWrapper>
              )}
              <RowWrapper>
                <Text fontFamily={FontFamily.MontserratSemiBold}>
                  {t("GigsCompany.TotalCost")}:
                </Text>
                <Text>
                  {gigMinCost?.totalCost.toFixed(2)} -{" "}
                  {gigMaxCost?.totalCost.toFixed(2)} kr
                </Text>
              </RowWrapper>
              <Text
                color={Color.SeaBlue600}
                fontSize={FontSize.Small}
                style={{ width: "200px", lineHeight: "12px" }}
              >
                {t("GigsCompany.FinalFee")}
              </Text>
            </div>
          ) : (
            <Text color={Color.SeaBlue600}>
              {t("GigsCompany.AddShiftToSeeEstimatedCost")}
            </Text>
          )}
        </FeesContainer>
      </RowWrapper>
    </InputContainer>
  );
};
