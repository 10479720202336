import { Text } from "components/Typography/text.styled";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import {
  Cell,
  Container,
  DeductionAmountContainer,
  DividerLine1,
  DividerLine2,
  DividerLine3,
  EmptyDiv,
  GrossSalaryContainer,
  HoursContainer,
  Logo,
  LogoContainer,
  NetSalaryContainer,
  PayrollTaxContainer,
  SalaryActionContainer,
  SalaryNoticeContainer,
  SalaryPageContainer,
  TaxContainer,
  WorkerContainer,
} from "./salary.styled";
import { FontFamily, FontSize } from "config/font";
import { SalaryNotice } from "model/Salary";
import { Color } from "config/colors";
import { IconCircularContainer } from "../dashboard/dashboard.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import { formatDuration } from "utils/utils";
import { WorkerDropdown } from "./workerDropDown.component";
import { Worker } from "model/StaffingPool";
import { Api } from "services/api/api.service";
import { useDownloadPDF } from "hooks/useDownloadPDF";
import { Dropdown } from "components/dropdown/Dropdown";
import { FilterContainer } from "../gig/gigs.styled";
import { SummaryIndividualSalary } from "./summaryIndividualSalary.component";
import { PaymentMethod } from "model/Gig";
import SimflexityPayLogo from "images/logo/svg/simflexityPay-logo.svg";

export const IndividualSalary = () => {
  const { t } = useTranslation();
  const [companyState] = useCompanyStore();
  const [salaryNotices, setSalaryNotices] = useState<SalaryNotice[]>([]);
  const [selectedWorker, setSelectedWorker] = useState<Worker>();
  const { fetchPdf } = useDownloadPDF();
  const [selectedYear, setSelectedYear] = useState<number>();

  const getSalaries = useCallback(() => {
    if (companyState.company?.id && selectedWorker) {
      Api()
        .company.salary.getWorkerSalaries(
          companyState.company.id,
          selectedWorker.workerId,
          selectedYear ?? undefined
        )
        .then((res) => {
          console.log(res.data);
          setSalaryNotices(res.data.salaryNotices);
        })
        .catch((e) => {
          console.error("Error when getting salaries", e);
        });
    }
  }, [selectedWorker, selectedYear]);

  const handleDownloadSalaryPdf = (
    workerId: number,
    period: string,
    workerName: string,
    pdfId: number
  ) => {
    if (!companyState.company) return;

    fetchPdf(
      Api().company.salary.generateSalaryNotice(
        companyState.company.id,
        workerId,
        pdfId
      ),
      `SalaryNotice_${period}_${workerName}.pdf`
    );
  };

  useEffect(() => {
    getSalaries();
  }, [getSalaries, selectedYear]);

  const formatTimePeriod = (timePeriod: string) => {
    const year = timePeriod.slice(0, 4);
    const month = timePeriod.slice(4, 6);

    return `${year}-${month}`;
  };

  return (
    <>
      <FilterContainer>
        <WorkerDropdown
          selectedWorker={selectedWorker}
          setSelectedWorker={setSelectedWorker}
        />
        <Container style={{ marginLeft: 20 }}>
          <Dropdown
            isCalendar
            textColor={Color.MidnightBlue}
            smallerFont
            placeholder={t("Salary.Year")}
            activeValue={selectedYear ? selectedYear : undefined}
            items={[
              { value: "", label: t("Salary.Year") },
              { value: 2025, label: "2025" },
              { value: 2024, label: "2024" },
              { value: 2023, label: "2023" },
              { value: 2022, label: "2022" },
              { value: 2021, label: "2021" },
            ]}
            maxHeight={200}
            onChange={(e: any) => {
              setSelectedYear(e.value);
            }}
          />
        </Container>
      </FilterContainer>
      <SalaryPageContainer style={{ marginBottom: 80 }}>
        {salaryNotices.length > 0 ? (
          <>
            <SalaryNoticeContainer>
              {salaryNotices.map((salary, index) => {
                let currency = salary.currency == "SEK" ? " kr" : "";
                return (
                  <Cell
                    style={{
                      marginBottom: salaryNotices.length - 1 == index ? 0 : 10,
                    }}
                  >
                    <WorkerContainer>
                      <Text
                        style={{ margin: 0, padding: 0 }}
                        fontSize={FontSize.Small}
                        color={Color.SeaBlue600}
                      >
                        {t("Salary.Period")}
                      </Text>
                      <Text
                        color={Color.MidnightBlue}
                        fontFamily={FontFamily.MontserratRegular}
                      >{`${formatTimePeriod(salary.period)}`}</Text>
                    </WorkerContainer>
                    <DividerLine1 />
                    <HoursContainer>
                      <Text
                        style={{ margin: 0, padding: 0 }}
                        fontSize={FontSize.Small}
                        color={Color.SeaBlue600}
                      >
                        {t("Salary.TotalWorkTime")}
                      </Text>
                      <Text
                        color={Color.MidnightBlue}
                        fontFamily={FontFamily.MontserratRegular}
                      >{`${formatDuration(
                        salary.workedHours.toString()
                      )}`}</Text>
                    </HoursContainer>
                    <DividerLine2 />
                    <GrossSalaryContainer>
                      <Text
                        style={{ margin: 0, padding: 0 }}
                        fontSize={FontSize.Small}
                        color={Color.SeaBlue600}
                      >
                        {t("Salary.GrossSalary")}
                      </Text>
                      <Text
                        color={Color.MidnightBlue}
                        fontFamily={FontFamily.MontserratRegular}
                      >{`${salary.grossSalary}${currency}`}</Text>
                    </GrossSalaryContainer>
                    <NetSalaryContainer>
                      <Text
                        style={{ margin: 0, padding: 0 }}
                        fontSize={FontSize.Small}
                        color={Color.SeaBlue600}
                      >
                        {t("Salary.NetSalary")}
                      </Text>
                      <Text
                        color={Color.MidnightBlue}
                        fontFamily={FontFamily.MontserratRegular}
                      >{`${salary.netSalary}${currency}`}</Text>
                    </NetSalaryContainer>
                    <TaxContainer>
                      <Text
                        style={{ margin: 0, padding: 0 }}
                        fontSize={FontSize.Small}
                        color={Color.SeaBlue600}
                      >
                        {t("Salary.Tax")}
                      </Text>
                      <Text
                        color={Color.MidnightBlue}
                        fontFamily={FontFamily.MontserratRegular}
                      >{`${salary.preliminaryTax}${currency}`}</Text>
                    </TaxContainer>
                    <PayrollTaxContainer>
                      <Text
                        style={{ margin: 0, padding: 0 }}
                        fontSize={FontSize.Small}
                        color={Color.SeaBlue600}
                      >
                        {t("Salary.PayrollTax")}
                      </Text>
                      <Text
                        color={Color.MidnightBlue}
                        fontFamily={FontFamily.MontserratRegular}
                      >{`${salary.payrollTax}${currency}`}</Text>
                    </PayrollTaxContainer>
                    <DeductionAmountContainer>
                      <Text
                        style={{ margin: 0, padding: 0 }}
                        fontSize={FontSize.Small}
                        color={Color.SeaBlue600}
                      >
                        {t("Salary.DeductionAmount")}
                      </Text>
                      <Text
                        color={Color.MidnightBlue}
                        fontFamily={FontFamily.MontserratRegular}
                      >{`${salary.deductionAmount}${currency}`}</Text>
                    </DeductionAmountContainer>
                    <DividerLine3 />
                    <SalaryActionContainer>
                      <IconCircularContainer
                        isAccept
                        onClick={() => {
                          handleDownloadSalaryPdf(
                            salary.workerId,
                            salary.period,
                            salary.workerName,
                            salary.id
                          );
                        }}
                      >
                        <CustomIcon
                          className="icon"
                          name="document"
                          size="20px"
                          padding="2px"
                          color={Color.White}
                          title={t("Salary.DownloadPdf")}
                        />
                      </IconCircularContainer>
                      {salary.payrollType === PaymentMethod.SIMFLEXITY_PAY ? (
                        <LogoContainer>
                          <Logo
                            src={SimflexityPayLogo}
                            alt="SimflexityPay logo"
                          />
                        </LogoContainer>
                      ) : (
                        <EmptyDiv />
                      )}
                    </SalaryActionContainer>
                  </Cell>
                );
              })}
            </SalaryNoticeContainer>
            {selectedYear && salaryNotices && (
              <SummaryIndividualSalary
                salaryNotices={salaryNotices}
                selectedYear={selectedYear}
                setSelectedYear={setSelectedYear}
              />
            )}
          </>
        ) : (
          <Text style={{ textAlign: "center", marginTop: 40 }}>
            {t("Salary.NoSalaryDataForPerson")}
          </Text>
        )}
      </SalaryPageContainer>
    </>
  );
};
