import { Skill } from "./Favorite";
import { PaymentMethod } from "./Gig";
import { SimflexityError } from "./SimflexityError";

export type CompanyDto = {
  id: number;
  companyName: string | null;
  legalName: string | null;
  registrationNumber: string | null;
  description: string | null;
  address: string | null;
  postalCode: string | null;
  city: string | null;
  countryId: number;
  email: string | null;
  phoneNumber: string | null;
  contactPerson: string | null;
  firstName: string | null;
  lastName: string | null;
  websiteUrl: string | null;
  rating: number;
  error?: SimflexityError | null;
  verified: boolean;
  active: boolean;
  collectiveAgreement: string | null;
  location: string | null;
  referenceCode: string | null;
  companyImage?: string | null;
  companySettings: CompanySettingDto[];
  staffingPools?: StaffingType[];
};
export type CompanySettingDto = {
  id?: number | undefined;
  companyId?: number | undefined;
  key: string;
  value: string;
};
export type CompanyRequestDto = Omit<
  CompanyDto,
  "id" | "active" | "verified" | "rating" | "referenceCode" | "companySettings"
>;
export type StaffingType = {
  name: string;
  staffingPoolId: number;
};

export type CompanyUser = {
  id: number;
  firebaseId: string;
  role: UserRole;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  companyId: number;
  error: {
    code: number;
    message: string;
  };
};

export type SubscriptionInfo = {
  companyId: number | undefined;
  expirationDate: string;
  membershipDisplayName: string;
  membershipType: string;
  membershipTypeId: string;
  membershipTypekey: string;
};

export type CompanyUserRequestDto = {
  role: UserRoleRequest;
  firstName: string;
  lastName: string;
  email: string;
};
export enum UserRole {
  NONE = "None",
  COMPANY_OWNER = "CompanyOwner",
  COMPANY_ADMIN = "CompanyAdmin",
  COMPANY_BASIC = "CompanyBasic",
  COMPANY_TIMESTAMP = "CompanyTimeStamp",
}

export enum UserRoleRequest {
  NONE = 0,
  COMPANY_OWNER = 1,
  COMPANY_ADMIN = 2,
  COMPANY_BASIC = 3,
  COMPANY_TIMESTAMP = 4,
}

export type CompanyOBSetting = {
  id?: number;
  endTime: string;
  date: string | null;
  weekday: OBSettingWeekDayEN | OBSettingWeekDaySE | null;
  fixedValue: number | null;
  percentValue: number | null;
  companyId: number;
  startTime: string;
};

export type TimeReportForStamping = {
  companyId: number;
  workerPhoneNumber: string;
  active: TimeReport | null;
  next: TimeReport | null;
  previous: TimeReport | null;
};

export type TemplateForAutoGeneratedGig = {
  companyId: number;
  workerPhoneNumber: string;
  firstName: string;
  startTime: string;
  endTime: string;
  breakTime: number;
  breakStartTime: string | null;
  breakEndTime: string | null;
  hourlyRate: number;
  role: string;
  templateId: number;
};

export type TimeReport = {
  timeReportId: number;
  gigRole: string;
  startTime: string;
  endTime: string;
  checkInTime: string | null;
  checkOutTime: string | null;
  firstName: string | null;
  lastName: string | null;
};

export type StampClockTemplate = {
  companyId: number | undefined;
  role: string;
  startTime: string;
  endTime: string;
  breakTime: number;
  hourlyRate: number;
  paymentType: string;
};

export type StampClockTemplateFormData = {
  role: string;
  startTime: string;
  endTime: string;
  hourlyRate: number;
  breakTime: number;
  paymentType: string;
  localStampClockSettingActive: boolean;
  autoGenerateShiftSettingActive: boolean;
};

export type SystemFormData = {
  preferedPaymentMethod: PaymentMethod | undefined;
};

export type UserWithTimeReportForStampClock = {
  companyId: number;
  timeReportId: number;
  workerPhoneNumber: string;
};

export enum OBSettingWeekDayEN {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SÖNDAG = 0,
  MÅNDAG = 1,
  TISDAG = 2,
  ONSDAG = 3,
  TORSDAG = 4,
  FREDAG = 5,
  LÖRDAG = 6,
}
export enum OBSettingWeekDaySE {
  SÖNDAG = 0,
  MÅNDAG = 1,
  TISDAG = 2,
  ONSDAG = 3,
  TORSDAG = 4,
  FREDAG = 5,
  LÖRDAG = 6,
}

export enum OBSettingShortWeekDay {
  SUNDAYSHORT = 0,
  MONDAYSHORT = 1,
  TUESDAYSHORT = 2,
  WEDNESDAYSHORT = 3,
  THURSDAYSHORT = 4,
  FRIDAYSHORT = 5,
  SATURDAYSHORT = 6,
}

export type CompanyWorkerDto = {
  id: number;
  workerFirebaseId: string;
  firstName: string;
  lastName: string;
  description: string;
  address: string;
  postalCode: string;
  city: string;
  email: string;
  dateOfBirth: string;
  ssn: string;
  phoneNumber: string;
  rating: any;
  workerSkills: Skill[];
  stats: any[];
  hourlyRate: string;
  documents: [];
};


export type Rating = {
  id: number;
  gigId: number;
  companyId: number;
  firstName: string;
  lastName: string;
  message: string;
  rating: number;
  workerId: number;
  gigRole: string;
  gigStartTime: string;
  gigEndTime: string;
};

export type WorkerWithUrl = {
  workerId: number;
  firebaseId: string | null;
  imageUrl: string;
};
