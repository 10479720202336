import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { H4 } from "components/Typography/text.styled";
import { FontFamily, FontSize } from "config/font";
import { Checkbox } from "@material-ui/core";
import { InfoCircle } from "../../timeReport/timeReportModal.styled";
import { Text } from "components/Typography/text.styled";
import { Color } from "config/colors";
import {
  CheckBoxContainer,
  CheckboxText,
  LocalStampClockContainer,
} from "../companyStampClock/companyStampClock.styled";
import { SystemFormData } from "model/Company";
import { PaymentMethod } from "model/Gig";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { Api } from "services/api/api.service";

type Props = {
  setPreferedPaymentMethod: React.Dispatch<
    React.SetStateAction<PaymentMethod | undefined>
  >;
  setFormData: React.Dispatch<React.SetStateAction<SystemFormData>>;
  formData: SystemFormData;
};

export const PreferedPaymentSetting: React.FC<Props> = ({
  setPreferedPaymentMethod,
  setFormData,
  formData,
}) => {
  const { t } = useTranslation();
  const [showInfo, setShowInfo] = useState(false);
  const [companyState] = useCompanyStore();

  useEffect(() => {
    if (companyState.company && companyState.company.id) {
      Api()
        .company.systemSettings.getCompanySetting(
          companyState.company.id,
          "DefaultGigPaymentType"
        )
        .then((res) => {
          setPreferedPaymentMethod(res.data.value);
          setFormData((prevState) => ({
            ...prevState,
            preferedPaymentMethod: res.data.value,
          }));
        })
        .catch((err) => {});
    }
  }, []);

  return (
    <>
      <LocalStampClockContainer>
        <H4>{t("System.PreferedPaymentMethod")}</H4>
        <InfoCircle
          onMouseEnter={() => setShowInfo(true)}
          onMouseLeave={() => setShowInfo(false)}
        >
          <Text
            fontFamily={FontFamily.LeagueSpartanBold}
            color={Color.White}
            padding
            fontSize={FontSize.Small}
          >
            i
          </Text>
        </InfoCircle>
        {showInfo && (
          <Text
            fontSize={FontSize.Small}
            color={Color.SeaBlue600}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("System.PreferedPaymentMethodInfo")}
          </Text>
        )}
      </LocalStampClockContainer>
      <CheckBoxContainer>
        <Checkbox
          checked={
            formData.preferedPaymentMethod !== PaymentMethod.SIMFLEXITY_PAY
          }
          name="ownSalaryManagmentSetting"
          value={PaymentMethod.NONE}
          onChange={(e) => {
            setFormData((prevState) => ({
              ...prevState,
              preferedPaymentMethod: e.target.value as PaymentMethod,
            }));
          }}
        />
        <CheckboxText>{t("System.OwnSalaryManagement")}</CheckboxText>
      </CheckBoxContainer>
      <CheckBoxContainer>
        <Checkbox
          checked={
            formData.preferedPaymentMethod == PaymentMethod.SIMFLEXITY_PAY
          }
          name="simflexityPaySetting"
          value={PaymentMethod.SIMFLEXITY_PAY}
          onChange={(e) => {
            setFormData((prevState) => ({
              ...prevState,
              preferedPaymentMethod: e.target.value as PaymentMethod,
            }));
          }}
        />
        <CheckboxText>{t("System.SimflexityPay")}</CheckboxText>
      </CheckBoxContainer>
    </>
  );
};
