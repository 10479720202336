import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useAuthStore } from "stores/authStore/authStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useChatStore } from "web-apps/company/stores/chatStore/chatStore";
import { getMenuList } from "utils/utils";
import { Color } from "config/colors";
import { FontFamily } from "config/font";
import { MenuItems } from "../menuList/menuItems.component";
import { CustomIcon } from "components/icon/customIcon.component";
import { CustomLink, Text } from "components/Typography/text.styled";
import { DrawerMenuListStyled } from "../menuList/drawerMenuList.styled";
import { DrawerStyled } from "./drawer.styled";
import {
  NavLi,
  NavIconLi,
  CompaniesLi,
  DrawerSelect,
} from "../menuList/navBarMenuList.styled";
import { DrawerCompanyList } from "../menuList/drawerCompanyList";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Drawer: React.FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { path } = useRouteMatch();
  const [authState, authActions] = useAuthStore();
  const [companyState, , companyDispatch] = useCompanyStore();
  const [, chatDispatch] = useChatStore();
  const menuList = getMenuList(authState.accountType, t);
  const [companySelectOpen, setCompanySelectOpen] = useState(false);

  const handleClick = async () => {
    authActions.signOut().then(() => {
      companyDispatch({ type: "CLEAR_COMPANY_INFORMATION" });
      chatDispatch({ type: "CLEAR_CHAT_DATA" });
      history.push("/company/login");
      setOpen(!open);
    });
  };

  return (
    <DrawerStyled open={open} className="drawer">
      <CustomIcon
        name="cross"
        size="40px"
        onClick={() => {
          setOpen(!open);
        }}
        style={{ margin: "20px 20px 0 0", alignSelf: "flex-end" }}
      />

      <DrawerMenuListStyled>
        {authState.signedIn && companyState.company && (
          <>
            <MenuItems menuList={menuList} open={open} setOpen={setOpen} />

            <NavIconLi active={window.location.pathname === "/company/profile"}>
              <CustomLink
                color={Color.SeaBlue500}
                to={"/company/profile"}
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CustomIcon
                  name="person"
                  color={Color.SeaBlue500}
                  padding="5px"
                />
                <p style={{ margin: 0, textAlign: "center" }}>
                  {t("ProfileCompany.Profile")}
                </p>
              </CustomLink>
            </NavIconLi>
            <NavIconLi active={window.location.pathname === "/company/settings"}>
              <CustomLink
                color={Color.SeaBlue500}
                to={"/company/settings"}
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CustomIcon
                  name="settings"
                  color={Color.SeaBlue500}
                  padding="5px"
                />
                <p style={{ margin: 0, textAlign: "center" }}>
                  {t("General.Settings")}
                </p>
              </CustomLink>
            </NavIconLi>
            <CompaniesLi>
              <DrawerSelect
                color={Color.SeaBlue500}
                onClick={() => {
                  setCompanySelectOpen(!companySelectOpen);
                }}
              >
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  color={Color.SeaBlue500}
                  style={{ margin: 0, textAlign: "center" }}
                >
                  {t("CompanySelect.YourCompanies")}
                </Text>

                <CustomIcon
                  name={companySelectOpen ? "chevron-up" : "chevron-down"}
                  color={Color.SeaBlue500}
                  padding="5px"
                />
              </DrawerSelect>
            </CompaniesLi>

            {companySelectOpen && (
              <DrawerCompanyList
                setCompanySelectOpen={setCompanySelectOpen}
                setOpen={setOpen}
              />
            )}

            {/*             <NavIconLi
              active={window.location.pathname === "company/employees"}
            >
              <CustomLink
                color={Color.SeaBlue500}
                to={"/company/employees"}
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CustomIcon
                  name="person"
                  color={Color.SeaBlue500}
                  padding="5px"
                />
                <p style={{ margin: 0, textAlign: "center" }}>
                  {t("CompanyEmployees.CompanyEmployees")}
                </p>
              </CustomLink>
            </NavIconLi> */}
            <NavIconLi
              active={window.location.pathname === "company/registration"}
            >
              <CustomLink
                color={Color.SeaBlue500}
                to={"/company/registration"}
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CustomIcon
                  name="plus"
                  color={Color.SeaBlue500}
                  padding="5px"
                />
                <p style={{ margin: 0, textAlign: "center" }}>
                  {t("CompanySelect.AddCompany")}
                </p>
              </CustomLink>
            </NavIconLi>
            <NavIconLi active={false}>
              <CustomLink color={Color.BurntSienna} onClick={handleClick}>
                <CustomIcon name="logout" padding="5px" />
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  style={{ margin: 0 }}
                >
                  {t("ProfileCompany.SignOut")}
                </Text>
              </CustomLink>
            </NavIconLi>
          </>
        )}
        {authState.signedIn && !companyState.company && (
          <NavLi active={false} color={Color.BurntSienna} onClick={handleClick}>
            <CustomLink to={`${path}/login`} color={Color.Destructive}>
              {t("ProfileCompany.SignOut")}
            </CustomLink>
          </NavLi>
        )}
        {!authState.signedIn && (
          <NavLi active={false}>
            <CustomLink to={`${path}/login`} color={Color.Destructive}>
              {t("LoginCompany.Login")}
            </CustomLink>
          </NavLi>
        )}
      </DrawerMenuListStyled>
    </DrawerStyled>
  );
};
