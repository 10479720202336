import React, { useEffect, useState } from "react";
import "react-tooltip/dist/react-tooltip.css";
import { CircularProgressWrapper } from "./weeklyCalendar.styled";
import { CircularProgress, Modal } from "@material-ui/core";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { Api } from "services/api/api.service";
import { TimeReportDto } from "model/TimeReport";
import { TimeReportModal } from "../timeReport/timeReportModal.component";
import { RatingModal } from "../timeReport/ratingModal.component";
import { EndorseModal } from "../timeReport/endorsements/endorseModal.component";
import { ApplicationDto } from "model/Application";

type TimeReportModalContainerProps = {
  gigId: number;
  timeReportId: number;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalOpen: boolean;
  calenderView?: boolean;
  application?: ApplicationDto | undefined;
};

export const TimeReportModalContainer: React.FC<
  TimeReportModalContainerProps
> = (props) => {
  const [companyState] = useCompanyStore();
  const [isLoading, setIsLoading] = useState(false);
  const [currentTimeReport, setCurrentTimeReport] = useState<TimeReportDto>();
  const [ratingModalOpen, setRatingModalOpen] = useState(false);
  const [endorseModalOpen, setEndorseModalOpen] = useState(false);

  const fetchTimeReport = (timeReportId: number) => {
    if (companyState.company?.id) {
      setIsLoading(true);
      Api()
        .company.timeReport.getTimeReport(
          companyState.company.id,
          props.gigId,
          timeReportId
        )
        .then((res) => {
          setCurrentTimeReport(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("Error fetching time report", err);
          setIsLoading(false);
          props.setModalOpen(false);
        });
    }
  };

  useEffect(() => {
    if (props.modalOpen) {
      fetchTimeReport(props.timeReportId);
    }
  }, [props.modalOpen]);

  return (
    <>
      {isLoading ? (
        <Modal
          open={props.modalOpen}
          onClose={() => props.setModalOpen(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <CircularProgressWrapper>
            <CircularProgress />
          </CircularProgressWrapper>
        </Modal>
      ) : (
        <>
          {currentTimeReport && (
            <TimeReportModal
              chosenTimeReportWithWorker={currentTimeReport}
              modalOpen={props.modalOpen}
              setModalOpen={props.setModalOpen}
              setRatingModalOpen={setRatingModalOpen}
              calenderView={props.calenderView}
              application={props.application}
            />
          )}
          {ratingModalOpen && currentTimeReport && (
            <RatingModal
              ratingModalOpen={ratingModalOpen}
              setRatingModalOpen={setRatingModalOpen}
              chosenTimeReportWithWorker={currentTimeReport}
              setEndorseModalOpen={setEndorseModalOpen}
            />
          )}
          {endorseModalOpen && currentTimeReport && (
            <EndorseModal
              endorseModalOpen={endorseModalOpen}
              setEndorseModalOpen={setEndorseModalOpen}
              chosenTimeReportWithWorker={currentTimeReport}
            />
          )}
        </>
      )}
    </>
  );
};
