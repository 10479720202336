import React from "react";
import { TipStatus } from "model/Tip";
import { Color } from "config/colors";
import { useTranslation } from "react-i18next";
import { Text } from "components/Typography/text.styled";
import { CustomIcon } from "components/icon/customIcon.component";

type TipStatusProps = {
  status: TipStatus;
};

export const TipStatusIndicator: React.FC<TipStatusProps> = (props) => {
  const { t } = useTranslation();

  switch (props.status) {
    case TipStatus.Created:
      return <Text>{t("CompanyTip.Created")}</Text>;
    case TipStatus.Saved:
      return <Text>{t("CompanyTip.SavedTip")}</Text>;
    case TipStatus.Done:
      return <Text>{t("CompanyTip.ReadyToPayout")}</Text>;
    case TipStatus.Closed:
      return (
        <Text
          style={{
            margin: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {t("CompanyTip.Paid")}
          <CustomIcon
            name="checkmark"
            color={Color.BurntSienna}
            padding="4px"
          />
        </Text>
      );
    case TipStatus.ClosedManually:
      return (
        <Text
          style={{
            margin: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {t("CompanyTip.ManuallyPaid")}
          <CustomIcon
            name="checkmark"
            color={Color.BurntSienna}
            padding="4px"
          />
        </Text>
      );
    default:
      return <Text>""</Text>;
  }
};
