import styled from "styled-components";
import { Text } from "components/Typography/text.styled";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: repeat(3, auto);
  grid-gap: 10px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    display: unset;
  }
`;

type RowProps = {
  justify?: string;
  paddingTop?: number;
};

type OfferCellProps = {
  disabled?: boolean;
};

export const RowWrapper = styled.div<RowProps>`
  display: flex;
  flex-direction: column;
  padding-top: ${(props) => props.paddingTop && props.paddingTop}px;
`;
export const LinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  div {
    margin: 0 0 10px 0;
  }
`;

export const ShiftUl = styled.ul`
  margin: 0px;
  padding-left: 0px;
`;

export const TimeAndDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px;
`;

export const CellContent = styled.div`
  display: contents;
  width: 100%;
  grid-column: 1/3;
`;

export const GiggerContainer = styled(TimeAndDateContainer)``;

export const ApplicationCell = styled.div`
  background-color: ${Color.White};
  margin-top: 10px;
  padding: 0 10px;
  width: 100%;
  display: grid;
  grid-template-columns: "0.7fr 0.2fr 1fr 1fr 0.2fr";
  column-gap: 0.5em;
  justify-content: space-between;
  position: relative;
  border-bottom: 1px solid ${Color.SeaBlue200};

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

export const TotalBrakeContainer = styled(TimeAndDateContainer)``;

export const TotalTimeContainer = styled(TimeAndDateContainer)``;
export const DeductionAmountContainer = styled(TimeAndDateContainer)``;

export const TimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px;
`;

export const VerticalDivider = styled.div`
  width: 1px;
  height: 80%;
  background-color: ${Color.SeaBlue200};
`;

export const CompanyInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 20px;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const RoleAndSkillsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GigHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
  margin-top: 15px;
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SalaryContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RatingCell = styled.div`
  background-color: ${Color.White};
  margin-top: 10px;
  padding: 0 10px;
  width: 100%;
  display: grid;
  grid-template-columns: "0.6fr 0.2fr 1fr 0.5fr";
  column-gap: 0.5em;
  justify-content: space-between;
  position: relative;
  border-bottom: 1px solid ${Color.SeaBlue200};
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;

export const GigInfoContainer = styled(TimeAndDateContainer)``;

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const WorkerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const DropDownRow = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${Color.SeaBlue200};
  justify-content: space-between;
  cursor: pointer;
`;

type Props = {
  backgroundColor?: Color;
  borderRadius?: string;
};

export const Row = styled.div<Props>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${Color.SeaBlue200};
  margin-top: 10px;
  padding-left: 0px;
  margin-left: 0px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "none"};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "0px"};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RowAction = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  padding: 10px;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ShiftContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RatingContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ShiftsScrollBar = styled.div<{ itemCount: number }>`
  display: flex;
  flex-direction: column;
  height: ${({ itemCount }) => (itemCount <= 1 ? "auto" : "250px")};
  max-height: 322px;
  width: 100%;
  overflow-y: scroll;
  position: relative;
  padding-right: 8px;

  ::-webkit-scrollbar {
    width: 8px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover ::-webkit-scrollbar {
    opacity: 1;
  }

  ::-webkit-scrollbar-thumb {
    background: ${Color.SeaBlue600};
    border-radius: 4px;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: ${Color.SeaBlue500};
    }
    &:active {
      background-color: ${Color.SeaBlue400};
    }
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
`;

export const ImageWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${Color.SeaBlue300};
  margin: 10px 10px 10px 0px;
`;

export const WorkerWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  button {
    display: unset;
    margin: 0;
    margin-top: auto;
    align-self: flex-start;
  }
`;

export const ModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: ${Color.White};
  padding: 20px;
  display: flex;
  flex-direction: column;
  outline: none;
  border-radius: 8px;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.2);
  button {
    font-size: ${FontSize.Small};
  }
  height: auto;
  max-height: 80%;
  overflow-y: scroll;
`;

export const SummaryModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 900px;
  background-color: ${Color.White};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  outline: none;
  border-radius: 8px;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.2);
  padding-top: 0px;
  height: 80vh;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;

  @media (max-width: 1024px) {
    max-width: 80%;
    height: auto;
  }

  @media (max-width: 480px) {
    padding: 20px;
    height: auto;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0px;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const SkillsWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
  margin: 15px 0;
  padding: 0 15px;
`;

export const SkillTag = styled.div`
  background-color: ${Color.Positive};
  padding: 5px;
  border-radius: 4px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LabelWrapperDescription = styled(LabelWrapper)`
  top: 0;
  position: sticky;
  padding-top: 10px;
  background: ${Color.SeaBlue200};
`;

export const Cell = styled.div`
  padding: 10px;
  background-color: ${Color.SeaBlue200};
  border-radius: 10px;
  @media (max-width: 768px) {
    margin: 10px 0px;
  }
`;

export const BottomText = styled(Text)`
  margin: 0px;
  font-family: ${FontFamily.MontserratRegular}, Helvetica;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const OfferModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: ${Color.White};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  outline: none;
  border-radius: 8px;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.2);

  .MuiInputBase-root {
    margin-bottom: 15px;
  }
  button {
    font-size: ${FontSize.Small};
  }

  p {
    margin: 0 0 30px 0;
  }
`;

export const OfferWorkerCell = styled.div<OfferCellProps>`
  display: flex;
  background-color: ${Color.White};
  padding: 10px;
  align-content: center;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 8px;
  justify-content: space-between;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  border-bottom: 1px solid ${Color.SeaBlue200};

  p {
    margin: 0;
  }
  &:hover {
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
    opacity: ${(props) => (props.disabled ? "0.5" : "0.7")};
  }
`;

export const WorkersContainer = styled.div`
  max-height: 300px;
  padding: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #cfcfcf #f9f9f9;
  width: 100%;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b3b3b3;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f9f9f9;
  }
`;
export const RatingRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  margin-bottom: 10px;
  p {
    margin: 0 0 0;
  }
`;


export const DropDownContainer = styled.div`
  border: 1px solid ${Color.SeaBlue400};
  border-radius: 8px;
  width: 100%;
  max-width: 300px;
  padding: 12px;
  height: 40px;
  font-size: ${FontSize.Standard};
  color: ${Color.MidnightBlue};
  font-family: ${FontFamily.MontserratRegular}, Helvetica;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  margin-bottom:20px;
  margin-top:10px;

  &:focus {
    border-color: ${Color.PitchBlack};
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    outline: none;
  }

  p {
  margin-bottom:42px;
  }
`;