import { useState, useEffect } from "react";
import moment from "moment";
import { Color } from "config/colors";
import { Modal } from "@material-ui/core";
import { AlertModal } from "./alertModal";
import { WorkgroupTab } from "./workgroupTab";
import { useTranslation } from "react-i18next";
import { FontFamily, FontSize } from "config/font";
import { DistributionTabs } from "./distributionTabs";
import { parseWorkTime, formatNumber } from "utils/utils";
import { Text, H3 } from "components/Typography/text.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import { Worker, WorkGroup, TipDetails, CalculationType } from "model/Tip";
import {
  TipInput,
  WorkerCell,
  TipHeadCell2,
  TipModalBody,
  TipButtonStyled,
  WorkerContainer,
  DetailsContainer,
} from "../tips.styled";
import { useTipStore } from "web-apps/company/stores/tipStore/tipStore";

type CalculateModalProps = {
  open: boolean;
  onClose: () => void;
  companyId: number;
  activeTab: CalculationType;
  setActiveTab: (tab: CalculationType) => void;
  onSaveSuccess: () => void;
  selectedTipIds: number[];
  calculationResult: {
    companyTips: TipDetails[];
    workGroups: WorkGroup[];
  };
  onUpdateRemainingAmount: (updatedAmounts: { [date: string]: number }) => void;
};

export const CalculateModal: React.FC<CalculateModalProps> = (props) => {
  const { t } = useTranslation();
  const tipStore = useTipStore();
  const [updatedTips, setUpdatedTips] = useState(() =>
    props.calculationResult.companyTips.reduce((acc, tip) => {
      acc[tip.date] = {
        workers: tip.workers.map((worker) => ({ ...worker })),
        remainingAmount: tip.remainingAmount,
      };
      return acc;
    }, {} as Record<string, { workers: Worker[]; remainingAmount: number }>)
  );
  const { companyTips } = props.calculationResult;
  const [_, tipActions] = useTipStore();

  useEffect(() => {
    setUpdatedTips(
      props.calculationResult.companyTips.reduce((acc, tip) => {
        acc[tip.date] = {
          workers: Array.isArray(tip.workers)
            ? tip.workers.map((worker) => ({ ...worker }))
            : [],
          remainingAmount: tip.remainingAmount,
        };
        return acc;
      }, {} as Record<string, { workers: Worker[]; remainingAmount: number }>)
    );
  }, [props.calculationResult]);

  useEffect(() => {
    if (Object.keys(updatedTips).length > 0) {
      const newRemainingAmounts = Object.keys(updatedTips).reduce(
        (acc, date) => {
          acc[date] = updatedTips[date].remainingAmount;
          return acc;
        },
        {} as { [date: string]: number }
      );

      props.onUpdateRemainingAmount(newRemainingAmounts);
    }
  }, [updatedTips]);

  const handleTipChange = (
    date: string,
    workerIndex: number,
    newTip: string
  ) => {
    setUpdatedTips((prevTips) => {
      if (!prevTips[date]) return prevTips;

      const currentData = prevTips[date];

      const tipData = props.calculationResult.companyTips.find(
        (tip) => tip.date === date
      );

      if (!tipData) return prevTips;

      const newTipAmount = parseFloat(newTip) || 0;

      const totalDistributed = currentData.workers.reduce(
        (sum, worker, index) =>
          index === workerIndex ? sum : sum + worker.tipAmount,
        0
      );

      if (totalDistributed + newTipAmount > tipData.amount) {
        return prevTips;
      }
      const newWorkers = [...currentData.workers];
      newWorkers[workerIndex] = {
        ...newWorkers[workerIndex],
        tipAmount: newTipAmount,
      };

      const newTips = {
        ...prevTips,
        [date]: {
          ...currentData,
          workers: newWorkers,
          remainingAmount: tipData.amount - (totalDistributed + newTipAmount),
        },
      };

      return newTips;
    });
  };

  return (
    <AlertModal>
      {(openAlertModal) => {
        const handleSaveDistribution = async () => {
          const updatedCompanyTips = props.calculationResult.companyTips.map(
            (tip) => {
              const updatedTip = updatedTips[tip.date];
              return updatedTip
                ? {
                    ...tip,
                    workers: updatedTip.workers,
                    remainingAmount: updatedTip.remainingAmount,
                  }
                : tip;
            }
          );

          setUpdatedTips(
            updatedCompanyTips.reduce((acc, tip) => {
              acc[tip.date] = {
                workers: tip.workers.map((worker) => ({ ...worker })),
                remainingAmount: tip.remainingAmount,
              };
              return acc;
            }, {} as Record<string, { workers: Worker[]; remainingAmount: number }>)
          );

          await tipActions.saveTipDistribution(
            props.companyId,
            updatedCompanyTips
          );

          openAlertModal(t("CompanyTip.TipSaved"), false, props.onSaveSuccess);
        };

        return (
          <>
            <Modal open={props.open} onClose={props.onClose}>
              <TipModalBody>
                <CustomIcon
                  color={Color.LighterDestructive}
                  onClick={props.onClose}
                  name={"cross"}
                  style={{
                    position: "absolute",
                    right: 20,
                    top: 20,
                  }}
                >
                  {t("General.Close")}
                </CustomIcon>

                <DistributionTabs
                  selectedDistribution={props.activeTab}
                  setAutomaticAlternative={props.setActiveTab}
                />

                {props.activeTab === CalculationType.Time ? (
                  <div>
                    {props.calculationResult &&
                      props.calculationResult.companyTips.length > 0 && (
                        <>
                          <H3>{t("CompanyTip.CalculatedTips")}</H3>
                          {companyTips.map(
                            ({
                              date,
                              amount,
                              approvedCount,
                              totalCount,
                              workers,
                            }) => (
                              <DetailsContainer
                                key={date}
                                style={{ marginBottom: "10px" }}
                              >
                                <TipHeadCell2>
                                  <Text fontSize={FontSize.Large}>
                                    {moment(date, "YYYY-MM-DD")
                                      .format("ddd D/M")
                                      .toUpperCase()}
                                  </Text>
                                  <Text fontSize={FontSize.Large}>
                                    {t("CompanyTip.RemainingWithColon")}{" "}
                                    {formatNumber(
                                      updatedTips[date]?.remainingAmount || 0
                                    )}{" "}
                                    {t("CompanyTip.Of")} {formatNumber(amount)}{" "}
                                    {t("CompanyTip.Kr")}
                                  </Text>
                                </TipHeadCell2>

                                {workers.length === 0 ? (
                                  <Text style={{ marginTop: "10px" }}>
                                    {t("CompanyTip.NoApprovedWorkReports")}
                                  </Text>
                                ) : (
                                  workers.map(
                                    (worker: Worker, index: number) => (
                                      <WorkerContainer key={index}>
                                        <WorkerCell>
                                          <Text>
                                            {worker.firstName} {worker.lastName}
                                          </Text>
                                        </WorkerCell>
                                        <WorkerCell>
                                          <Text>
                                            {
                                              parseWorkTime(worker.workTime)
                                                .hours
                                            }
                                            {t("General.HourShortened")}{" "}
                                            {
                                              parseWorkTime(worker.workTime)
                                                .minutes
                                            }
                                            {t("General.MinutesShortened")}
                                          </Text>
                                        </WorkerCell>
                                        <WorkerCell>
                                          <TipInput
                                            type="text"
                                            value={
                                              updatedTips[date]?.workers[index]
                                                ?.tipAmount ?? 0
                                            }
                                            onChange={(e) =>
                                              handleTipChange(
                                                date,
                                                index,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </WorkerCell>
                                      </WorkerContainer>
                                    )
                                  )
                                )}
                                <Text
                                  fontFamily={FontFamily.MontserratBold}
                                  style={{
                                    color:
                                      approvedCount < totalCount
                                        ? Color.Destructive
                                        : Color.Black,
                                  }}
                                >
                                  {t("CompanyTip.ApprovedCount")}{" "}
                                  {approvedCount} / {totalCount}
                                </Text>
                              </DetailsContainer>
                            )
                          )}
                        </>
                      )}

                    <TipButtonStyled
                      onClick={handleSaveDistribution}
                      backgroundColor={Color.ModernGreen}
                    >
                      {t("CompanyTip.SaveDistribution")}
                    </TipButtonStyled>
                  </div>
                ) : (
                  <WorkgroupTab
                    onClose={props.onClose}
                    companyId={props.companyId}
                    selectedTipIds={props.selectedTipIds}
                    workgroups={props.calculationResult.workGroups}
                    onUpdateRemainingAmount={props.onUpdateRemainingAmount}
                  />
                )}
              </TipModalBody>
            </Modal>
          </>
        );
      }}
    </AlertModal>
  );
};
