import { AxiosResponse } from "axios";
import { axiosRequest } from "../api.utils";
import { API_ROOT } from "services/baseURLs.config";
import { CompanyLoginDto } from "model/Login";
import {
  CompanyDto,
  CompanyOBSetting,
  CompanyRequestDto,
  CompanyUser,
  CompanyUserRequestDto,
  StampClockTemplate,
  TemplateForAutoGeneratedGig,
  UserRoleRequest,
} from "model/Company";
import { application } from "./application.service";
import { gig } from "./gig.service";
import { companyEmployees } from "./companyEmployees";
import { calendar } from "./calendar.service";
import { template } from "./template.service";
import { worker } from "./worker.service";
import { timeReport } from "./timeReport.service";
import { staffingWorkerPool } from "./staffingWorkerPool.service";
import { CompanyFavoriteDto } from "../../../model/Favorite";
import { TimeReportPaymentDto } from "model/TimeReport";
import { workgroup } from "./workgroup.service";
import { EntityName, NewWorker, UnitName } from "model/CompanyStructure";
import { tip } from "./companyTip.service";
import { systemSettings } from "./systemSettings.service";
import { salary } from "./salary.service";

const login = async (uid: string): Promise<AxiosResponse<CompanyLoginDto>> => {
  return await axiosRequest.auth.get<CompanyLoginDto>(
    `${API_ROOT}/login/company/${uid}`
  );
};

const addOrUpdateCompany = async (
  companyData: CompanyRequestDto,
  companyId?: number
): Promise<AxiosResponse<CompanyDto>> => {
  if (companyId) {
    return await axiosRequest.auth.put<CompanyDto>(
      `${API_ROOT}/companies/${companyId}`,
      companyData
    );
  } else {
    return await axiosRequest.auth.post<CompanyDto>(
      `${API_ROOT}/companies`,
      companyData
    );
  }
};

const addCompanyFavorites = async (
  companyId: number,
  workerId: number
): Promise<AxiosResponse<CompanyFavoriteDto>> => {
  return await axiosRequest.auth.post<any>(
    `${API_ROOT}/companies/${companyId}/favorites`,
    { workerId }
  );
};

const addCompanyFavoritesByNumber = async (
  companyId: number,
  data: any
): Promise<AxiosResponse<CompanyFavoriteDto>> => {
  return await axiosRequest.auth.post<any>(
    `${API_ROOT}/companies/${companyId}/favorites`,
    data
  );
};

const updateCompanyFavorite = async (
  companyId: number,
  data: any,
  favoriteId: number
): Promise<AxiosResponse<CompanyFavoriteDto>> => {
  return await axiosRequest.auth.put<any>(
    `${API_ROOT}/companies/${companyId}/favorites/${favoriteId}`,
    data
  );
};

const removeCompanyFavorites = async (
  companyId: number,
  id: number
): Promise<AxiosResponse<any>> => {
  return await axiosRequest.auth.delete(
    `${API_ROOT}/companies/${companyId}/favorites/${id}`
  );
};

const getCompanyFavorites = async (
  companyId: number
): Promise<AxiosResponse<CompanyFavoriteDto[]>> => {
  return await axiosRequest.auth.get<CompanyFavoriteDto[]>(
    `${API_ROOT}/companies/${companyId}/favorites`
  );
};

const getCompanyUsers = async (
  companyId: number
): Promise<AxiosResponse<CompanyUser[]>> => {
  return await axiosRequest.auth.get<CompanyUser[]>(
    `${API_ROOT}/companies/${companyId}/users`
  );
};
const getCompanySubscriptionInfo = async (
  companyId: number
): Promise<AxiosResponse<any>> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/membership`
  );
};

const addCompanyUser = async (
  companyId: number,
  user: CompanyUserRequestDto
): Promise<AxiosResponse<CompanyUser>> => {
  return await axiosRequest.auth.post(
    `${API_ROOT}/companies/${companyId}/users`,
    user
  );
};

const updateCompanyUser = async (
  companyId: number,
  userId: number,
  firebaseId: string,
  role: UserRoleRequest,
  firstName: string,
  lastName: string
): Promise<AxiosResponse<CompanyUser>> => {
  return await axiosRequest.auth.put(
    `${API_ROOT}/companies/${companyId}/users/${userId}/role`,
    { firebaseId, role, firstName, lastName }
  );
};

const deleteCompanyUser = async (
  companyId: number,
  userId: number
): Promise<AxiosResponse<CompanyUser>> => {
  return await axiosRequest.auth.delete(
    `${API_ROOT}/companies/${companyId}/users/${userId}`
  );
};

const createPayoutForTimeReport = async (
  companyId: number,
  timeReportId: number
): Promise<AxiosResponse<any>> => {
  return await axiosRequest.auth.post<TimeReportPaymentDto>(
    `${API_ROOT}/companies/${companyId}/timereports/${timeReportId}/pay`,
    { timeReportId: timeReportId, companyId: companyId }
  );
};

const getCompanyInformation = async (orgNumber: string) => {
  return axiosRequest.auth.get<any>(
    `${API_ROOT}/CompanyInfo?organisationNumber=${orgNumber}`
  );
};

const setupSimflexityPayForCompany = async (
  companyId: number
): Promise<AxiosResponse<any>> => {
  return await axiosRequest.auth.post(
    `${API_ROOT}/company/${companyId}/SimflexityPay`
  );
};

const addCompanyObSetting = async (
  companyId: number,
  obSetting: CompanyOBSetting
): Promise<AxiosResponse<CompanyOBSetting>> => {
  return await axiosRequest.auth.post<any>(
    `${API_ROOT}/companies/${companyId}/OBSetting`,
    obSetting
  );
};

const getCompanyObSettings = async (
  companyId: number
): Promise<AxiosResponse<CompanyOBSetting[]>> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/OBSetting`
  );
};

const deleteCompanyObSettings = async (
  companyId: number,
  obSettingId: number
): Promise<AxiosResponse<CompanyOBSetting[]>> => {
  return await axiosRequest.auth.delete<any>(
    `${API_ROOT}/companies/${companyId}/OBSetting/${obSettingId}`
  );
};

const getTimeReportForStamping = async (
  companyId: number,
  workerPhoneNumber: string
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/stampClock/${workerPhoneNumber}`
  );
};

const getStampClockTemplate = async (
  companyId: number
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/stampClock/template`
  );
};

const updateStampClockTemplate = async (
  data: StampClockTemplate
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.put<any>(
    `${API_ROOT}/companies/${data.companyId}/stampClock/template`,
    data
  );
};

const getLocalStampClockOnlySetting = async (
  companyId: number
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/stampClock/settings/localOnly`
  );
};

const updateLocalStampClockOnlySetting = async (
  companyId: number,
  active: boolean
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.put<any>(
    `${API_ROOT}/companies/${companyId}/stampClock/settings/localOnly?active=${active}`
  );
};

const getAutogenerateShiftSetting = async (
  companyId: number
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/stampClock/settings/autoGenerate`
  );
};

const updateAutogenerateShiftSetting = async (
  companyId: number,
  active: boolean
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.put<any>(
    `${API_ROOT}/companies/${companyId}/stampClock/settings/autoGenerate?active=${active}`
  );
};

const getTemplateForAutoGeneratedGig = async (
  companyId: number,
  workerPhoneNumber: string
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/stampClock/${workerPhoneNumber}/autoGenerate`
  );
};

const createAutoGeneratedGig = async (
  companyId: number,
  workerPhoneNumber: string,
  templateForAutoGeneratedGig: TemplateForAutoGeneratedGig
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.post<any>(
    `${API_ROOT}/companies/${companyId}/stampClock/${workerPhoneNumber}/autoGenerate/${templateForAutoGeneratedGig.templateId}`
  );
};

const handleCheckInCheckOut = async (
  companyId: number,
  timeReportId: number,
  workerPhoneNumber: string,
  now: boolean
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.put<any>(
    `${API_ROOT}/companies/${companyId}/stampClock/${workerPhoneNumber}/timereports/${timeReportId}?now=${now}`
  );
};

const deleteCompaniesGigsShift = async (
  companyId: number,
  shiftId: number,
  gigsId: string
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.delete<any>(
    `${API_ROOT}/companies/${companyId}/gigs/${gigsId}/shifts/${shiftId}`
  );
};

const getTotalTimeAndCostsMonthly = async (
  companyId: number,
  year: number,
  month: number
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/workers/summary/${year}-${month}`
  );
};

const getTotalsMonthlyWorker = async (
  companyId: number,
  workerId: number,
  year: number,
  month: number
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/workers/${workerId}/summary/${year}-${month}`
  );
};

const getWorkerDetailsByWorkerId = async (
  companyId: number,
  workerId: number
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/workers/${workerId}`
  );
};

const getLatestGigRatings = async (
  companyId: number
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/gigrating?amount=4&page=0&sortAcending=false`
  );
};

const sendNewOfferForWithdrawnShift = async (
  companyId: number,
  gigId: number,
  data: any
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.post<any>(
    `${API_ROOT}/companies/${companyId}/gigs/${gigId}/children`,
    data
  );
};

// Entity, Unit structure

const fetchCompanyStructure = async (
  companyId: number
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/structure?includeWorkers=true&includeInvites=true&includeWorkGroups=true`
  );
};

const addCompanyStructureEntity = async (
  companyId: number,
  data: EntityName
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.post<any>(
    `${API_ROOT}/companies/${companyId}/structure/entities`,
    data
  );
};

const updateCompanyStructureEntity = async (
  companyId: number,
  entityId: number,
  data: EntityName
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.put<any>(
    `${API_ROOT}/companies/${companyId}/structure/entities/${entityId}`,
    data
  );
};

const deleteCompanyStructureEntity = async (
  companyId: number,
  entityId: number
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.delete<any>(
    `${API_ROOT}/companies/${companyId}/structure/entities/${entityId}`
  );
};

const addCompanyStructureUnit = async (
  companyId: number,
  entityId: number,
  data: UnitName
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.post<any>(
    `${API_ROOT}/companies/${companyId}/structure/entities/${entityId}/units`,
    data
  );
};

const updateCompanyStructureUnit = async (
  companyId: number,
  entityId: number,
  unitId: number,
  data: UnitName
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.put<any>(
    `${API_ROOT}/companies/${companyId}/structure/entities/${entityId}/units/${unitId}`,
    data
  );
};

const deleteCompanyStructureUnit = async (
  companyId: number,
  entityId: number,
  unitId: number
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.delete<any>(
    `${API_ROOT}/companies/${companyId}/structure/entities/${entityId}/units/${unitId}`
  );
};

const addNewWorkers = async (
  companyId: number,
  unitId: number,
  data: NewWorker[]
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.post<any>(
    `${API_ROOT}/companies/${companyId}/workers?companyUnitId=${unitId}`,
    data
  );
};

const addWorkers = async (
  companyId: number,
  entityId: number,
  unitId: number,
  workerIdList: number[]
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.post<any>(
    `${API_ROOT}/companies/${companyId}/structure/entities/${entityId}/units/${unitId}/workers`,
    workerIdList
  );
};

const deleteWorker = async (
  companyId: number,
  entityId: number,
  unitId: number,
  workerId: number
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.delete<any>(
    `${API_ROOT}/companies/${companyId}/structure/entities/${entityId}/units/${unitId}/worker/${workerId}`
  );
};

export const company = {
  application,
  gig,
  calendar,
  template,
  worker,
  timeReport,
  login,
  workgroup,
  companyEmployees,
  systemSettings,
  salary,
  addOrUpdateCompany,
  getCompanyFavorites,
  addCompanyFavorites,
  removeCompanyFavorites,
  createPayoutForTimeReport,
  getCompanyUsers,
  addCompanyUser,
  updateCompanyUser,
  deleteCompanyUser,
  getCompanyInformation,
  setupSimflexityPayForCompany,
  addCompanyObSetting,
  getCompanyObSettings,
  deleteCompanyObSettings,
  addCompanyFavoritesByNumber,
  updateCompanyFavorite,
  getTimeReportForStamping,
  handleCheckInCheckOut,
  staffingWorkerPool,
  deleteCompaniesGigsShift,
  getCompanySubscriptionInfo,
  getTotalTimeAndCostsMonthly,
  getTotalsMonthlyWorker,
  getStampClockTemplate,
  updateStampClockTemplate,
  getLocalStampClockOnlySetting,
  updateLocalStampClockOnlySetting,
  getAutogenerateShiftSetting,
  updateAutogenerateShiftSetting,
  getTemplateForAutoGeneratedGig,
  createAutoGeneratedGig,
  getWorkerDetailsByWorkerId,
  getLatestGigRatings,
  sendNewOfferForWithdrawnShift,
  fetchCompanyStructure,
  addCompanyStructureEntity,
  updateCompanyStructureEntity,
  deleteCompanyStructureEntity,
  addCompanyStructureUnit,
  updateCompanyStructureUnit,
  deleteCompanyStructureUnit,
  addNewWorkers,
  addWorkers,
  deleteWorker,
  tip
};
