import React, { useState, useEffect } from "react";
import {
  ChronoUnit,
  LocalDate,
  LocalTime,
  LocalDateTime,
  Instant,
} from "@js-joda/core";
import { useTranslation } from "react-i18next";
import { ShiftRequestDto } from "model/Shift";
import { FontFamily, FontSize } from "config/font";
import { ButtonStyled } from "components/buttons/buttons.styled";
import {
  LabelMargin,
  ShiftFormStyled,
  TimeInput,
  BreakInput,
  ShiftInputContainer,
  ErrorMessage,
  DatePickerContainer,
  MaxShiftError,
  ModalBody,
  BreakFormStyled,
  TextCenter,
} from "./shiftForm.styled";
import { CustomLink, Text } from "components/Typography/text.styled";
import { SingleDatePicker } from "react-dates";
import moment, { Moment } from "moment";
import { Modal } from "@material-ui/core";
import { Color } from "config/colors";
import { ModalButtonContainer } from "../../gig/gigs.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import { Select } from "components/form/select/select.styled";
import { calculateBreakTime, createBreakStrings } from "utils/utils";

type Props = {
  shiftIndex?: number;
  shifts: ShiftRequestDto[];
  onChange: (shifts: ShiftRequestDto[]) => void;
  shiftFormOpen: boolean;
  setShiftFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  shift?: ShiftRequestDto;
  shiftDate?: string;
  editGig?: boolean;
};

export const ShiftFormHHMMRegex = /^([01]?[0-9]|2[0-3]|24):[0-5][0-9]$/;

export const ShiftForm: React.FC<Props> = ({
  shiftIndex,
  shift,
  shifts,
  onChange,
  shiftFormOpen,
  setShiftFormOpen,
  shiftDate,
  editGig,
}) => {
  const { t } = useTranslation();
  const [date, setDate] = useState<Moment>(moment());
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [breakTime, setBreakTime] = useState(0);
  const [breakTimeMin, setBreakTimeMin] = useState(0);
  const [focused, setFocused] = useState(false);
  const [endDate, setEndDate] = useState<Moment>(moment());
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showShiftBreakTimes, setShowShiftBreakTimes] = useState(false);
  const [breakStartTime, setBreakStartTime] = useState("");
  const [breakEndTime, setBreakEndTime] = useState("");
  const [deduction, setDeduction] = useState(
    shift?.deductionAmount ? shift.deductionAmount : 0
  );

  const today = LocalDate.now();
  const nowTime = LocalTime.now().truncatedTo(ChronoUnit.MINUTES);

  const validateEndTime = () => {
    return endTime > startTime;
  };

  useEffect(() => {
    if (shift) {
      const startTime = LocalDateTime.ofInstant(Instant.parse(shift.startTime));
      const endTime = LocalDateTime.ofInstant(Instant.parse(shift.endTime));
      if (shift.breakStartTime && shift.breakEndTime) {
        const breakStart = LocalDateTime.ofInstant(
          Instant.parse(shift.breakStartTime.slice(0, 19) + "Z") //TA bort slice när fixat i responsen
        );
        const breakEnd = LocalDateTime.ofInstant(
          Instant.parse(shift.breakEndTime.slice(0, 19) + "Z") //TA bort slice när fixat i responsen
        );
        setBreakStartTime(
          breakStart.toLocalTime().truncatedTo(ChronoUnit.MINUTES).toString()
        );
        setBreakEndTime(
          breakEnd.toLocalTime().truncatedTo(ChronoUnit.MINUTES).toString()
        );
      } else if (shift.breakTime) setBreakTimeMin(shift.breakTime);

      setDate(moment(shift.startTime.toString()));
      setStartTime(
        startTime.toLocalTime().truncatedTo(ChronoUnit.MINUTES).toString()
      );
      setEndTime(
        endTime.toLocalTime().truncatedTo(ChronoUnit.MINUTES).toString()
      );
    } else {
      setDate(shiftDate ? moment(shiftDate) : moment());
      setStartTime(
        nowTime.plusHours(1).truncatedTo(ChronoUnit.HOURS).toString()
      );
      setEndTime(nowTime.plusHours(2).truncatedTo(ChronoUnit.HOURS).toString());
      setBreakStartTime(
        nowTime.plusHours(1).truncatedTo(ChronoUnit.HOURS).toString()
      );
      setBreakEndTime(
        nowTime.plusHours(1).truncatedTo(ChronoUnit.HOURS).toString()
      );
    }
  }, [shift]);

  useEffect(() => {
    if (breakEndTime && breakStartTime && breakEndTime !== breakStartTime) {
      const res = calculateBreakTime(
        breakStartTime,
        breakEndTime,
        moment(date.format("YYYY-MM-DD") + " " + startTime),
        validateEndTime()
          ? moment(moment(date).format("YYYY-MM-DD") + " " + endTime)
          : moment(
              moment(moment(date).add(1, "days")).format("YYYY-MM-DD") +
                " " +
                endTime
            )
      );
      setBreakTime(res);
    } else {
      setBreakTime(0);
    }
  }, [breakStartTime, breakEndTime, startTime, endTime, date, endDate]);

  const handleSubmit = () => {
    const breakStrings = createBreakStrings(
      startTime,
      endTime,
      breakStartTime,
      breakEndTime,
      date,
      endDate
    );
    if (validateShift()) {
      const newData = {
        startTime: new Date(
          `${date.format("YYYY-MM-DD")}T${startTime}:00`
        ).toISOString(),
        endTime: new Date(
          `${endDate.format("YYYY-MM-DD")}T${endTime}:00`
        ).toISOString(),
        ...(breakStartTime !== breakEndTime && {
          breakStartTime: breakStrings.breakStartString,
          breakEndTime: breakStrings.breakEndString,
        }),
        ...(breakTimeMin && {
          breakTime: breakTimeMin,
        }),
        deductionAmount: deduction,
      };

      if (shift) {
        const newShifts = shifts.map((currentShift, index) => {
          return index === shiftIndex
            ? {
                ...shift,
                startTime: newData.startTime,
                endTime: newData.endTime,
                breakStartTime: newData.breakStartTime,
                breakEndTime: newData.breakEndTime,
                ...(breakTimeMin && {
                  breakTime: breakTimeMin,
                }),
                deductionAmount: deduction,
              }
            : currentShift;
        });
        onChange(newShifts);
      } else {
        onChange([...shifts, newData]);
      }

      setShiftFormOpen(false);
    }
  };

  const validateDate = () => {
    //return today.toString() <= date.format("YYYY-MM-DD");
    return true;
  };

  /*const validateStartTime = () => {
    if (today.toString() === date.format("YYYY-MM-DD")) {
      return startTime >= nowTime.toString();
    } else {
      return true;
    }
  };*/

  const overnightShift = () => {
    if (
      endTime <= startTime &&
      (endDate.format("YYYY-MM-DD") === date.format("YYYY-MM-DD") ||
        date > endDate)
    ) {
      setEndDate(moment(date).add(1, "days"));
    } else if (endTime < startTime) {
      setEndDate(moment(date).add(1, "days"));
    } else if (endTime > startTime) {
      setEndDate(date);
    }
  };

  useEffect(() => {
    overnightShift();
  }, [date, endTime, startTime]);

  const maxShifts = () => {
    if (shifts.filter((shift) => shift.deleteEntry !== true).length + 1 > 30) {
      return false;
    } else return true;
  };

  const validateShift = () => {
    if (
      date &&
      startTime &&
      endTime &&
      typeof breakTime === "number" &&
      validateDate() &&
      ShiftFormHHMMRegex.test(startTime) &&
      validateBreakTime() &&
      maxShifts()
    ) {
      return true;
    } else {
      return false;
    }
  };

  const validateBreakTime = (): boolean => {
    const startShift = new Date(`${date.format("YYYY-MM-DD")}T${startTime}:00`);
    const endShift = new Date(
      `${
        startTime > endTime
          ? endDate.format("YYYY-MM-DD")
          : date.format("YYYY-MM-DD")
      }T${endTime}:00`
    );

    const startBreak = new Date(
      `${(breakStartTime >= startTime ? date : endDate).format(
        "YYYY-MM-DD"
      )}T${breakStartTime}:00`
    );

    const endBreak = new Date(
      `${(breakEndTime < startTime ? endDate : date).format(
        "YYYY-MM-DD"
      )}T${breakEndTime}:00`
    );

    if (breakStartTime === breakEndTime) {
      return true;
    }

    if (startBreak < startShift || endBreak > endShift) {
      return false;
    }

    if (startBreak > endBreak) {
      return false;
    }

    if (breakTimeMin < 0) {
      return false;
    }

    return true;
  };

  const checkOverlapping = () => {
    const newstart = new Date(
      `${date.format("YYYY-MM-DD")}T${startTime}:00`
    ).toISOString();
    const newEnd = new Date(
      `${endDate.format("YYYY-MM-DD")}T${endTime}:00`
    ).toISOString();

    const overlapping = shifts.filter((currentShift, index) => {
      if (
        (currentShift.id && shift && shift.id !== currentShift.id) ||
        index !== shiftIndex ||
        new Date(currentShift.startTime).setHours(0, 0, 0) !==
          new Date(newstart).setHours(0, 0, 0)
      ) {
        if (
          (newstart >= currentShift.startTime &&
            newstart <= currentShift.endTime) ||
          (newEnd >= currentShift.startTime && newEnd <= currentShift.endTime)
        ) {
          return currentShift;
        }
      }
    });

    if (overlapping.length > 0) {
      setShowWarningModal(true);
    } else {
      handleSubmit();
    }
  };

  return (
    <div>
      <ShiftFormStyled open={shiftFormOpen}>
        <Text
          fontFamily={FontFamily.MontserratSemiBold}
          fontSize={FontSize.Large}
          align="center"
        >
          {shift ? t("GigsCompany.EditShift") : t("GigsCompany.AddShift")}
        </Text>
        <LabelMargin>{t("GigsCompany.Date")}</LabelMargin>
        <ShiftInputContainer>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
          >
            {t("GigsCompany.Date")}
          </Text>
          <DatePickerContainer>
            <SingleDatePicker
              date={date}
              onDateChange={(date) => date && setDate(date)}
              focused={focused}
              onFocusChange={({ focused }) => setFocused(focused)}
              id="SingleDatePicker"
              hideKeyboardShortcutsPanel={true}
              anchorDirection="right"
              numberOfMonths={1}
              displayFormat="DD/MM/YYYY"
              firstDayOfWeek={1}
              isOutsideRange={() => false}
            />
          </DatePickerContainer>
          {!validateDate() && (
            <ErrorMessage>{t("GigsCompany.DateHasPassed")}</ErrorMessage>
          )}
        </ShiftInputContainer>
        <LabelMargin>{t("GigsCompany.Time")}</LabelMargin>

        <ShiftInputContainer>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
          >
            {t("GigsCompany.ShiftStart")}
          </Text>
          <TimeInput
            fontFamily={FontFamily.MontserratBold}
            fontSize={FontSize.Large}
            name="startTime"
            type="time"
            value={startTime}
            onChange={(e) => {
              setStartTime(e.currentTarget.value);
            }}
          />
          {!ShiftFormHHMMRegex.test(startTime) && (
            <ErrorMessage>{t("GigsCompany.EnterAValidTime")}</ErrorMessage>
          )}
        </ShiftInputContainer>
        <ShiftInputContainer>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
          >
            {t("GigsCompany.ShiftEnd")}
          </Text>
          <TimeInput
            fontFamily={FontFamily.MontserratBold}
            fontSize={FontSize.Large}
            name="endTime"
            type="time"
            required
            value={endTime}
            onChange={(e) => {
              setEndTime(e.currentTarget.value);
            }}
          />
        </ShiftInputContainer>
        {!showShiftBreakTimes && !editGig && (
          <>
            <ShiftInputContainer style={{ marginBottom: 0 }}>
              <Text
                fontFamily={FontFamily.MontserratSemiBold}
                fontSize={FontSize.Large}
              >
                {t("GigsCompany.UnpaidBreak")}
              </Text>
              <BreakInput
                fontFamily={FontFamily.MontserratBold}
                fontSize={FontSize.Large}
                name="breakTime"
                type="number"
                value={breakTimeMin.toString()}
                onChange={(e) => {
                  setBreakTimeMin(parseInt(e.currentTarget.value) || 0);
                }}
                onBlur={(e) => {
                  !e.currentTarget.value.trim() && setBreakTime(0);
                }}
              />
            </ShiftInputContainer>
            <TextCenter>
              <CustomLink
                onClick={() => {
                  setShowShiftBreakTimes(true);
                  setBreakTimeMin(0);
                }}
                fontSize={FontSize.Small}
                color={Color.MidnightBlue}
              >
                {"(" + t("GigsCompany.ClickToEnterBreakTimeSpan") + ")"}
              </CustomLink>
            </TextCenter>
          </>
        )}
        {(showShiftBreakTimes || editGig) && (
          <>
            <Select style={{ height: 48 }}>
              <CustomIcon
                name="plus_old"
                size="48px"
                color={Color.MidnightBlue}
                padding="10px"
              />
              <Text
                fontFamily={FontFamily.MontserratSemiBold}
                fontSize={FontSize.Large}
                color={Color.MidnightBlue}
              >
                {`${t("GigsCompany.UnpaidBreak")}    ${breakTime} min`}
              </Text>
            </Select>
            <BreakFormStyled breakFormOpen={true}>
              <ShiftInputContainer style={{ marginTop: 10 }}>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  fontSize={FontSize.Large}
                  color={Color.White}
                >
                  {t("GigsCompany.StartTime")}
                </Text>
                <TimeInput
                  fontFamily={FontFamily.MontserratBold}
                  fontSize={FontSize.Large}
                  name="startTime"
                  type="time"
                  value={breakStartTime}
                  onChange={(e) => {
                    setBreakStartTime(e.currentTarget.value);
                  }}
                />
                {!validateBreakTime() && (
                  <ErrorMessage>
                    {t("GigsCompany.EnterAValidTime")}
                  </ErrorMessage>
                )}
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  fontSize={FontSize.Large}
                  color={Color.White}
                >
                  {t("GigsCompany.EndTime")}
                </Text>
                <TimeInput
                  fontFamily={FontFamily.MontserratBold}
                  fontSize={FontSize.Large}
                  name="endTime"
                  type="time"
                  required
                  value={breakEndTime}
                  onChange={(e) => {
                    setBreakEndTime(e.currentTarget.value);
                  }}
                />
              </ShiftInputContainer>
            </BreakFormStyled>

            {!editGig && (
              <TextCenter>
                <CustomLink
                  onClick={() => {
                    setShowShiftBreakTimes(false);
                    setBreakStartTime(startTime);
                    setBreakEndTime(startTime);
                  }}
                  fontSize={FontSize.Small}
                  color={Color.MidnightBlue}
                >
                  {"(" + t("GigsCompany.ClickToEnterMinutesBreak") + ")"}
                </CustomLink>
              </TextCenter>
            )}
          </>
        )}
        <ShiftInputContainer style={{ marginBottom: 0 }}>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
          >
            {t("GigsCompany.Deduction")}
          </Text>
          <BreakInput
            fontFamily={FontFamily.MontserratBold}
            fontSize={FontSize.Large}
            name="deduction"
            type="number"
            value={deduction.toString()}
            onChange={(e) => {
              setDeduction(parseInt(e.currentTarget.value) || 0);
            }}
            onBlur={(e) => {
              !e.currentTarget.value.trim() && setDeduction(0);
            }}
            maxLength={6}
          />
        </ShiftInputContainer>
        <Text
          fontSize={FontSize.Small}
          style={{ marginLeft: 20, marginTop: 0, marginBottom: 30 }}
        >
          {t("GigsCompany.DeductionInfo")}
        </Text>
        <ButtonStyled disabled={!validateShift()} onClick={checkOverlapping}>
          {shift ? t("General.Done") : t("GigsCompany.AddShift")}
        </ButtonStyled>
        {!maxShifts() && (
          <MaxShiftError>{t("GigsCompany.ToManyShifts")}</MaxShiftError>
        )}
      </ShiftFormStyled>

      <Modal
        open={showWarningModal}
        onClose={() => {
          setShowWarningModal(false);
        }}
      >
        <ModalBody>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("GigsCompany.YourShiftsAreOverlapping")}
          </Text>
          <ModalButtonContainer>
            <ButtonStyled
              onClick={() => setShowWarningModal(false)}
              backgroundColor={Color.SeaBlue400}
            >
              {t("General.Abort")}
            </ButtonStyled>
            <ButtonStyled
              onClick={() => {
                setShowWarningModal(false);
                handleSubmit();
              }}
            >
              {t("General.Continue")}
            </ButtonStyled>
          </ModalButtonContainer>
        </ModalBody>
      </Modal>
    </div>
  );
};
