import styled from "styled-components";
import { Color } from "config/colors";
import { FontFamily } from "config/font";
import { CircularProgress } from "@material-ui/core";
type TypeCellProps = {
  selected: boolean;
};
export const RoleWrapper = styled.div`
  display: flex;
  width: 100%;
  column-gap: 15px;
  margin-top: 10px;
`;

export const SkillInputWrapper = styled(RoleWrapper)`
  align-items: center;

  button {
    margin-top: -10px;
  }
`;

export const SkillsWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
  margin-top: 15px;
`;

export const SkillTag = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
  background-color: ${Color.SeaBlue600};
  padding: 5px;
  border-radius: 4px;
`;

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const TemplateWrapper = styled(RowWrapper)`
  height: 48px;
  display: flex;
  align-items: center;
`;

export const StyledProgress = styled(CircularProgress)`
  position: relative;
  display: flex;
  align-self: center;
  margin-left: 18px;
`;

export const DisabledSelect = styled.div`
  height: 48px;
  width: fit-content;
  padding-left: 8px;
  display: flex;
  align-items: center;
  font-family: ${FontFamily.LeagueSpartanBold};
  border-radius: 10px;
  color: ${Color.BurntSienna};
  cursor: not-allowed;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const CheckboxText = styled.p`
  font-size: 14px;
  color: ${Color.MidnightBlue};
  font-family: ${FontFamily.MontserratSemiBold};
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  p {
    &:hover {
      cursor: pointer;
      color: ${Color.SeaBlue400};
    }
  }
  checkbox .checked {
    color: ${Color.SeaBlue400};
  }
`;
export const TypeCell = styled.div<TypeCellProps>`
  height: 80px;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  padding: 4px;
  ${(props) =>
    !props.selected
      ? `background-color: ${Color.SeaBlue200};
      border: none`
      : `background-color: ${Color.Positive}; border: 1px solid ${Color.BurntSienna}`};
  p {
    margin: 0;
  }
  &:hover {
    cursor: pointer;
    background-color: ${Color.Positive};
  }
  margin-top: 16px;
  text-align: center;
  margin-right: 10px;
`;
export const TypeRow = styled.div`
  display: flex;
  align-items: center;
  max-width: 500px;
  flex: wrap;
`;


export const LoadingWrapper = styled.div.attrs(
  (props: { loading: boolean }) => props
)`
  opacity: ${(props) => (props.loading ? "0.5" : "1.0")};
`;
