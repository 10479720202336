import { AxiosResponse } from "axios";
import { axiosRequest } from "../api.utils";
import { API_ROOT } from "services/baseURLs.config";
import {
  TipDetails,
  AddTipRequest,
  GetTipsResponse,
  UpdateTipResponse,
  GetTipsQueryParams,
  GetTipByIdResponse,
  SelectTipsResponse,
  CalculateTipsRequest,
  SaveTipPeriodRequest,
  CalculateTipsResponse,
  SaveTipPeriodResponse,
} from "model/Tip";

const getTips = async (
  companyId: number,
  params?: GetTipsQueryParams
): Promise<AxiosResponse<GetTipsResponse>> => {
  return await axiosRequest.auth.get<GetTipsResponse>(
    `${API_ROOT}/companies/${companyId}/tips/${params?.month}`,
    {
      params: {
        page: 0,
        pageSize: 10,
        sortAscending: false,
        ...params,
      },
    }
  );
};

const addTips = async (
  companyId: number,
  data: { date: string; amount: number }
): Promise<AxiosResponse<TipDetails>> => {
  return await axiosRequest.auth.post<TipDetails>(
    `${API_ROOT}/companies/${companyId}/tips`,
    data
  );
};

const updateTip = async (
  companyId: number,
  companyTipId: number,
  data: AddTipRequest
): Promise<AxiosResponse<UpdateTipResponse>> => {
  return await axiosRequest.auth.put<UpdateTipResponse>(
    `${API_ROOT}/companies/${companyId}/tips/${companyTipId}`,
    data
  );
};

const getTipById = async (
  companyId: number,
  tipId: number
): Promise<AxiosResponse<GetTipByIdResponse>> => {
  return await axiosRequest.auth.get<GetTipByIdResponse>(
    `${API_ROOT}/companies/${companyId}/tips/${tipId}`
  );
};

const selectTips = async (
  companyId: number,
  tipIds: number[]
): Promise<AxiosResponse<SelectTipsResponse>> => {
  return await axiosRequest.auth.post<SelectTipsResponse>(
    `${API_ROOT}/companies/${companyId}/tips/select`,
    { companyTipIds: tipIds }
  );
};

const calculateTips = async (
  companyId: number,
  data: CalculateTipsRequest
): Promise<AxiosResponse<CalculateTipsResponse>> => {
  return await axiosRequest.auth.post<CalculateTipsResponse>(
    `${API_ROOT}/companies/${companyId}/tips/calculate`,
    data
  );
};

const saveTipPeriod = async (
  companyId: number,
  data: SaveTipPeriodRequest[]
): Promise<AxiosResponse<{companyTips: SaveTipPeriodResponse}>> => {
  return await axiosRequest.auth.post<{companyTips: SaveTipPeriodResponse}>(
    `${API_ROOT}/companies/${companyId}/tips/save`,
    data
  );
};

const payoutTips = async (
  companyId: number,
  data: { companyTipIds: number[]; payoutType: string }
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.post<void>(
    `${API_ROOT}/companies/${companyId}/tips/payout`,
    data
  );
};

const deleteTipPeriod = async (
  companyId: number,
  tipId: number
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.delete<void>(
    `${API_ROOT}/companies/${companyId}/tips/${tipId}`
  );
};

export const tip = {
  getTips,
  addTips,
  updateTip,
  getTipById,
  selectTips,
  calculateTips,
  saveTipPeriod,
  payoutTips,
  deleteTipPeriod,
};
